:global {
    :local(.wrapper) {
        height: 64px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        background-color: #F5F5F5;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);

        // >.left {
        //     >.text {
        //         height: 14px;
        //         font-size: 14px;
        //         font-family: PingFangSC-Regular, PingFang SC;
        //         font-weight: 400;
        //         color: #2B334B;
        //         line-height: 14px;
        //     }
        // }

        >.right {
            margin-left: auto;
        }
    }
}