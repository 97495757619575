:global {
    :local(.wrapper) {
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 24px;

        >.sys_wrapper {
            margin-left: 45px;
        }

        >.time_wrapper {
            margin-left: auto;
            font-size: 14px;
            font-family: PingFangSC-Regular;
            line-height: 14px;
            color: #546677;
        }
    }
}