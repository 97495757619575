:global{
    :local(.addShiftClcleModal){
        
        .ant-modal-content {
            border-radius: 2px;
            height: 100%;   
            overflow: hidden;        
        }
    
        .ant-modal-close-x {
            height: 40px;
            line-height: 40px;
            font-size: 22px;
            margin-top: 12px;
        }

        .ant-modal-body {
            padding: 0;
            .title {            
                font-size: 16px;
                color: #333;
                padding:20px 24px;
                background: #E2E4E7;                
            }
            .ant-form{
                margin-top: 16px;
                padding: 0 24px 0 0;

                .ant-input {
                    border-radius: 2px;
                    border-color: #C9C9C9;
                }
                .ant-form-item-explain-error{
                    font-size: 12px;
                }
            }
        }
        .ant-modal-footer {
            border-top: none;
            .ant-btn:hover{
               color: #375B98;
               border-color: #375B98 !important;
            }
            .ant-btn-primary {
                background: #375B98;
                border: 1px solid #375B98;
                &:hover{
                    color: #fff !important;
                    opacity: .8;
                } 
            }
        }
        
    }
}