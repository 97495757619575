:global {
    :local(.HourlyFactorsDiagram) {
        height: calc(100% - 70px);
        overflow-y: auto;

        .filter_wrapper {
            height: 50px;
            display: flex;
            align-items: center;
            width: 90%;
            padding: 0 30px;
            border-bottom: 1px solid #BBBBBB;
            margin: 0 auto;

            >div {
                margin-right: 20px;
            }
        }
    }
}