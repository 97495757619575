:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            display: flex;
            flex-direction: column;

            >.content {
                height: 0;
                flex-grow: 1;
                display: flex;

                >.left {
                    width: 0;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    >.top {
                        padding-top: 24px;
                        padding-left: 52px;
                        display: flex;
                        align-items: center;

                        >.item_wrapper {
                            display: flex;
                            align-items: center;

                            >.color {
                                width: 24px;
                                height: 10px;
                                border-radius: 2px;
                            }

                            >.name {
                                margin-left: 6px;
                                height: 12px;
                                font-size: 12px;
                                font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                                font-weight: 400;
                                color: #666666;
                                line-height: 12px;
                            }

                            &:nth-child(n+2) {
                                margin-left: 20px;
                            }
                        }
                    }

                    >.bottom {
                        height: 0;
                        flex-grow: 1;

                        >.chart {
                            height: 100%;
                        }
                    }
                }

                >.right {
                    width: 255px;
                    padding-top: 50px;

                    >.row {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        line-height: 14px;

                        >.title {
                            width: 72px;
                            height: 14px;
                            color: #999999;
                        }

                        >.value {
                            color: #333333;

                            &.selector {
                                position: relative;

                                >.text {
                                    position: absolute;
                                    bottom: -12px;
                                    left: 0;
                                    transform: translateY(100%);
                                    white-space: nowrap;
                                }
                            }
                        }

                        &:nth-child(n+2) {
                            margin-top: 32px;
                        }
                    }
                }
            }
        }
    }
}