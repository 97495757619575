:global {
    :local(.exam_rule_config_content_wrapper) {
        height: 100%;

        .content_wrapper {
            height: 100%;
            background: rgba(255, 255, 255, 1);
            border-radius: 16px;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);
            padding: 24px;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                align-items: center;
                padding-bottom: 24px;
                flex-shrink: 0;
            }

            .cus_table_wrapper {
                height: 0;
                flex-grow: 1;

                .ant-table-wrapper {
                    height: 100%;

                    .ant-spin-nested-loading {
                        height: 100%;

                        .ant-spin-container {
                            height: 100%;

                            .ant-table.ant-table-fixed-header {
                                height: 100%;

                                .ant-table-container {
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;

                                    .ant-table-header {
                                        flex-shrink: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}