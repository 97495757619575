.main_page_bottom_panels_improve_chance_modal_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 1);
        opacity: 0.7;
    }

    .cus_modal_wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 964px;
        height: 511px;
        // opacity: 0.6;
        border: 1px solid rgba($color: #44F2D9, $alpha: 0.6);
        background-color: rgba(33, 40, 48, 1);
        display: flex;
        flex-direction: column;

        .modal_header {
            height: 46px;
            background: #0D1115;
            border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
            padding: 0 16px 0 16px;
            display: flex;
            align-items: center;
            position: relative;

            .title_icon {
                width: 24px;
            }

            .title {
                height: 18px;
                font-size: 18px;
                // font-weight: 500;
                color: #FFFFFF;
                line-height: 18px;
                margin-left: 5px;
            }

            .time {
                margin-left: 12px;
                height: 16px;
                font-size: 16px;
                // font-weight: 400;
                color: #9FA0BA;
                line-height: 16px;
            }

            .cus_close_icon {
                margin-left: auto;
                margin-right: 4px;
                font-size: 24px;
                color: rgba(89, 97, 104, 1);
            }
        }

        .modal_body {
            height: 0;
            flex-grow: 1;
            // overflow-y: auto;
            padding-top: 24px;
            display: flex;

            .left {
                width: 0;
                flex-grow: 1;
                padding-top: 4px;
                padding-bottom: 12px;
                padding-left: 36px;
                display: flex;
                flex-direction: column;

                .header_block {
                    display: flex;
                    align-items: center;

                    .left_block {
                        width: 327px;
                    }

                    .ave_title {
                        width: 71px;
                        height: 16px;
                        font-size: 16px;
                        // font-weight: 400;
                        color: #9FA0BA;
                        line-height: 16px;
                    }

                    .standard_title {
                        height: 16px;
                        font-size: 16px;
                        // font-weight: 400;
                        color: #9FA0BA;
                        line-height: 16px;
                    }
                }

                .left_content_block {
                    margin-top: 12px;
                    height: 0;
                    flex-grow: 1;
                    overflow-y: auto;

                    .union_wrapper {
                        position: relative;

                        .left_line {
                            position: absolute;
                            left: 7px;
                            top: 16px;
                            bottom: 16px;
                            width: 1px;
                            background-color: rgba(68, 243, 218, 1);
                            opacity: 0.3;
                        }

                        .items_wrapper {
                            position: relative;

                            .item_row_wrapper {
                                height: 32px;
                                display: flex;
                                align-items: center;

                                .circle_block {
                                    width: 26px;

                                    .circle_wrapper {
                                        width: 14px;
                                        height: 14px;
                                        border-radius: 50%;
                                        border-width: 1px;
                                        border-style: solid;
                                        border-color: transparent;
                                        background-color: rgba(33, 40, 48, 1);
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        .circle_2 {
                                            width: 8px;
                                            height: 8px;
                                            border-radius: 50%;
                                            background-color: transparent;
                                        }
                                    }
                                }

                                .time_wrapper {
                                    width: 158px;
                                    height: 16px;
                                    font-size: 14px;
                                    color: #9FA0BA;
                                    line-height: 16px;
                                }

                                .tip_wrapper {
                                    width: 36px;
                                    // display: flex;

                                    .text {
                                        // padding: 0 5px;
                                        width: 28px;
                                        height: 14px;
                                        border-radius: 9px;
                                        // background-color: rgba(68, 243, 218, 1);
                                        background-color: transparent;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        font-size: 12px;
                                        color: #151922;
                                    }
                                }

                                .name_wrapper {
                                    width: 106px;
                                    height: 14px;
                                    font-size: 14px;
                                    // font-weight: 500;
                                    color: #FFFFFF;
                                    line-height: 14px;
                                }

                                .ave_value {
                                    width: 71px;
                                    height: 14px;
                                    font-size: 14px;
                                    // font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 14px;
                                }

                                .standard_value {
                                    width: 83px;
                                    height: 14px;
                                    font-size: 14px;
                                    // font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 14px;
                                }

                                .hint_wrapper {
                                    height: 14px;
                                    font-size: 14px;
                                    // font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 14px;
                                }
                            }
                        }
                    }

                }
            }

            .modal_body_devide_line {
                width: 1px;
                background: #3E405E;
            }

            .right {
                width: 359px;
                padding-left: 38px;
                padding-bottom: 12px;
                display: flex;
                flex-direction: column;

                .title_wrapper {
                    display: flex;
                    align-items: center;

                    .title_icon {
                        width: 22px;
                        height: 12px;
                    }

                    .title {
                        margin-left: 8px;
                        height: 18px;
                        font-size: 18px;
                        // font-weight: 500;
                        color: #FFFFFF;
                        line-height: 18px;
                    }
                }

                .table_content {
                    height: 0;
                    flex-grow: 1;
                    overflow-y: auto;
                    margin-top: 19px;

                    .items_wrapper {

                        // display: inline-block;
                        // width: 380px;
                        .item_row_wrapper {
                            height: 38px;
                            display: flex;
                            align-items: center;

                            .rank_wrapper {
                                width: 35px;
                                padding-left: 2px;

                                .text {
                                    height: 14px;
                                    font-size: 14px;
                                    color: #9FA0BA;
                                    line-height: 14px;
                                }
                            }

                            .tip_wrapper {
                                width: 36px;
                                // display: flex;

                                .text {
                                    // padding: 0 5px;
                                    width: 28px;
                                    height: 14px;
                                    border-radius: 9px;
                                    background-color: rgba(68, 243, 218, 1);
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 12px;
                                    color: #151922;
                                }
                            }

                            .name_wrapper {
                                width: 106px;
                                height: 14px;
                                font-size: 14px;
                                // font-weight: 500;
                                color: #FFFFFF;
                                line-height: 14px;
                            }

                            .hint_wrapper {
                                width: 71px;
                                height: 14px;
                                font-size: 14px;
                                // font-weight: 400;
                                color: #FFFFFF;
                                line-height: 14px;
                            }

                            .value_wrapper {
                                height: 14px;
                                font-size: 14px;
                                // font-weight: 400;
                                color: #FFFFFF;
                                line-height: 14px;
                            }

                            &:nth-child(1),
                            &:nth-child(2),
                            &:nth-child(3) {
                                .rank_wrapper {
                                    .text {
                                        width: 16px;
                                        height: 16px;
                                        border-radius: 50%;
                                        background-color: transparent;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }
                                }
                            }

                            &:nth-child(1) {
                                .rank_wrapper {
                                    .text {
                                        background-color: rgba(252, 96, 111, 1);
                                    }
                                }
                            }

                            &:nth-child(2) {
                                .rank_wrapper {
                                    .text {
                                        background-color: rgba(177, 178, 183, 1);
                                    }
                                }
                            }

                            &:nth-child(3) {
                                .rank_wrapper {
                                    .text {
                                        background-color: rgba(173, 115, 71, 1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}