:global {
    :local(.wrapper) {
        // display: flex;
        // align-items: center;
        position: relative;

        >.picker_wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;

            >.hover_block {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        >.content {
            position: relative;
            display: flex;
            align-items: center;

            >.date {
                height: 14px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #546677;
                line-height: 14px;
                cursor: pointer;
                user-select: none;
            }

            >.icon {
                width: 16px;
                height: 16px;
                user-select: none;
                cursor: pointer;
            }

            >.time_wrapper {
                margin-left: 8px;
                height: 28px;
                border-radius: 4px;
                border: 1px solid #C9C9C9;
                background-color: #FFFFFF;
                display: flex;

                >.left,
                >.mid,
                >.right {
                    user-select: none;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                        background-color: #8abada29;
                    }
                }

                >.left,
                >.right {
                    width: 32px;

                    >.wrapper {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        >.icon {
                            width: 5px;
                            height: 10px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }

                >.right {
                    >.wrapper {
                        // 翻转
                        transform: rotateY(180deg);
                    }
                }

                >.mid {
                    min-width: 240px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 22px;
                    color: #333333;
                }
            }

        }

    }
}