.OnlineDisk{
    // padding: 30px;
    // overflow: hidden;
    min-height: 600px;
    min-width: 1100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    // background-color: #eceef1;

    .folderTree{
        display: flex;
        flex-direction: column;
        width: 220px;
        flex-shrink: 0;
        padding-right: 20px;
        background-color: #FFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
        .folderTreeBar{
            border-bottom: 1px solid #ddd;
            width: 220px;
            background-color: #FFF;
            height: 46px;
            margin-bottom: 16px;
            .createFolderBtn{
                margin-left: 15px;
                margin-top: 7px;
            }
        }
    }

    .folderPanel{
        flex-grow: 1;
        margin-left: 24px;
        background-color: #FFF;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);

        .FolderTabs{
            height: 100%;
            width: 100%;

            .ant-tabs-nav-wrap{
                width: 200px;
                flex-grow: 0;
            }
            .ant-tabs-extra-content{
                flex-grow: 1;
            }
            .tabBar{
                display: flex;
                flex-direction: row;
                flex-grow: 1;
                .barText{
                    padding-right: 140px;
                    text-align: center;
                    flex-grow: 1;
                    font-size: 16px;
                    line-height: 32px;
                }
            }

            &>.ant-tabs-content-holder{
                height: 100%;
                &>.ant-tabs-content{
                    height: 100%;
                }
            }

            .folderDisplayRadio{
                margin-top: 30px;
                margin-left: 30px;
            }

            .FilesTab{
                height: 100%;
                width: 100%;
            }
            .ScriptTab{
                height: 100%;
                width: 100%;
            }
        }
    }

    .folderModalContent{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 400px;
    }


}
