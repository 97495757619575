:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
            background: #FFFFFF;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
            border-radius: 2px;

            >.table_wrapper {
                margin: 24px 64px;

                >table {
                    width: 100%;

                    >thead {
                        >tr {
                            >th {
                                >.sort_header_wrapper {
                                    display: flex;
                                    justify-content: center;

                                    >.inner_wrapper {
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                        user-select: none;

                                        >.tips {
                                            margin-left: 6px;

                                            >.up {
                                                width: 0;
                                                height: 0;
                                                border-style: solid;
                                                border-width: 0 4px 6px 4px;
                                                border-color: transparent transparent #E6E6E6 transparent;

                                                &.selected {
                                                    border-color: transparent transparent #666666 transparent;
                                                }
                                            }

                                            >.down {
                                                margin-top: 2px;
                                                width: 0;
                                                height: 0;
                                                border-style: solid;
                                                border-width: 6px 4px 0 4px;
                                                border-color: #E6E6E6 transparent transparent transparent;

                                                &.selected {
                                                    border-color: #666 transparent transparent transparent;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    >tbody {
                        color: #333;

                        >tr {
                            >td {
                                height: 48px;

                                &.name {
                                    font-size: 16px;
                                }

                                >.value_wrapper {
                                    display: flex;
                                    align-items: flex-end;

                                    >.unit,
                                    >.value {
                                        width: 0;
                                        flex-grow: 1;
                                    }

                                    >.unit {
                                        opacity: 0.8;
                                        height: 12px;
                                        font-size: 12px;
                                        line-height: 12px;
                                    }

                                    >.value {
                                        text-align: right;
                                        font-size: 16px;
                                        line-height: 16px;
                                        height: 16px;
                                    }

                                    >.space {
                                        width: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}