.enclosure {
  width: 916px;
  background-color: #EDF7F8;
  border-radius: 16px;
  margin-top: 10px;
  padding: 16px;
  box-sizing: border-box;
  &-header {
    margin-bottom: 16px;
    img {
      vertical-align: middle;
    }
    span {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 27px;
      color: #727272;
      opacity: 1;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
  &-content {
    display: flex;
    &-type {
      width: 128px;
      height: 128px;
      background: #FFFFFF;
      opacity: 1;
      position: relative;
      border-radius: 16px;
      margin-right: 24px;
      div {
        text-align: center;
      }
      img {
        display: inline-block;
        border-radius: 16px;
      }
      .type-img {
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .number {
        position: absolute;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        opacity: 1;
        bottom: 10px;
        left: 58px;
      }
      .small-del {
        position: absolute;
        width: 30px;
        height: 18px;
        top: 86px;
        right: 0px;
      }
    }
  }
  &-line {
    width: 880px;
    height: 1px;
    background: #FFFFFF;
    margin-top: 16px;
  }
  &-chat {
    margin-top: 24px;
    img {
      vertical-align: middle;
      margin-right: 8px;
    }
    span {
      vertical-align: middle;
    }
    .chat {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 27px;
      color: #727272;
      margin-right: 16px;
    }
    .num {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #727272;
    }
  }
  &-chatRecord {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    ul {
      padding-left: 0
    }
    ul li {
      list-style: none;
    }
    &-other {
      li {
        margin-bottom: 20px;
      }
      .date {
        margin-bottom: 8px;
        &-name {
          display: inline-block;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3A3A3A;
        }
        &-time {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 20px;
          color: #727272;
          margin-left: 13px;
        }
      }
      .text {
        min-width: 176px;
        max-width: 576px;
        min-height: 24px;
        background: #238D7A;
        opacity: 0.7;
        border-radius: 16px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #FFFFFF;
        padding: 6px 8px;
        box-sizing: border-box;
      }
    }
    &-self {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      li {
        margin-bottom: 20px;
        margin-top: 12px;
      }
      .self-date {
        float: right;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #727272;
      }
      .self-text {
        width: 224px;
        background: #D9D7D7;
        border-radius: 16px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3A3A3A;
        padding: 6px 12px;
        box-sizing: border-box;
      }
    }
  }
  &-send {
    float: right;
    .ant-input {
      border-radius: 12px;
      border: 1px solid #AFDAD8;
    }
    img {
      margin-left: 8px;
      vertical-align: middle;
    }
  }
}
