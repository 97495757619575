.factor_summary_v2_single_factor_detail_custom_factor_btn_wrapper {
    .inner_wrapper {
        background-color: #000000;

        >.text {
            display: none;
        }

        &:hover {
            background-color: #44F3DA;

            >.icon {
                display: none;
            }

            >.text {
                display: block;
                color: #0D1115;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }
}