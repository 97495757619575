:global {
    :local(.wrapper) {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        >.auto_wrapper {
            height: 32px;
            display: flex;

            >.header_wrapper {
                width: 112px;
                background: #F1F1F1;
                border-radius: 16px 0px 0px 16px;
                display: flex;
                align-items: center;
                justify-content: center;

                >.icon {
                    width: 16px;
                    height: 16px;
                }

                >.text {
                    margin-left: 8px;
                    height: 14px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 14px;
                    color: #3A3A3A;
                }
            }

            >.space {
                width: 2px;
                background-color: #FFFFFF;
            }

            >.event_wrapper {
                background-color: #FF6161;
                padding: 0 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                &.last_one {
                    border-radius: 0px 16px 16px 0px;
                }

                >.text_wrapper {
                    min-width: 56px;
                    max-width: 181px;

                    >.text {
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        line-height: 14px;
                        color: #FFFFFF;
                    }
                }

                >.tool_tip_wrapper {
                    display: none;
                }

                &:hover {
                    >.tool_tip_wrapper {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        transform: translateY(100%);
                        width: 88px;
                        height: 32px;
                        background: #357CF7;
                        border-radius: 0px 0px 8px 8px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 16px;

                        >.icon {
                            width: 16px;
                            height: 16px;
                            user-select: none;
                            cursor: pointer;
                        }
                    }
                }
            }

            >.footer_wrapper {
                background: #F1F1F1;
                border-radius: 0px 16px 16px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 9px 0 8px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    cursor: pointer;
                }

                &:hover {
                    // add by ql
                    opacity: 0.8;
                }

                >.text {
                    user-select: none;
                    height: 14px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 14px;
                    color: #3A3A3A;
                }
            }

            >.no-event_wrapper {
                background: #238D7A;
                border-radius: 0px 16px 16px 0px;
                width: 200px;
                display: flex;
                align-items: center;
                padding-left: 16px;

                >.icon {
                    width: 45px;
                    height: 30px;
                }

                >.text {
                    height: 14px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 14px;
                    color: #FFFFFF;
                    margin-left: 11px;
                }
            }
        }

        >.delete_wrapper {
            width: 32px;
            height: 32px;
            background: #357CF7;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                cursor: pointer;
            }

            &:hover {
                // add by ql
                opacity: 0.8;
            }

            >.icon {
                user-select: none;
                width: 16px;
                height: 16px;
            }
        }
    }

    :local(.cus_popover_wrapper) {
        .ant-popover-inner-content {
            padding: 0;
        }
    }
}