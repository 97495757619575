:global {
    :local(.HourlyChart) {
        padding: 40px 12px 0 12px;
        display: flex;
        flex-direction: column;

        .chartNode {
            height: 304px;
        }
    }

}