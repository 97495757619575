:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 2px;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);

        >.header {
            height: 56px;
            display: flex;
            align-items: center;
            padding-left: 20px;

            >.icon {
                width: 20px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }

            >.title {
                margin-left: 9px;
                height: 16px;
                font-size: 16px;
                font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                font-weight: 500;
                color: #357CF7;
                line-height: 16px;
            }

            >.subtitle_wrapper {
                width: 0;
                flex-grow: 1;
                align-self: stretch;
            }

            >.watch_detail {
                padding-right: 16px;
                margin-left: auto;
                display: flex;
                align-items: center;
                user-select: none;
                cursor: pointer;

                >.text {
                    height: 14px;
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #666666;
                    line-height: 14px;
                }

                >.icon {
                    margin-left: 5px;
                    width: 6px;
                    height: 12px;
                }
            }
        }

        >.line {
            height: 1px;
            background-color: #C9C9C9;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        }

        >.content {
            height: 0;
            flex-grow: 1;
        }
    }
}