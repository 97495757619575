:global {
    :local(.wrapper){
         background-color: #FFFFFF;
         border-radius: 2px;
         display: flex;
         margin-left: 45px;
         align-items: center;
         text-align: center;
         border: 1px solid #fff ;
         >.item {
             width: 90px;
             cursor: pointer;
             user-select: none;
             height: 28px;
             line-height: 28px;
             font-size: 14px;
             font-family: Source Han Sans CN;
             font-weight: 400;
             color: #333;

             &.selected {
                 background-color: #355B99;
                 color: #fff !important;
             }
         }
    }
    // :local(.wrapper) {
    //     height: 32px;
    //     display: flex;
    //     cursor: pointer;
    //     user-select: none;

    //     >.item {
    //         // width: 56px;
    //         display: flex;
    //         align-items: center;
    //         position: relative;

    //         >.click_area {
    //             position: absolute;
    //             top: 0;
    //             right: 0;
    //             bottom: 0;
    //             left: 0;
    //         }

    //         // &:nth-child(n+2) {
    //         //     border-style: solid;
    //         //     border-width: 2px 2px 2px 0;
    //         // }

    //         &:last-child {
    //             border-radius: 0 2px 2px 0 / 0 2px 2px 0;
    //         }

    //         >.arrow_wrapper {
    //             width: 12px;

    //             // &::after {
    //             //     content: '';
    //             //     display: block;
    //             //     width: 0;
    //             //     height: 0;
    //             //     border-style: solid;
    //             //     border-width: 4px 0 4px 4px;
    //             //     border-color: transparent transparent transparent #333;
    //             // }
    //         }

    //         >.text {
    //             height: 14px;
    //             font-size: 14px;
    //             font-family: Source Han Sans CN;
    //             font-weight: bold;
    //             line-height: 14px;
    //             color: #2B3249;
    //             white-space: nowrap;
    //         }

    //         >.arrow_2_wrapper {
    //             width: 11px;
    //             padding-left: 1px;

    //             &::after {
    //                 content: '';
    //                 display: block;
    //                 width: 6px;
    //                 height: 6px;
    //                 border-style: solid;
    //                 border-width: 1px 1px 0 0;
    //                 border-color: #2B3249;
    //                 transform: rotate(45deg);
    //             }
    //         }

    //         >.child_name {
    //             height: 12px;
    //             font-size: 12px;
    //             font-family: Source Han Sans CN;
    //             font-weight: 400;
    //             line-height: 12px;
    //             color: #2B3249;
    //             white-space: nowrap;
    //         }
    //         >.content_wrapper {
    //             // width: 168px;
    //             background: #FFFFFF;
    //             border-radius: 2px;
    //             display: flex;
    //             margin-left: 45px;
    //             align-items: center;
    //             text-align: center;
    //             border: 1px solid #fff ;
    //             >.item {
    //                 width: 90px;
    //                 cursor: pointer;
    //                 user-select: none;
    //                 height: 28px;
    //                 line-height: 28px;
    //                 font-size: 14px;
    //                 font-family: Source Han Sans CN;
    //                 font-weight: 400;
    //                 color: #333;

    //                 &.selected {
    //                     background-color: #355B99;
    //                     color: #fff !important;
    //                 }
    //             }
    //         }
    //         >.hover_menu_wrapper {
    //             display: none;
    //         }

    //         &:hover {

    //             >.arrow_wrapper {
    //                 &::after {
    //                     border-width: 8px 4px 0 4px;
    //                     border-color: #355B99 transparent transparent transparent;
    //                 }
    //             }

    //             // >.hover_menu_wrapper {
    //             //     display: block;
    //             //     position: absolute;
    //             //     z-index: 2; // hard code
    //             //     bottom: 0;
    //             //     // left: 50%;
    //             //     // transform: translate(-50%, 100%);
    //             //     padding-top: 6px;
    //             //     left: 0;
    //             //     right: 0;
    //             //     transform: translateY(100%);
    //             // }
    //         }

    //         // &.selected {
    //         //     // background-color: #355B99;

    //         //     >.arrow_wrapper {
    //         //         &::after {
    //         //             border-color: transparent transparent transparent #FFFFFF;
    //         //         }
    //         //     }

    //         //     >.text {
    //         //         color: #FFFFFF;
    //         //     }

    //         //     >.arrow_2_wrapper {
    //         //         &::after {
    //         //             border-color: #FFFFFF;
    //         //         }
    //         //     }

    //         //     >.child_name {
    //         //         color: #FFFFFF;
    //         //     }

    //         //     &:hover {

    //         //         >.arrow_wrapper {
    //         //             &::after {
    //         //                 border-color: #FFFFFF transparent transparent transparent;
    //         //             }
    //         //         }
    //         //     }

    //         // }
    //     }
    // }
}