:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px 56px 0 72px;

        >.content {
            height: 0;
            flex-grow: 1;
            padding-top: 200px;
        }
    }
}