.main_page_wrapper {
    height: 100%;

    display: flex;
    flex-direction: column;

    .system_root_content_top_wrapper {
        height: 68px;

        .header_out_wrapper {
            height: 100%;
            background-color: rgba(33, 40, 48, 1);
            // position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 24px;
            border-bottom: 1px solid #3DCDB9;

            .title_text {
                height: 22px;
                font-size: 16px;
                // font-family: PingFangSC-Regular, PingFang SC;
                // font-weight: 400;
                color: #9FA0BA;
                line-height: 22px;
                // width: 0;
                // flex-grow: 1;
            }

            .date_text {
                height: 24px;
                font-size: 24px;
                // font-family: Helvetica;
                color: #FFFFFF;
                line-height: 24px;
                // width: 300px;
                margin: 0 auto;
            }
        }
    }

    .scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .content_out_wrapper {
        height: 0;
        flex-grow: 1;
        // height: 100%;
        overflow-y: auto;

        .content_wrapper {
            height: 100%;
            min-height: 966px;
            display: flex;
            flex-direction: column;

            .top_wrapper {
                height: 696px;
                padding: 24px 28px 0 24px;
                display: flex;

                .left_chart_wrapper {
                    width: 1236px;
                }

                .right_table_wrapper {
                    margin-left: 12px;
                    width: 0;
                    flex-grow: 1;
                }
            }

            .bottom_wrapper {
                height: 0;
                flex-grow: 1;
                padding: 12px 28px 18px 24px;
            }
        }
    }
}