:global {
    :local(.wrapper) {
        height: 100%;

        >.item_wrapper {
            position: absolute;

            >.point {
                width: 8px;
                height: 8px;
                background: #CC9806;
                border-radius: 50%;
                opacity: 1;
                position: relative;

                >.line {
                    position: absolute;
                    background-color: #CC9806;

                    >.line {
                        position: absolute;
                        background-color: #CC9806;

                        >.content_wrapper {
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
}