.tabs {
  ul {
    display: flex;
  }
  .tabs-title {
    list-style: none;
    padding: 0px;
    margin-left: 16px;
    cursor: pointer;
  }
  .isBorder {
    border-bottom: 3px solid #357CF7;
  }
}
