.wrapper {
    min-height: 100%;
    padding: 24px;

    >:global(.header) {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}