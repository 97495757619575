:global {
    :local(.wrapper) {
        display: inline-block;

        >.sort_icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &::before,
            &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                opacity: 0.3;
            }

            &::before {
                border-width: 0 4px 6px 4px;
                border-color: transparent transparent #666666 transparent;
            }

            &::after {
                margin-top: 2px;
                border-width: 6px 4px 0 4px;
                border-color: #666666 transparent transparent transparent;
            }

            &.desc {
                &::after {
                    opacity: 1;
                }
            }

            &.asc {
                &::before {
                    opacity: 1;
                }
            }
        }
    }
}