:global {
    :local(.board_state_wrapper) {
        height: 175px;
        flex-shrink: 0;
        margin-top: 12px;
        background: #FFFFFF;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 14px 35px;
        position: relative;

        .item_wrapper {
            height: 116px;
            width: 170px;
            flex-shrink: 0;
            background: rgba(245, 246, 248, 1);
            border-radius: 2px;
            padding: 14px 14px 12px 20px;
            display: flex;
            flex-direction: column;

            >div {
                opacity: 0.85;
            }

            .title {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .value_wrapper {
                display: flex;
                align-items: flex-end;

                .value {
                    height: 38px;
                    font-size: 30px;
                    font-weight: 500;
                    color: #1890FF;
                    line-height: 38px;
                }

                .unit {
                    height: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #232324;
                    line-height: 20px;
                    margin-left: 6px;
                }
            }

            .range {
                height: 20px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                line-height: 20px;
                margin-top: auto;
            }
        }

        .page_switch_wrapper {
            position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;

            >div:not(:first-child) {
                margin-left: 6px;
            }

            .dot {
                width: 10px;
                height: 10px;
                background: rgba(232, 232, 232, 1);
                border-radius: 50%;
                cursor: pointer;
            }

            .current_dot {
                background: #357CF7;
            }
        }
    }
}