:global {
    :local(.race_setting_content_wrapper) {
        height: 100%;
        padding: 24px 56px 0 72px;
        position: relative;

        .btn_header_wrapper {
            position: absolute;
            right: 56px;
            top: 0;
            transform: translateY(-100%);
            display: flex;
            align-items: center;


            .opt_title {
                margin: 0 24px 0 auto;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
            }

            .btn_setting {
                margin-left: 8px;
            }
        }

        .content_wrapper {
            height: 100%;
            background: rgba(255, 255, 255, 1);
            border-radius: 16px;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);

            .header {
                // font-size: 14px;
                // font-weight: 400;
                // color: rgba(0, 0, 0, 0.45);
                // line-height: 22px;
                padding: 16px 24px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                display: flex;
                align-items: center;

                .race_name {
                    margin-right: 10px;
                }
            }

            .content {
                padding: 32px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                >.item_block_wrapper {
                    width: calc(25% - 24px);
                    padding: 24px;
                    background: rgba(255, 255, 255, 1);
                    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
                    border-radius: 4px;
                    position: relative;
                    min-width: 340px;
                    margin-bottom: 40px;

                    >.custom_checkbox {
                        position: absolute;
                        bottom: -10px;
                        left: 50%;
                        transform: translate(-50%, 100%);
                    }

                    .title_wrapper {
                        margin-bottom: 8px;

                        >img {
                            float: left;
                            display: block;
                            width: 80px;
                            height: 80px;
                            margin-right: 24px;
                        }

                        .title {
                            padding: 8px 0 12px;
                            font-size: 20px;
                            font-weight: 500;
                            color: rgba(0, 0, 0, 0.85);
                            line-height: 28px;
                        }
                    }

                    .row_block {
                        padding-top: 32px;
                        display: flex;
                        align-items: flex-start;

                        >img {
                            width: 18px;
                            height: 18px;
                            margin-right: 12px;
                            display: block;
                            position: relative;
                            top: 2px;
                        }

                        >div {
                            .name {
                                font-size: 14px;
                                font-weight: 400;
                                color: rgba(0, 0, 0, 0.65);
                                line-height: 22px;
                                padding-bottom: 4px;
                            }

                            .value {
                                font-size: 14px;
                                font-weight: 500;
                                color: rgba(0, 0, 0, 0.85);
                                line-height: 22px;
                                height: 22px;
                            }

                            .people_block {
                                display: flex;
                                flex-wrap: wrap;

                                >div {
                                    margin-right: 30px;
                                }
                            }
                        }
                    }
                }

                >.item_block_wrapper_blank {
                    width: calc(25% - 24px);
                    min-width: 340px;
                }
            }
        }
    }
}