:global {
    :local(.wrapper) {
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        background: #FFFFFF;
        padding: 16px 76px 0 76px;
        height: 100%;
        position: relative;

        .title_row {
            display: flex;
            align-items: center;

            .title {               
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 37px;
                color: #727272;
            }

            .arrow {
                margin-left: auto;

                &.down_arrow {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 6px 0 6px;
                    border-color: #FB6060 transparent transparent transparent;
                }
            }

            .percent_text {
                margin-left: 7px;
                height: 24px;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 24px;
                color: #FB6060;
            }
        }

        .value_row {
            margin-top: 12px;
            margin-bottom: 0;
            display: flex;
            align-items: flex-end;

            .value {
                height: 45px;                
                font-size: 45px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 45px;
                color: #3A3A3A;
            }

            .unit {
                height: 28px;
                margin-left: 4px;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 24px;
                color: #3A3A3A;
            }
        }

        .divide_line_row {
            margin-top: 17px;
            height: 1px;
            background-color: #c9c9c9;
        }

        .switch_time_row {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .time_text {
                height: 24px;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 24px;
                color: #3a3a3a;
                margin: 0 10px;
                user-select: none;
            }
        }

        // .chart_block_row {}
    }
}