:global {
    :local(.EventPanel) {
        height: 100%;
        display: flex;
        flex-direction: column;
        
        .header-bar {
            height: 56px;
            background: #fff;
            border-bottom: 1px solid #D4D4D4;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;

            .title{
            margin: 0;
            color: #357CF7;
            font-size: 16px;
            font-weight: 500;
            line-height: 56px;
            text-indent: 28px;
            padding-left: 18px;
            background: url('../../../../../assets/images/v3/icon_events.png') no-repeat left;
            background-origin: content-box;
            }
            .ant-btn{
                display: flex;
                align-items: center;
                padding: 4px 8px;
                .search-icon{
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    background: url('../../../../../assets/images/v3/search_record.png'); 
                    margin-right: 2px;   
                }
            }                    
        }
        .center{
            height: 0;
            flex: 1;
            display: flex;
            .time-line{
                width: 264px;
                height: 100%;
                background: #fff;
            }
            .event-container{
                flex: 1;
            }
        }
        .footer-bar{
            height: 102px;
            background: #fff;
            border-top: 1px solid #D4D4D4;
        }
    }
}