:global {
    :local(.main_page_v2_elec_detail_block_wrapper) {
        height: 100%;

        table {
            border-spacing: 0 2px;
        }

        .ant-table-wrapper {
            height: 100%;

            .ant-spin-nested-loading {
                height: 100%;

                .ant-spin-container {
                    height: 100%;

                    .ant-table.ant-table-fixed-header {
                        height: 100%;

                        .ant-table-container {
                            height: 100%;
                            display: flex;
                            flex-direction: column;

                            .ant-table-header {
                                flex-shrink: 0;
                            }
                        }
                    }
                }
            }
        }

        .row_red {
            &>td {
                color: #F5222D;
                background-color: rgba($color: #FFF1F0, $alpha: 1);
                border-style: solid;
                border-color: #FFA39E;
                border-width: 1px 0 1px 0;
                margin-top: 5px;

                &:first-child {
                    border-radius: 5px 0 0 5px;
                    border-width: 1px 0 1px 1px;
                }

                &:last-child {
                    border-radius: 0 5px 5px 0;
                    border-width: 1px 1px 1px 0;
                }
            }
        }

        .table_title {
            color: #357CF7;
        }

        .arrow {
            width: 0;
            height: 0;
            border-style: solid;
            cursor: pointer;
            margin: 0 auto;
        }

        .arrow_down {
            border-width: 14px 7px 0 7px;
            border-color: #357CF7 transparent transparent transparent;
        }

        .arrow_up {
            border-width: 0 7px 14px 7px;
            border-color: transparent transparent #357CF7 transparent;
        }
    }
}