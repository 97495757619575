.cardList {
  display: flex;
  margin-bottom: 24px;
  padding-left: 0px;
  li {
    list-style: none;
    width: 200px;
    height: 120px;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 1;
    border-radius: 16px;
    margin-right: 16px;
    padding: 16px;
    cursor: pointer;
    .cardItem-top {
      margin-bottom: 8px;
      span {
        display: inline-block;
      }
      &-title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: #727272;
        margin-right: 8px;
      }
      &-text {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: #727272;
        margin-right: 17px;
      }
      &-percent {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 24px;
        //color: #FF6161;
      }
    }
  }
}
.noMarinLeft {
  margin-right: 0px;
}
.cardItem {
  padding: 16px;
}

.cardItem-center {
  margin-bottom: 13px;
}
.number {
  font-size: 18px;
  font-weight: bold;
  color: #3A3A3A;
}
