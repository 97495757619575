:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        >.iframe_wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            >iframe {
                opacity: 0;
                width: 100%;
                height: 100%;
            }
        }

        >.three_chart_block {
            position: relative;
            height: 100%;
            overflow: hidden;
        }

        >.outer_wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        >.control_bar_wrapper {
            display: none;
        }

        &:hover {
            >.control_bar_wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: block;
                padding-left: 12px;
                padding-right: 12px;
                padding-bottom: 3px;
            }
        }
    }
}