:global {
    :local(.wrapper) {
        display: flex;
        flex-direction: column;
        position: relative;

        >.top {
            display: flex;
        }

        >.bottom {
            margin-top: 24px;
        }
    }

    :local(.watch_detail) {
        display: inline-block;
        height: 14px;
        font-size: 14px;
        font-family: SourceHanSansCN-Normal;
        line-height: 14px;
        color: #1890FF;
        user-select: none;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }


    :local(.sort) {
        >.wrapper {
            display: inline-block;
            user-select: none;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }

            >.content {
                display: flex;
                // 这里不用inline-block可以少一层嵌套
                // 然而这样和hover opacity冲突了
                // 还是需要多一层，包裹点击事件
                align-items: center;
                justify-content: center;

                >.text {
                    margin-right: 4px;
                }
            }
        }
    }

    :local(.cost_td_block) {
        display: flex;
        align-items: center;

        >.left {
            // width: 0;
            flex-grow: 1;
            text-align: right;
            white-space: nowrap;
            height: 12px;
            font-size: 12px;
            font-family: PingFangSC-Medium;
            line-height: 12px;
            color: #5BAE8D;
        }

        >.right {
            margin-left: 10px;
            width: 120px;

            >.content {
                border-radius: 2px;
                background-color: rgba(128, 128, 128, 0.1);

                >.bar_value {
                    height: 10px;
                    background-color: #5BAE8D;
                    border-radius: 2px;
                }
            }
        }
    }

    :local(.content) {
        display: flex;
        align-items: center;
        justify-content: center;

        >.arrow {
            width: 0;
            height: 0;
            margin-right: 4px;
            border-style: solid;

            &.up {
                border-width: 0 4px 8px 4px;
                border-color: transparent transparent #666666 transparent;
            }

            &.down {
                border-width: 8px 4px 0 4px;
                border-color: #666666 transparent transparent transparent;
            }
        }

        &.green {
            color: #3AA372;

            >.arrow {

                &.up {
                    border-color: transparent transparent #3AA372 transparent;
                }

                &.down {
                    border-color: #3AA372 transparent transparent transparent;
                }
            }
        }

        &.red {
            color: #FB6060;

            >.arrow {

                &.up {
                    border-color: transparent transparent #FB6060 transparent;
                }

                &.down {
                    border-color: #FB6060 transparent transparent transparent;
                }
            }
        }

    }
}