.system_overflow_wrapper {
    height: 100%;
    overflow: auto;
    background: #15191E;

    .system_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        min-height: 900px;
        min-width: 1920px;

        .system_root_header_wrapper {
            height: 60px;
        }

        .system_root_sidemenu_content_wrapper {
            flex-grow: 1;
            height: 0;
            position: relative;
            display: flex;

            .system_root_sidemenu_wrapper {
                width: 216px;
            }

            .system_root_content_wrapper {
                width: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .system_root_content_bottom_wrapper {
                    height: 0;
                    flex-grow: 1;
                }
            }
        }

    }
}