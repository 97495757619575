.main_page_bottom_panels_wrapper {
    height: 100%;
    display: flex;

    .panel_wrapper {
        width: 0;
        flex-grow: 1;
        border: 1px solid rgba(68, 242, 217, 0.2);
        background-color: rgba(33, 40, 48, 1);
        display: flex;
        flex-direction: column;

        &:not(:first-child) {
            margin-left: 12px;
        }

        .title_wrapper {
            height: 46px;
            background-color: rgba(13, 17, 21, 1);
            padding-left: 12px;
            padding-right: 8px;
            display: flex;
            align-items: center;

            .title_icon {
                width: 24px;
                height: 24px;
            }

            .text {
                margin-left: 5px;
                height: 18px;
                font-size: 18px;
                // font-weight: 500;
                color: #FFFFFF;
                line-height: 18px;
            }

            .see_more {
                margin-left: auto;
                display: flex;
                align-items: center;
                cursor: pointer;
                user-select: none;

                .see_more_text {
                    height: 14px;
                    font-size: 14px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 14px;
                }

                .see_more_icon {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .bottom_panel_aLine {
            height: 1px;
            background-color: rgba(68, 243, 218, 0.2);
        }

        .bottom_panel_content_wrapper {
            height: 0;
            flex-grow: 1;
        }
    }
}