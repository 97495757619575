:global {
    :local(.wrapper) {
        height: 100%;

        :local(.content) {
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;

            >.title_wrapper {
                display: flex;
                align-items: center;

                >div {
                    text-align: center;
                    // height: 12px;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 12px;
                }

                >.title_1 {
                    width: 0;
                    flex-grow: 203;
                    line-height: 14px;
                    position: relative;

                    >.sort {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);

                        >.wrapper {
                            display: inline-block;
                            user-select: none;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            >.content {
                                display: flex;
                                // 这里不用inline-block可以少一层嵌套
                                // 然而这样和hover opacity冲突了
                                // 还是需要多一层，包裹点击事件
                                align-items: center;
                                justify-content: center;

                                >.text {
                                    margin-right: 4px;
                                }
                            }
                        }
                    }
                }

                >.title_2 {
                    width: 0;
                    flex-grow: 114;
                }

                >.title_3 {
                    width: 0;
                    flex-grow: 123;
                }
            }

            >.scroll_wrapper {
                height: 0;
                flex-grow: 1;
                overflow-y: auto;

                >.content {
                    padding: 0 16px;

                    >.item_wrapper {
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}