.yieldTarget {
  padding-left: 8px;
  border-bottom: 1px solid #8DA1AF;
  &-header {
    display: flex;
    color: #A6B2C2;
    margin-bottom: 8px;
    &-left {
      margin-right: 200px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
    }
    &-right {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 16px;
      color: #A6B2C2;
      opacity: 0.8;
    }
  }
  &-item {
    display: flex;
    margin-bottom: 16px;
    &-left {
      flex: 1;
      .item-name {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #FEBC05;
        opacity: 0.8;
        margin-bottom: 16px;
      }
      .item-progress {
        width: 216px;
        height: 8px;
        background: #464A57;
        opacity: 1;
        border-radius: 8px;
        position: relative;
        &-proNum {
          width: 67%;
          height: 100%;
          background: #FEBC05;
          border-radius: 8px;
        }
        .benchmark {
          position: absolute;
          top: -30px;
          right: 0px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 24px;
          color: #FFFFFF;
        }
        .triangle-down {
          position: absolute;
          top: -10px;
          right: 32px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 8px solid #FFFFFF;
        }
      }
    }
  }
}
