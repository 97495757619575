.devicesStatus {
  width: 456px;
  height: 304px;
  background-color: #13191F;
  border-radius: 16px;
  padding: 16px;
  font-family: 'Microsoft YaHei';

  .model-title {
    font-size: 18px;
    color: #A6B2C2;
    line-height: 24px;
  }

  &-main {
    margin: 13px 8px 0;
    display: flex;

    &-left {
      width: 260px;

      .rulerBar-group {
        margin-left: 26px;
      }

      ul {
        margin-top: 8px;
        padding-left: 0;
        list-style: none;

        li {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;

          &>div {
            width: 58px;
            text-align: center;

            &:first-child {
              width: 44px;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #8DA1AF;
              opacity: 0.8;
            }
          }

          &.tr-1 {
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            color: #FFFFFF;
          }

          &.tr-2 {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            color: #FFFFFF;
            opacity: 0.8;
          }

          &.tr-3 {
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            color: #8DA1AF;
            opacity: 0.8;
          }
        }
      }

      &-bottom {
        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          color: #8DA1AF;
          opacity: 0.8;
          margin-right: 8px;

          &:last-child {
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            color: #FFFFFF;
            opacity: 1;
          }
        }
      }
    }

    &-right {
      margin-left: 16px;
      flex: 1;

      .rulerBar-group {
        margin-left: 10px;
      }

      ul {
        list-style: none;
        padding-left: 10px;
        margin-top: 8px;

        li {
          display: flex;
          align-items: flex-end;
          margin-bottom: 10px;

          &>div {
            width: 58px;
            text-align: center;
          }

          &:first-child {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #8DA1AF;
            opacity: 0.8;
          }

          &:nth-child(2) {
            font-size: 18px;
            font-weight: bold;
            line-height: 24px;
            color: #FFFFFF;
          }
        }
      }

      &-bottom {
        padding-left: 20px;

        span {
          font-size: 12px;
          font-weight: 400;
          line-height: 12px;
          color: #8DA1AF;
          opacity: 0.8;
          margin-right: 8px;

          &:last-child {
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            color: #FFFFFF;
            opacity: 1;
          }
        }
      }
    }

    .ant-divider {
      height: 232px;
      margin: 0;
      border-color: #8494bf;
    }
  }

  .device-title {
    font-size: 14px;
    line-height: 19px;
    color: #FEBC05;
    font-weight: 400;
    opacity: 0.8;
    margin-bottom: 16px;
  }
}
