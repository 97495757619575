.DiagramGenWrapper {
    display: flex;
    height: 100%;
    min-height: 400px;
    width: 100%;
    min-width: 1000px;
    >div {
        box-sizing: border-box;
        border: 1px solid grey;
    }
    .inputLabel {
        width: 60px;
        line-height: 30px;
        text-align: center;
        box-sizing: border-box;
        border-radius: 3px;
        background-color: #1890ff;
        cursor: pointer;
        color: white;
    }
    .inputLabel:hover {
        background-color: #40a9ff;
    }
    .inputFile {
        display: none;
    }
    .LeftWrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .editWrapper {
            flex-grow: 1;
        }
    }
}