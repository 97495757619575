$template-color:#357CF7;

.report-container {
    flex-grow: 1;
    width: 415px;
    height: 100%;
    background-color: #fff;
    display: inline-block;
    border-radius: 16px;
    padding: 16px 0;
    overflow: hidden auto;
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);

    .title {
        margin: 0 16px 34px 16px;
        color: #727272;
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        text-indent: 20px;
        background: url('../../../assets/svg/report_icon.svg') no-repeat;
        margin-bottom: 34px;
    }

    ul.report-records {
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 20px;

            .fill-count {
                padding: 0 24px;
                color: $template-color;
            }

            .card-bg{
                padding: 8px 24px;
                &.active{
                    background-color: rgba(175, 218, 216, .3);
                }
            }

            .report-card {
                height: 48px;
                line-height: 48px;
                padding: 0 16px;
                color: #727272;
                border-radius: 8px;
                cursor: pointer;
                position: relative;               
                background: #F1F1F1 url('../../../assets/svg/icon_fill_ok.svg') no-repeat right;
                background-origin: content-box;
                &>span {
                    padding-right: 15px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                }

                &.need-fill {
                    position: relative;
                    color: #fff;
                    background: $template-color url('../../../assets/svg/icon_fill.svg') no-repeat right;
                    
                    background-origin: content-box;
                    // &::after {
                    //     content: '';
                    //     position: absolute;
                    //     width: 20px;
                    //     height: 20px;
                    //     right: -50px;
                    //     border: solid $template-color;
                    //     border-width: 0 2px 2px 0;
                    //     transform: rotate(-45deg);
                    //     -webkit-transform: rotate(-45deg);         
                    // }
                }
            }
        }
    }
}