:global {
    :local(.sample_maintenance_wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px 35px 0 72px;
        position: relative;

        .ant-table-body,
        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 10px;
                background-color: rgba(0, 0, 0, 0);
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: #dadada;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
                background-color: rgba(200, 200, 200, 0.1);
            }
        }

        >.tabs_wrapper {
            position: absolute;
            top: 0;
            left: 580px; // hard code
            transform: translateY(-100%);

            >.content_header {
                height: 32px;
                display: flex;
                cursor: pointer;
                user-select: none;

                >div {
                    display: flex;
                    align-items: center;
                    border-color: #fff;
                    padding-left: 16px;
                    padding-right: 16px;
                    background-color: #fff;
                    // &:first-child {
                    //     border-style: solid;
                    //     border-width: 2px;
                    //     border-radius: 2px 0 0 2px / 2px 0 0 2px;
                    // }

                    // &:nth-child(n+2) {
                    //     border-style: solid;
                    //     border-width: 2px 2px 2px 0;
                    // }

                    // &:last-child {
                    //     border-radius: 0 2px 2px 0 / 0 2px 2px 0;
                    // }

                    >.text {
                        font-size: 14px;
                        line-height: 22px;
                        font-weight: bold;
                        color: #3A3A3A;
                    }

                    &.selected_system {
                        border-color: #fff;
                        background-color: #355B99;

                        >.text {
                            color: #FFFFFF;
                        }

                    }
                }
            }
        }

        .sample_maintenance_content_wrapper {
            flex-grow: 1;
            height: 0;
            overflow-y: auto;

            .content_body_wrapper {
                overflow-x: auto;
                display: flex;
                min-height: 100%;
                padding-bottom: 21px;
                padding-right: 20px;

                .left_wrapper {
                    width: 376px;
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;

                    .left_bottom_wrapper {
                        height: 0;
                        flex-grow: 1;
                        background: #FFFFFF;
                        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);
                        border-radius: 2px;
                        min-height: 300px;
                    }
                }

                .right_wrapper {
                    margin-left: 12px;
                    width: 0;
                    flex-grow: 1;
                    background: #FFFFFF;
                    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);
                    border-radius: 2px;
                    padding: 16px 24px;
                }
            }
        }
    }
}