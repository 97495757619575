:global {
    :local(.contentViewWrapper) {
        .content-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;

            &>span {
                color: #2B3249;
                font-size: 16px;
                font-weight: 500;

                span {
                    color: #999;
                    font-size: 12px;
                }
            }

            &>div {
                display: flex;
                font-size: 14px;
                color: #595959;

                .time {
                    text-indent: 20px;
                    background: url('../images/time.png') no-repeat left;
                }

                .classify {
                    margin-left: 24px;
                    text-indent: 20px;
                    background: url('../images/classify.png') no-repeat left;
                }
            }
        }

        .content {
            padding: 24px 0;
            border-top: 1px solid #E2E4E7;
            border-bottom: 1px solid #E2E4E7;

            .title {
                font-size: 18px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 500;
            }

            .custom-info {
                margin-top: 12px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
}