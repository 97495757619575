:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;

        >.item_wrapper {
            width: 200px;
        }
    }
}