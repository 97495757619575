:global {
    :local(.wrapper) {
        height: 100%;

        >.bck_block_wrapper {
            height: 100%;
            width: 8px;
            position: relative;
            left: 160px;
            background: #F1F1F1;
            border-radius: 16px;
            position: relative;

            >.icon_arrow {
                position: absolute;
                left: -24px;
                transform: translateX(-100%);
                user-select: none;
                cursor: pointer;

                &:hover {
                    // add by ql
                    opacity: 0.8;
                }

                &.icon_arrow_up {
                    top: 0;

                    &.current {
                        filter: grayscale(100%);
                        opacity: 0.5;
                    }
                }

                &.icon_arrow_down {
                    bottom: 0;
                }
            }

            >.current_tag {
                position: absolute;
                right: -24px;
                top: 0;
                transform: translateX(100%);
                width: 48px;
                height: 16px;
                background: #357CF7;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                user-select: none;
                cursor: pointer;

                &:hover {
                    // add by ql
                    opacity: 0.8;
                }

                >.text {
                    height: 12px;
                    font-size: 12px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 12px;
                    color: #FFFFFF;
                }
                .new-event-count{
                    position: absolute;
                    right: 0;
                    transform: translate(100%, -50%);
                    .ant-badge-count {
                        font-size: 12px;
                        padding: 0;
                    }
                }
            }

            >.time_line_range {
                position: absolute;
                left: 0;
                right: 0;
                top: 64px;
                bottom: 64px;

                >.single_time_stamp {
                    position: absolute;
                    left: 0;
                    right: 0;

                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        // width: 12px;
                        height: 12px;
                        background: #B4B4B4;
                        border-radius: 50%;
                        opacity: 1;
                    }

                    >.content_wrapper {
                        position: absolute;
                        top: 50%;
                        display: flex;
                        align-items: center;

                        >.text {
                            height: 12px;
                            font-size: 12px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 12px;
                            color: #3A3A3A;
                            white-space: nowrap;
                        }

                        >.space {
                            width: 8px;
                        }

                        >.line {
                            height: 1px;
                            width: 24px;
                            background-color: #B4B4B4;
                        }
                    }

                    &.hand_over_time {
                        &::after {
                            // width: 14px;
                            height: 14px;
                            background-color: #238D7A;
                        }

                        >.content_wrapper {
                            >.text {
                                color: #238D7A;
                                font-weight: bold;
                            }

                            >.line {
                                background-color: #238D7A;
                                height: 2px;
                            }

                            >.space2 {
                                width: 3px;
                            }

                            >.avatar {
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
                .cursor-range{
                    width: 24px;
                    margin-left: -8px;
                    min-height: 1px;
                    position: absolute;
                    z-index: 1;
                    background-color: rgba(0, 103, 107, .5);
                }
                .capsuleMarkGroup {
                    position: absolute;
                    z-index: 0;
                    display: flex;
                    align-items: center;
                    transform: translateY(-50%);
                    .capsuleMark{
                        position: relative;
                        display: inline-block;
                        border-radius: 50%;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                    }
                }
            }
        }
    }
}