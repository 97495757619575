.factor_summary_v2_single_factor_detail_advice_block_wrapper {
    >table {
        >thead {
            >tr {
                >th {
                    padding: 0;
                    font-size: 12px;
                    line-height: 12px;
                    color: #9FA0BA;
                }
            }
        }

        >tbody {
            >tr {
                >td {
                    padding: 0;
                }
            }
        }
    }
}