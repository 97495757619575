:global {
    :local(.wrapper) {
        height: 100%;
        padding: 16px 2px 0 18px;

        >.scroll_wrapper {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 0 18px 12px 0;

            >table {
                width: 100%;

                >tbody {
                    >tr {
                        >td {
                            padding: 14px 0 14px 0;
                            font-size: 12px;
                            font-family: SourceHanSansCN-Normal;
                            line-height: 12px;
                            color: #333333;

                            &:first-child {
                                border-top-left-radius: 4px;
                                border-bottom-left-radius: 4px;
                            }

                            &:last-child {
                                border-top-right-radius: 4px;
                                border-bottom-right-radius: 4px;
                            }
                        }

                        &:nth-child(odd) {
                            >td {
                                background-color: rgba(248, 248, 248, 1);
                            }
                        }

                        &:nth-child(even) {
                            >td {
                                background-color: rgba(255, 255, 255, 1);
                            }
                        }
                    }
                }
            }
        }
    }
}