:global {
    :local(.wrapper) {
        min-height: 100%;
        padding: 24px;
        display: flex;
        flex-direction: column;

        >.header {
            height: 40px;
            display: flex;
            align-items: center;
        }

        >.grid_wrapper {
            height: 0;
            flex-grow: 1;
            border: 1px solid rgba(0, 0, 0, 0.5);

            .layout_item {
                .tool_tip {
                    display: none;
                }

                &:hover {
                    .tool_tip {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        padding-right: 12px;
                    }
                }
            }
        }
    }
}