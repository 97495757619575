:global {
    :local(.more-btn) {
        white-space: nowrap;
        color: #357CF7;
        font-size: 12px;
        margin: 0 15px;
        cursor: pointer;
        position: relative;
    }

    :local(.wrapper) {        
        .ant-popover-inner-content {
            padding: 0;
            width: 518px;
            height: 688px;
        }

        .pop-top {
            position: relative;
            width: 100%;
            height: 56px;
            background-color: #E2E4E7;
            line-height: 56px;
            font-size: 16px;
            color: #000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 24px;

            &::before {
                position: absolute;
                left: 0;
                content: '';
                display: inline-block;
                border-top: 4px solid transparent;
                border-left: 4px solid #375B98;
                border-bottom: 4px solid transparent;
                height: 24px;
                width: 4px;
            }

            .fold-btn {
                display: block;
                width: 24px;
                height: 24px;
                background: url('../../../../../../../assets/images/down_arrow.png');
                cursor: pointer;
            }
        }

        .table-wrapper {
            position: relative;
            list-style: none;
            margin: 0;
            padding: 20px 16px;
            background-color: #fff;            

            li {
                display: flex;
                border-bottom: 1px solid #B9C5DA;

                &>div {
                    white-space: nowrap;
                    color: rgba(0, 0, 0, 0.65);
                    font-size: 12px;
                    line-height: 54px;
                    text-align: center;

                    &.order {
                        width: 60px;
                        min-width: 60px;
                    }

                    &.time {
                        width: 150px;
                        min-width: 150px;
                    }

                    &.title {
                        flex-grow: 1;
                        padding-left: 10px;
                        color: #375B98;
                        text-align: left;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &.operation {
                        visibility: hidden;
                        width: 86px;
                        min-width: 86px;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;

                        .btn {
                            display: inline-block;
                            width: 24px;
                            height: 24px;
                            background-repeat: no-repeat;
                            cursor: pointer;
                        }

                        .delete-btn {
                            background-image: url('../../../../../../../assets/images/delete_event.png');
                        }

                        .edit-btn {
                            background-image: url('../../../../../../../assets/images/edit_event.png');
                        }
                    }
                }

                &:nth-child(odd) {
                    background-color: #EFF5FD;
                }

                &:last-child {
                    border-color: rgba(0, 0, 0, 0.08);
                }

                &:hover {
                    .operation {
                        visibility: visible;
                    }
                }
            }

            
        }
        .ant-empty{
            margin-top: 200px;
        }

        .pagination {
            text-align: center;           

            .ant-pagination-item-active {
                background-color: #357CF7;

                &>a {
                    color: #fff;
                    font-weight: 400;
                }
            }
        }
    }
}