:global {
    :local(.capsuleTable) {
        margin: 24px;
        padding: 24px;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        flex-grow: 1;

        .ant-table-wrapper {
            width: 100%;

            tr th {
                color: rgba(51, 51, 51, .85);
            }

            tr td {
                color: #666
            }

            tr th:not(:last-child),
            tr td:not(:last-child) {
                border-right: none !important;
            }

            .ant-table-thead {
                background: #F5F5F5;
            }

           td.operation{
                color: #1890FF;
                span{
                    cursor: pointer;
                    margin: 0 12px;
                }
            }
        }
    }
}