:global {
    :local(.wrapper) {
        height: 100%;
        background-color: #FFFFFF;
        border-radius: 2px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        position: relative;

        >.top {
            padding-top: 24px;
            padding-bottom: 24px;
            display: flex;
            align-items: center;

            >.item {
                display: flex;
                align-items: center;

                >.title {
                    height: 14px;
                    font-size: 14px;
                    font-family: SourceHanSansCN-Normal;
                    line-height: 14px;
                    color: #999999;
                }

                >.value {
                    margin-left: 11px;
                }

                &:nth-child(n+2) {
                    margin-left: 48px;
                }
            }

            >.right_wrapper {
                margin-left: auto;
            }
        }

        >.bottom {
            // height: 0;
            // flex-grow: 1;
            padding-right: 2px;
            overflow-y: hidden;

            >.scroll_wrapper {
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;

                >.content {
                    // padding-bottom: 12px;

                    >table {
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 0;

                        >thead {

                            >tr {
                                height: 48px;

                                >th {
                                    padding: 0;
                                    text-align: center;
                                    border-top: 1px solid #E6E6E6;
                                    background-color: #F5F5F5;
                                    font-size: 14px;
                                    font-family: SourceHanSansCN-Medium;
                                    line-height: 14px;
                                    color: rgba($color: #333333, $alpha: 0.85);
                                    position: sticky;
                                    top: 0;
                                    z-index: 1; // hardcode,echart会悬浮

                                    &:first-child {
                                        border-left: 1px solid #E6E6E6;
                                        border-top-left-radius: 2px;
                                    }

                                    &:last-child {
                                        border-right: 1px solid #E6E6E6;
                                        border-top-right-radius: 2px;
                                    }

                                    &.name {
                                        width: 200px;
                                    }

                                    &.action {
                                        width: 120px;
                                    }
                                }
                            }
                        }

                        >tbody {
                            >tr {
                                height: 72px;

                                >td {
                                    padding: 0;
                                    font-size: 14px;
                                    font-family: PingFangSC-Medium;
                                    line-height: 14px;
                                    color: #666666;
                                    text-align: center;

                                    &:first-child {
                                        border-left: 1px solid #E6E6E6;
                                    }

                                    &:last-child {
                                        border-right: 1px solid #E6E6E6;
                                    }

                                    &.name {
                                        text-align: left;
                                        padding-left: 16px;
                                        line-height: 16px;
                                    }

                                    &.pred_value {
                                        color: #1890FF;
                                        font-family: Source Han Sans CN;
                                    }

                                    &.result {
                                        font-family: Source Han Sans CN;

                                        &.normal {
                                            color: #3AA372;
                                        }

                                        &.alarm {
                                            color: #FB6060;
                                        }
                                    }

                                    &.action {
                                        padding-right: 24px;
                                        padding-left: 24px;
                                        font-size: 14px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 500;
                                        line-height: 14px;
                                        color: #1890FF;

                                        >.action_wrapper {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;

                                            >.btn {
                                                user-select: none;
                                                cursor: pointer;
                                                height: 14px;

                                                &:hover {
                                                    opacity: 0.8;
                                                }
                                            }
                                        }
                                    }
                                }

                                &:nth-child(even) {
                                    >td {
                                        border-top: 1px solid #E6E6E6;
                                        border-bottom: 1px solid #E6E6E6;
                                        background-color: #FAFAFA;
                                    }
                                }

                                &:last-child {
                                    >td {
                                        border-bottom: 1px solid #E6E6E6;

                                        &:first-child {
                                            border-bottom-left-radius: 2px;
                                        }

                                        &:last-child {
                                            border-bottom-right-radius: 2px;
                                        }
                                    }
                                }

                                &:hover {
                                    >td {
                                        background-color: #E3F2FF;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        >.footer_wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 24px;
            padding-bottom: 24px;

            >.page_size_selector {
                margin-left: 12px;
            }
        }
    }
}