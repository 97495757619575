.alarm_strategy_content_wrapper {
    height: 100%;
    overflow-y: auto;
    padding: 24px;

    .content_wrapper {
        min-height: 100%;
        background: rgb(25, 31, 39);
        border-radius: 2px;
        padding: 24px;

        .header {
            display: flex;
            align-items: center;
            padding-bottom: 24px;

            .time_link {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
                margin: 0 4px;
            }
        }
    }
}

.alarm_strategy_modal_wrapper {
    .header {
        display: flex;
        align-items: center;

        .time_link {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
            margin: 0 4px;
        }
    }

    .input_item {
        margin: 0 12px;
    }
}