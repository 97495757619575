.main_page_bottom_panels_quality_modal_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 1);
        opacity: 0.7;
    }

    .cus_modal_wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1192px;
        height: 696px;
        // opacity: 0.6;
        border: 1px solid rgba($color: #44F2D9, $alpha: 0.6);
        background-color: rgba(33, 40, 48, 1);
        display: flex;
        flex-direction: column;

        .modal_header {
            height: 44px;
            background: #0D1115;
            border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
            padding: 0 16px 0 16px;
            display: flex;
            align-items: center;
            position: relative;

            .title_icon {
                width: 24px;
            }

            .title {
                height: 18px;
                font-size: 18px;
                // font-weight: 500;
                color: #FFFFFF;
                line-height: 18px;
                margin-left: 5px;
            }

            .time {
                margin-left: 12px;
                height: 16px;
                font-size: 16px;
                // font-weight: 400;
                color: #9FA0BA;
                line-height: 16px;
            }

            .cus_close_icon {
                margin-left: auto;
                margin-right: 4px;
                font-size: 24px;
                color: rgba(89, 97, 104, 1);
            }

            .mid_tab_wrapper {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                padding-bottom: 12px;
                display: flex;

                .tab {
                    width: 56px;
                    height: 16px;
                    font-size: 16px;
                    // font-weight: 400;
                    color: #FFFFFF;
                    line-height: 16px;
                    text-align: center;
                    user-select: none;
                    cursor: pointer;
                }

                .tab_selected {
                    color: #44F3DA;
                    position: relative;

                    &::after {
                        content: '';
                        position: absolute;
                        height: 2px;
                        width: 19px;
                        background-color: rgba(68, 243, 218, 1);
                        bottom: -12px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }

        .modal_body {
            height: 0;
            flex-grow: 1;
            overflow-y: auto;

            .modal_body_content_wrapper {
                padding: 4px 0 24px 6px;
                display: flex;
                flex-wrap: wrap;

                .item_position_wrapper {
                    margin-top: 12px;
                    margin-left: 12px;
                    width: 182px;
                }

                .item_large_position_wrapper {
                    margin-top: 12px;
                    margin-left: 12px;
                    width: 376px;
                }
            }
        }
    }
}