:global {
    :local(.setting-modal) {
        width: 760px !important;

        .ant-modal-body {
            padding-top: 0;
            padding-right: 4px;
        }

        .ant-modal-footer {
            border-top: none !important;

            .ant-btn-primary {
                background-color: #375B98;
                border: none;
            }
        }

        ::-webkit-scrollbar {
            width: 4px;
            background-color: #fff;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #dadada;
            border-radius: 2px;
        }

        ::-webkit-scrollbar-track {
            background-color: #fff;
            ;
        }

        .ant-modal-header {
            border-radius: 2px 2px 0 0;
            background: #E2E4E7;
        }

        .ant-modal-title {
            color: #333;
        }

        .ant-modal-content {
            border-radius: 2px;
            overflow: hidden;
        }

        .ant-picker {
            width: 25%;
        }

        .ant-select {
            width: 175px;
            .ant-select-selector{
                border-color:#c9c9c9 !important;
            }
            .ant-select-selection-item-remove{
                display: none;
            }
        }

        .form {
            max-height: 400px;
            padding-right: 20px;
            overflow-y: scroll;

            p {
                margin: 0;
            }

            .form-top {
                height: 72px;
                background: #EFEFEF;
                border-radius: 4px;
                margin-top: 16px;
                display: flex;
                justify-content: space-evenly;
                padding: 16px 0;

                .top-item {
                    flex: 1;
                    text-align: center;
                    color: #666;
                    &>:first-child {
                        font-weight: bold;
                        color: #333;
                    }
                }
            }

            .class-group {
                margin: 16px 0;
                display: flex;
                justify-content: space-evenly;

                div {
                    flex: 1;
                    text-align: center;
                    overflow: hidden;
                    padding: 0 8px;

                    span {
                        display: block;
                        text-align: center;
                        font-size: 16px;
                        color: #5CA9C8;
                        // background: #6BBBDD;
                        border-radius: 4px;
                        padding: 0 8px;
                        line-height: 32px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        
                    }
                }
            }

            .class-member {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                color: #666;
                &>div {
                    flex: 1;
                    display: inline-flex;
                    flex-direction: column;
                    // align-items: center;

                    &>div {
                        height: 60px;
                        margin-bottom: 24px;
                        line-height: 32px;
                        font-size: 14px;

                    }

                }

                .info {
                    min-width: 175px;
                    padding: 0 8px;

                    .info-setting {
                        padding: 0 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .radio-group {
                            margin-left: 8px;
                            margin-top: 8px;
                            display: flex;
                        }

                        .ant-radio-inner {
                            border-color: #375B98;
                        }

                        .ant-radio-wrapper-checked .ant-radio-inner::after {
                            background-color: #375B98;
                        }
                    }
                }
            }

            .members-setting {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                margin-bottom: 24px;

                &>:first-child {
                    width: 14%;
                }

                .info {
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;

                    .info-setting {
                        width: 30%;
                        .radio-group {
                            margin-top: 10px;
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
