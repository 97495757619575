:global {
    :local(.EventBoard) {
        display: flex;
        flex-direction: column;
        padding: 0 24px 24px;
        height: 100%;
        background-color: #EDEEF1;

        .main-warpper {
            flex: 1;
            display: flex;            
            .left-item{
                width: 403px;
                margin-right: 24px;
            }
            .right-item{
                flex: 1;
            }
        }
    }
}