:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;

        >.left {
            width: 320px;
            padding: 0 0 0 24px;
            display: flex;
            flex-direction: column;

            >.title {
                height: 20px;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 20px;
                color: #3A3A3A;
                margin-bottom: 24px;
                margin-top: 20px;
            }

            >.content {
                height: 0;
                flex-grow: 1;
            }
        }

        >.right {
            width: 0;
            flex-grow: 1;
            // padding: 0 16px;
            display: flex;
            flex-direction: column;
            position: relative;

            >.tab_row {
                display: flex;
                align-items: flex-start;
                margin-bottom: 24px;
                margin-top: 8px;
            }

            >.tab_row_absolute {
                position: absolute;
                top: -5px;
                right: 16px;
                transform: translateY(-100%);
            }

            >.content_wrapper {
                align-self: center;
                height: 0;
                flex-grow: 1;
                width: 100%;
                max-width: 1472px;
            }
        }
    }
}