:global {
    :local(.header_wrapper) {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 48px 0 26px;
        background: #12191F;

        .logo_img {
            width: 36px;
            height: 33px;
        }

        .system_name {
            height: 33px;
            font-size: 24px;
            // font-weight: 400;
            color: #FFFFFF;
            line-height: 33px;
            margin-left: 9px;
        }

        .content_index_wrapper {
            display: flex;
            align-items: center;
            margin-left: 53px;

            .title {
                height: 22px;
                font-size: 16px;
                // font-weight: 400;
                color: #FFFFFF;
                line-height: 22px;
                cursor: pointer;
                user-select: none;

                &:hover {
                    opacity: 0.8;
                }
            }

            .right_arrow {
                height: 9px;
                width: 9px;
                border-width: 1px 1px 0 0;
                border-style: solid;
                border-color: rgba(109, 110, 159, 1) rgba(109, 110, 159, 1) transparent transparent;
                transform: rotate(45deg);
                margin: 0 10px;
            }
        }

        .custom_input_comp {
            margin-left: auto;
            background: #343D47;
            border-radius: 6px;
            color: #FFFFFF;

            >input {
                // hardcode
                color: #FFFFFF;
            }

            .input_icon {
                width: 22px;
                height: 22px;
                cursor: pointer;
            }
        }

        .message_img,
        .ring_img,
        .mine_img {
            width: 32px;
            height: 32px;
        }

        .message_img {
            margin-left: 64px;
        }

        .ring_img {
            margin-left: 32px;
        }

        .divide_line {
            margin: 0 32px;
            width: 1px;
            height: 28px;
            background: #4E4E4E;
        }

        .user_name {
            margin-left: 4px;
            height: 16px;
            font-size: 16px;
            // font-weight: 400;
            color: #3DCDB9;
            line-height: 16px;
        }
    }

    .cus_menu_item_SbZQy {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 38px;
        width: 98px;
        font-size: 14px;
        font-weight: 400;
    }

    .cus_menu_item_second_SbZQy {
        color: #3DCDB9;
    }
}