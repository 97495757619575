:global {
    :local(.factor_detail_modal_wrapper) {

        .divide_line {
            margin-top: 10px;
            border-top: 1px solid black;
        }

        .factor_content_wrapper {
            // margin-top: 24px;
            padding: 0 0 0 24px;
            // border-top: 1px solid black;
            display: flex;

            .left {
                width: 280px;
                margin-top: 24px;
            }

            .right {
                flex-grow: 1;
                // padding-top: 12px;
                padding-left: 12px;

                .cat_wrapper {
                    display: flex;
                    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);

                    .cat_item {
                        padding: 5px 10px;
                        cursor: pointer;
                        position: relative;

                        // &::after {
                        //     content: '';
                        //     position: absolute;
                        //     height: 1px;
                        //     background-color: #000000;
                        //     left: 10px;
                        //     right: 10px;
                        //     bottom: 0;
                        // }

                        &:hover {
                            color: rgba($color: blue, $alpha: 0.3);

                            &::after {
                                background-color: rgba($color: blue, $alpha: 0.3);
                            }
                        }
                    }

                    .cat_item_selected {
                        color: blue;
                        font-weight: bold;

                        &::after {
                            background-color: blue;
                        }
                    }
                }

                .score2_wrapper {
                    margin-top: 24px;

                    .row_wrapper {
                        display: flex;
                        align-items: center;
                        padding: 8px 0;
                    }
                }

                .score1_wrapper {
                    margin-top: 24px;

                    .title {
                        width: 100px;
                        text-align: right;
                        flex-shrink: 0;
                    }

                    .row_wrapper {
                        display: flex;
                        align-items: center;
                        padding: 8px 0;

                        .min_max_block {
                            width: 80px;
                            margin: 0 5px;
                        }
                    }
                }
            }

            .rcmd_right {
                width: 100px;
                position: relative;

                .hint_text {
                    text-align: right;
                    cursor: pointer;
                    // display: inline-block;
                    user-select: none;
                    color: blue;
                }

                .analysis_wrapper {
                    position: absolute;
                    width: 315px;
                    height: 870px;
                    right: -24px;
                    top: 50%;
                    transform: translateX(100%) translateY(-50%);
                    background-color: #F0F0F0;
                    border: 1px solid #BBBBBB;
                }
            }
        }

        .rule_factor_img_display_wrapper {
            border-top: 1px solid rgba($color: grey, $alpha: 0.3);
            padding: 5px 0 10px;

            .top {
                display: flex;
                justify-content: flex-end;

                .reload_wrapper {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;

                    .reload_text {
                        margin-left: 5px;
                    }
                }
            }

            .rule_colored_bar_wrapper {
                padding: 5px 60px 0;
            }
        }
    }
}