:global {
    :local(.ReportPanel) {
        $template-color: #357CF7;
        flex-grow: 1;
        width: 403px;
        height: 100%;
        background-color: #fff;
        display: inline-block;
        padding: 0 0 16px;
        overflow: hidden auto;

        .title {
            margin: 0;
            color: #357CF7;
            font-size: 16px;
            font-weight: 500;
            line-height: 56px;
            text-indent: 28px;
            padding-left: 18px;
            // ../../../../../assets/svg/v3/icon_report.svg
            background: url('../../../../../assets/images/v3/icon_report.png') no-repeat left;
            background-origin: content-box;
            border-bottom: 1px solid #D4D4D4;
        }

        .group-title {
            font-size: 14px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000;
            line-height: 24px;
            margin: 12px 0;
            text-indent: 20px;
        }

        ul.report-records {
            padding: 0;
            list-style: none;

            li {
                padding: 0 20px;
                margin-bottom: 12px;
                .report-card {
                    height: 64px;
                    line-height: 64px;
                    padding: 0 16px;
                    color: #727272;
                    border-radius: 8px;
                    cursor: pointer;
                    position: relative;
                    display: flex;
                    align-items: center;
                    background: #D5D5D5;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 32px;

                    .report-name {
                        flex: 1;
                        color: #2B3249;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .cell-count {
                        display: none;
                        width: 32px;
                        height: 32px;
                        background: #fff;
                        border-radius: 50%;
                        color: #357CF7;
                        text-align: center;
                    }                    
                }
            }           
        }
        .todoList {
            margin-bottom: 12px;
            .report-card{
                background: #375B98 !important;
                
                .report-name{
                    color: #fff !important;
                }
                .cell-count {
                    display: block !important;
                }
            }
        }
    }
}