:global {
    :local(.board_top_wrapper) {
        height: 199px;
        flex-shrink: 0;
        background: linear-gradient(90deg, #355B99 0%, rgba(53, 108, 200, 0.85) 50%, rgba(53, 124, 247, 0.7) 100%);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        padding: 20px 0 20px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .board_row {
            display: flex;
            // justify-content: space-between;
            height: 60px;

            .icon_wrapper {
                align-self: center;
                width: 50px;
                height: 50px;
                background: rgba(255, 255, 255, 0.28);
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;

                >img {
                    display: block;
                }
            }

            .item {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .title {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 1);
                    line-height: 20px;
                    opacity: 0.65;
                }

                .value_wrapper {
                    display: flex;
                    align-items: flex-end;

                    // .value {
                    // flex-grow: 1;
                    // width: 0;
                    // }

                    .unit {
                        width: 0;
                        flex-shrink: 0;
                        height: 12px;
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 1);
                        line-height: 12px;
                        margin-left: 3px;
                        opacity: 0.85;
                    }
                }
            }

            .item_1 {
                width: 120px;
                margin-left: 10px;

                .value_wrapper {
                    .value {
                        height: 30px;
                        font-size: 30px;
                        font-weight: 500;
                        color: rgba(255, 255, 255, 1);
                        line-height: 30px;
                    }

                    .unit {
                        width: 40px;
                    }
                }
            }

            .item_2,
            .item_3 {
                width: 80px;

                .value_wrapper {
                    // .trend_icon_wrapper {
                    //     align-self: center;
                    //     width: 18px;
                    //     height: 18px;
                    //     display: flex;
                    //     align-items: center;

                    //     >img {
                    //         display: block;
                    //         width: 12px;
                    //         height: 12px;
                    //     }
                    // }
                    .arrow {
                       margin-right: 5px;
                    }

                    // .arrow_down {
                    //     border-color: orange transparent transparent transparent;
                    //     border-width: 16px 8px 0 8px;
                    // }

                    // .arrow_up {
                    //     border-color: transparent transparent orange transparent;
                    //     border-width: 0 8px 16px 8px;
                    // }

                    .value {
                        height: 20px;
                        font-size: 20px;
                        color: rgba(255, 255, 255, 1);
                        line-height: 20px;
                    }

                    .unit {
                        width: 20px;
                    }
                }
            }

            .item_2 {
                margin-left: 13px;
            }

            .item_3 {
                margin-left: 7px;
            }
        }
    }
}