.personal_info_content_wrapper {
    // height: calc(100vh - 135px);
    height: 100%;
    // background: rgba(240, 242, 245, 1);
    overflow-y: auto;
    padding: 24px;

    .block_wrapper {
        height: 100%;
        // background: rgba(255, 255, 255, 1);
        background-color: #212830;
        border-radius: 2px;
        display: flex;
        flex-direction: column;

        .content {
            flex-grow: 1;
            display: flex;

            .left {
                width: 160px;
                // background: rgba(0, 0, 0, 0.02);
            }

            .right {
                flex-grow: 1;
                padding: 24px 40px;

                .title {
                    font-size: 24px;
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.85);
                    line-height: 32px;
                    padding-bottom: 40px;
                }

                .row {
                    display: flex;
                    align-items: center;
                    padding-bottom: 24px;

                    .row_title {
                        width: 108px;
                        text-align: left;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 0.65);
                        line-height: 20px;
                    }

                    .row_title_action {
                        user-select: none;
                    }

                    .arrow_down {
                        cursor: pointer;
                        transform: rotate(90deg);
                    }

                    .row_right {
                        position: relative;

                        .hint_wrapper {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            transform: translateY(100%);
                            color: #FF3D00;
                            display: flex;
                            align-items: center;
                            height: 16px;
                            padding-left: 5px;

                            >span:last-child {
                                font-size: 12px;
                                line-height: 12px;
                                margin-left: 5px;
                            }
                        }
                    }
                }

                .change_pwd_wrapper {
                    background-color: #15191E;
                    display: inline-block;
                    padding: 12px 24px 0;
                }
            }
        }
    }
}