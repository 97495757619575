.setting-modal{
  :global(.ant-modal-body){
    padding-top: 0;
    padding-right: 4px;
  }  
  :global(.ant-modal-footer){
    border-top: none !important;
    :global(.ant-btn-primary){
      background-color: #375B98;
      border: none;
    }
  }
  :global(::-webkit-scrollbar){
    width: 4px;
    background-color: #fff;
  }
  :global(::-webkit-scrollbar-thumb){
    background-color:  #dadada;
    border-radius: 4px;
  } 
  :global(::-webkit-scrollbar-track){
    background-color: #fff;;
  }
  :global(.ant-modal-header){
    border-radius: 2px 2px 0 0;
    background: #E2E4E7;
  }
  :global(.ant-modal-title){
    color: #333;
  }
  :global(.ant-modal-content){
    border-radius: 2px;
  }
  .form{
    max-height: 400px;
    padding-right: 20px;
    overflow-y: scroll;
    .setting-info{
      display: flex;
      justify-content: space-between;
      padding-top: 16px;
      align-items: center;
      .class-setting{
        width: 90%;
        max-height: 32px;
      }
      .class-info-delete{
        width: 16px;
        height: 16px;
      }
      .user-setting{
        margin-bottom: 5px;
        &>:first-child{
          margin-right: 20px;
        }
        .user-selected{
          min-width: 90px !important;
        }
      }
      
    }
  } 

}
