.factor_item_wrapper {
    // height: 162px;
    // padding: 21px 20px 0 16px;

    .title_bar_wrapper {
        padding-right: 6px;
        display: flex;
        align-items: center;

        .class_wrapper {
            height: 20px;
            border: 1px solid #FFD94E;
            padding: 0 4px;
            font-size: 12px;
            // font-weight: 400;
            color: #FFD94E;
            // line-height: 18px;
            display: flex;
            align-items: center;
        }

        .tag_wrapper {
            margin-left: 6px;
            padding: 0 5px;
            height: 14px;
            background: #44F3DA;
            border-radius: 9px;
            font-size: 12px;
            color: #151922;
            display: flex;
            align-items: center;
        }

        .title_text {
            margin-left: 6px;
            height: 14px;
            font-size: 14px;
            // font-weight: 500;
            color: #FFFFFF;
            line-height: 14px;
        }

        .time {
            margin-left: auto;
            height: 12px;
            font-size: 12px;
            // font-weight: 400;
            color: #9FA0BA;
            line-height: 12px;
        }
    }

    .block_and_chart {
        margin-top: 10px;
        display: flex;
        align-items: flex-start;

        .block {
            margin-top: 2px;
            width: 0;
            flex-grow: 1;
            height: 84px;
            background: #191F27;
            border-radius: 4px;
            padding: 11px 8px 15px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .row_wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding-left: 31px;

                .icon {
                    position: absolute;
                    left: 16px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    // width: 14px;
                    // height: 14px;
                }

                .text {
                    height: 14px;
                    font-size: 12px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 14px;
                }

                .value {
                    height: 20px;
                    font-size: 16px;
                    // font-weight: 500;
                    color: #FFFFFF;
                    line-height: 20px;
                }
            }
        }

        .chart_wrapper {
            width: 210px;
            margin-left: 12px;

            .chart_header_bar {
                display: flex;
                align-items: center;

                .ave_color_icon {
                    width: 12px;
                    height: 4px;
                    // background: #44F3DA;
                    border-radius: 2px;
                }

                .ave_color_text {
                    margin-left: 6px;
                    height: 12px;
                    font-size: 11px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }

                .standard_color_icon {
                    margin-left: 9px;
                    width: 17px;
                    height: 0;
                    border-style: dashed;
                    border-width: 1px 0 0 0;
                    border-color: transparent;
                }

                .standard_color_text {
                    margin-left: 3px;
                    height: 12px;
                    font-size: 11px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }

                .value_text {
                    margin-left: auto;
                    height: 12px;
                    font-size: 11px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }

                .value_number {
                    margin-right: 6px;
                    margin-left: 5px;
                    height: 12px;
                    font-size: 11px;
                    // font-weight: 400;
                    color: #FF6170;
                    line-height: 12px;
                }
            }

            .chart_self_wrapper {
                margin-top: 7px;
                height: 80px;
            }
        }
    }
}