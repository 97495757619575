.DisplayCardWrapper {
    box-shadow: 2px 2px 6px rgba($color: #000000, $alpha: 0.06);
    border: 0.5px solid #E3E3E3;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    >div {
        .title {
            background-color: #F9F9F9;
            border-radius: 4px 4px 0 0;
            border: 0.5px solid #E3E3E3;
            line-height: 27px;
            font-size: 20px;
            color: #555555;
            padding: 10px;
            box-sizing: border-box;
            overflow: hidden;
            word-break: keep-all;
            white-space: nowrap;
            margin-bottom: 15px;
        }
        .item {
            .value {
                flex-grow: 1;
                border-radius: 2px;
                text-align: right;
                padding: 1px 8px;
                line-height: 24px;
                font-size: 20px;
                color: #555555;
                overflow: hidden;
            }
            .unit {
                padding-left: 6px;
                color: #646262;
                font-size: 20px;
                line-height: 26px;
                flex-shrink: 0;
            }
        }
    }
    .type0 {
        padding-bottom: 15px;
        .subtitle {
            font-size: 16px;
            color: #555555;
            padding: 1px 10px;
            text-align: center;
            overflow: hidden;
            word-break: keep-all;
            white-space: nowrap;
        }
        .item {
            display: flex;
            padding: 5px 10px;
            align-items: flex-end;
            .name {
                width: 76px;
                flex-shrink: 0;
                overflow: hidden;
                color: #646262;
                font-size: 20px;
                line-height: 26px;
                padding-right: 7px;
                overflow: hidden;
                word-break: keep-all;
                white-space: nowrap; // text-overflow: ellipsis;
            }
        }
    }
    .type1 {
        padding-bottom: 10px;
        .item {
            .nameAndBtn {
                padding: 1px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .name {
                    font-size: 20px;
                    line-height: 28px;
                    color: #646262;
                }
                .btn {
                    width: 56px;
                    line-height: 30px;
                    background-color: #B5B5B5;
                    color: #FFFFFF;
                    font-size: 18px;
                    text-align: center;
                    border-radius: 15px;
                    box-shadow: 3px 3px 3px rgba($color: #000000, $alpha: 0.16);
                    cursor: pointer;
                }
            }
            .itemData {
                padding: 5px 25px 5px 88px;
                display: flex;
                align-items: flex-end;
            }
        }
    }
}