:global {
    :local(.wrapper) {
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        background: #FFFFFF;
        padding: 16px 16px 0 16px;
        height: 100%;
        position: relative;

        .title_row {
            .title {
                height: 16px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 16px;
                color: #666;
            }
        }

        .content_row {
            display: flex;

            .left,
            .right {
                width: 0;
                flex-grow: 1;
            }

            .left {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 16px;

                .chart_wrapper {
                    width: 100%;
                    position: relative;

                    &::before {
                        content: '';
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                    }

                    .chart_content_wrapper {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .radar_block {
                            width: 50%;
                            height: 50%;
                            position: relative;

                            .item_wrapper {
                                padding: 3px 6px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                user-select: none;
                                cursor: pointer;

                                .value {
                                    height: 12px;
                                    font-size: 12px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    line-height: 12px;
                                    color: #333;
                                }

                                .name {
                                    margin-top: 4px;
                                    height: 12px;
                                    font-size: 12px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    line-height: 12px;
                                    color: #333;

                                    &.selected_name {
                                        height: 16px;
                                        line-height: 16px;
                                        padding: 0 8px;
                                        background-color: #375B98;
                                        color: #FFFFFF;
                                        border-radius: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right {
                padding-top: 16px;

                .chart_row {
                    height: 72px;
                }

                .table_row {
                    table {
                        width: 100%;

                        thead {
                            tr {
                                th {
                                    height: 32px;
                                    text-align: center;

                                    &.th_topic {
                                        width: 90px;

                                        .title_wrapper {
                                            display: flex;
                                            width: 100%;
                                            align-items: center;
                                            // justify-content: space-between;

                                            .name {
                                                margin-left: 6px;
                                                height: 16px;
                                                font-size: 16px;
                                                font-family: Source Han Sans CN;
                                                font-weight: bold;
                                                line-height: 16px;
                                                color: #666;
                                            }
                                        }

                                        >.clickable {
                                            cursor: pointer;
                                            user-select: none;
                                        }
                                    }

                                    .col_title_wrapper {
                                        max-width: 72px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: flex-end;
                                        font-size: 14px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 400;
                                        line-height: 14px;
                                        color: #666;

                                        .name {
                                            height: 14px;
                                        }

                                        .unit {
                                            margin-left: 4px;
                                            height: 14px;
                                        }
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                &.tr_content {
                                    td {
                                        height: 30px;
                                        font-size: 14px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 400;
                                        line-height: 14px;
                                        color: #666;

                                        &:first-child {
                                            text-align: center;
                                        }

                                        &:nth-child(n+2) {
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .list_row {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 8px;
                    // margin-bottom: 10px;

                    .content_wrapper {
                        font-size: 14px;
                        line-height: 14px;

                        .item_wrapper {
                            color: #666;
                            height: 14px;
                            margin-bottom: 8px;
                        }

                        .more_btn {
                            display: inline-block;
                            color: #357CF7;
                            height: 14px;
                            user-select: none;
                            cursor: pointer;
                        }
                    }
                }
            }

        }
    }
}