:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.content {
            height: 0;
            flex-grow: 1;

            >.inner_content_wrapper {
                height: 100%;
                display: flex;

                >.left {
                    width: 320px;
                    padding: 0 0 0 24px;
                    display: flex;
                    flex-direction: column;

                    >.content {
                        height: 0;
                        flex-grow: 1;
                    }
                }

                >.right {
                    width: 0;
                    flex-grow: 1;
                    // padding: 0 12px;
                    display: flex;
                    flex-direction: column;

                    >.content_wrapper {
                        align-self: center;
                        height: 0;
                        flex-grow: 1;
                        width: 100%;
                        max-width: 1472px;
                    }
                }
            }
        }
    }
}