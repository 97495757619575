.menu {
    position: absolute;
    top: 187px;
    left: 0px;
    width: 57px;
    z-index: 10;

    img {
        display: inline-block;
        margin-left: -9px;
        cursor: pointer; 
    }

    ul {
        padding-left: 0px;
        -webkit-user-select: none;
        user-select: none;

        li {
            list-style: none;
            width: 48px;
            min-height: 48px;
            background: #FFFFFF;
            font-size: 14px;
            font-family: 'Source Han Sans CN';
            font-weight: 400;
            line-height: 18px;
            color: #3A3A3A;
            opacity: 1;
            margin-bottom: 16px;
            text-align: center;
            line-height: 18px;
            padding: 8px 10px;
            box-sizing: border-box;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;

            &.active-menu {
                background-color: #355B99;
                color: #fff;
            }
        }
    }
}
