.alarm_page_content_wrapper {
    height: 100%;
    // background: rgba(240, 242, 245, 1);
    overflow-y: auto;
    padding: 24px;

    .content_wrapper {
        min-height: 100%;
        background: #191f27;
        border-radius: 2px;
        padding: 24px;

        .header {
            display: flex;
            align-items: center;
            padding-bottom: 24px;

            .alarm_volume_wrapper {
                margin-left: auto;
                display: flex;
                align-items: center;
                cursor: pointer;
                user-select: none;

                .icon {
                    width: 24px;
                    height: 24px;
                }

                .volume_text_hint {
                    height: 18px;
                    color: rgba(101, 31, 255, 1);
                    font-size: 12px;
                    line-height: 18px;
                    margin-left: 11px;
                }
            }
        }
    }

    .not_end_title {
        display: flex;
        align-items: center;

        &::before {
            content: '进行中';
            display: block;
            width: 50px;
            height: 22px;
            background: linear-gradient(270deg, rgba(235, 47, 150, 1) 0%, rgba(246, 38, 76, 1) 100%);
            border-radius: 4px;
            font-size: 12px;
            color: rgba(255, 255, 255, 1);
            // line-height: 22px;
            // text-align: center;
            margin-right: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .not_end_alarm {
        // background: rgba(255, 241, 240, 1);

        // &:hover {
        //     background: rgba(255, 241, 240, 1);
        // }
        // >td {
        //     border-width: 1px 0;
        //     border-color: rgba(255, 163, 158, 1);
        //     border-style: solid;

        //     &:first-child {
        //         border-left-width: 1px;
        //     }

        //     &:last-child {
        //         border-right-width: 1px;
        //     }
        // }
    }
}