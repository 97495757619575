:global {
    :local(.wrapper) {
        >.top {
            >table {
                width: 100%;

                >tbody {
                    >tr {
                        >td {
                            padding: 0;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            line-height: 14px;

                            &.title {
                                padding: 16px 0;
                                width: 72px;
                                font-weight: 400;
                                color: #666666;
                            }

                            &.value {
                                font-weight: 500;
                                color: #333333;
                            }
                        }
                    }
                }
            }
        }

        >.mid {
            height: 240px;

            >.chart {
                height: 100%;
            }
        }

        >.bottom {
            >table {
                width: 100%;
                table-layout: fixed;

                >thead {
                    >tr {
                        >th {
                            padding: 0;
                        }
                    }
                }

                >tbody {
                    >tr {
                        >td {
                            padding: 12px 0;
                            text-align: center;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            line-height: 14px;
                            font-weight: 500;
                            color: #333333;

                            &.title {
                                text-align: left;
                                color: #666666;
                                font-weight: 400;
                            }

                            &.comp {
                                >.content {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    >.arrow {
                                        width: 0;
                                        height: 0;
                                        margin-right: 4px;
                                        border-style: solid;

                                        &.up {
                                            border-width: 0 4px 8px 4px;
                                            border-color: transparent transparent #666666 transparent;
                                        }

                                        &.down {
                                            border-width: 8px 4px 0 4px;
                                            border-color: #666666 transparent transparent transparent;
                                        }
                                    }
                                }

                                &.green {
                                    color: #3AA372;

                                    >.content {
                                        >.arrow {

                                            &.up {
                                                border-color: transparent transparent #3AA372 transparent;
                                            }

                                            &.down {
                                                border-color: #3AA372 transparent transparent transparent;
                                            }
                                        }
                                    }
                                }

                                &.red {
                                    color: #FB6060;

                                    >.content {
                                        >.arrow {

                                            &.up {
                                                border-color: transparent transparent #FB6060 transparent;
                                            }

                                            &.down {
                                                border-color: #FB6060 transparent transparent transparent;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}