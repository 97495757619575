:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            display: flex;
            flex-direction: column;

            >.top {
                padding: 0 20px 0 20px;
                height: 34px;
                display: flex;
                flex-direction: column;

                >.items_wrapper {
                    margin-top: auto;
                    display: flex;
                    align-items: center;

                    >.item {
                        margin-right: auto;
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        line-height: 14px;
                        display: flex;
                        align-items: center;

                        >.title {
                            height: 14px;
                            color: #999999;
                        }

                        >.value {
                            margin-left: 2px;
                            height: 14px;
                            color: #333333;
                        }
                    }

                }
            }

            >.mid {
                height: 0;
                flex-grow: 1;
                position: relative;
                overflow: hidden;

                >.resize {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }

                >.chart {
                    height: 100%;
                    position: relative;
                }
            }

            >.bottom {
                >.bar_wrapper {
                    >.top {
                        position: relative;

                        >.title_wrapper {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            width: 0;
                            transform: translate(-100%, -50%);
                            overflow: hidden;
                            display: flex;
                            justify-content: flex-end;

                            >.text {
                                height: 14px;
                                font-size: 14px;
                                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                font-weight: 400;
                                color: #666666;
                                line-height: 14px;
                                margin-right: 13px;
                            }
                        }

                        >.bar {
                            height: 16px;
                            position: relative;
                            overflow: hidden;

                            >.bar_item {
                                height: 100%;
                                position: absolute;
                                left: 100%;
                                width: 0;
                            }
                        }
                    }

                    >.bottom {
                        height: 42px;
                        padding-bottom: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        >.item {
                            display: flex;
                            align-items: center;
                            font-size: 12px;
                            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                            font-weight: 400;
                            color: #666666;
                            line-height: 12px;

                            >.color_block {
                                width: 25px;
                                height: 8px;
                            }

                            >.title,
                            >.value {
                                height: 12px;
                            }

                            >.title {
                                margin-left: 8px;
                            }

                            >.value {
                                margin-left: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}