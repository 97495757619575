:global {
    :local(.wrapper) {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        >.tabs_wrapper {
            margin-right: 8px;
            height: 32px;
            border: 2px solid #3A3A3A;
            border-radius: 16px;
            display: flex;

            >.single_tab_wrapper {
                width: 142px;
                padding: 0 16px; // 15 to 16, 设计稿上15和7字省略冲突, 暂时调整为16
                display: flex;
                align-items: center;
                user-select: none;
                cursor: pointer;

                &:hover {
                    // add by ql
                    opacity: 0.8;
                }

                >.text {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    height: 14px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 14px;
                    color: #3A3A3A;
                }
            }

            >.line {
                width: 2px;
                background-color: #3A3A3A;
            }
        }

        >.add_btn_wrapper {
            width: 88px;
            height: 32px;
            background: #357CF7;
            border-radius: 16px;
            padding-left: 16px;
            display: flex;
            align-items: center;
            user-select: none;
            cursor: pointer;

            &:hover {
                // add by ql
                opacity: 0.8;
            }

            >.icon {
                width: 16px;
                height: 16px;
            }

            >.text {
                margin-left: 8px;
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 14px;
                color: #FFFFFF;
            }
        }
    }
}