:global {
    :local(.new_main_page_record_modal_wrapper) {
        position: fixed;
        background: rgba(0, 0, 0, 0.65);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        padding: 8px 16px 16px;
        min-width: 200px;
        transform: translateX(-50%) translateY(20px);
        cursor: move;
        user-select: none;
        z-index: 101;

        .title_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 22px;

            .title {
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }

            >img {
                cursor: pointer;
                margin-left: 10px;
            }
        }

        table {
            border-collapse: separate;
            border-spacing: 0 10px;
        }

        .zone_row {
            // display: flex;
            // align-items: center;
            // margin-top: 10px;

            .point {
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            .title {
                margin-left: 7px;
                height: 20px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 20px;
                width: 34px;
            }

            .zone_value {
                margin-left: 5px;
                // width: 120px;
                // height: 20px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 20px;
            }

            .vlaue_block {
                // width: 68px;
                height: 22px;
                border-radius: 2px;
                color: #ffffff;
                display: flex;
                align-items: center;
                padding: 0 7px;
                margin-left: 12px;

                .value {
                    margin-left: 5px;
                    font-size: 16px;
                    line-height: 16px;
                    height: 16px;
                }
            }
        }
    }
}