.main_page_bottom_panels_quality_comp_wrapper {
    height: 100%;
    overflow-y: auto;

    .comp_content_wrapper {
        padding: 7px 15px 16px 8px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(182px, 1fr));

        .item_position_wrapper {
            margin-left: 8px;
            margin-top: 8px;
        }
    }
}