:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.tab_wrapper {
            padding-top: 0px;
            padding-left: 24px;
            display: flex;
        }

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
        }
    }
}