:global {
    :local(.race_list_wrapper) {
        height: 100%;
        padding: 24px 56px 0 72px;
        position: relative;

        >.inner_wrapper {
            height: 100%;
            display: flex;
            flex-direction: column;
            background: rgba(255, 255, 255, 1);
            border-radius: 16px;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);

            .race_list_header_wrapper {
                height: 70px;
                display: flex;
                align-items: center;
                padding: 0 24px;
                // justify-content: flex-end;

                .btn_setting {
                    margin-left: 15px;
                }

            }

            .line_bottom {
                height: 1px;
                background: rgba(233, 233, 233, 1);
            }

            .race_list_content_wrapper {
                height: 0;
                flex-grow: 1;

                .content_wrapper {
                    height: 100%;
                    padding: 32px;
                    padding-bottom: 84px;

                    .ongoing_record {

                        &:hover {
                            >td:not(:last-child) {
                                background-color: rgb(238, 53, 53);
                            }
                        }

                        >td:not(:last-child) {
                            background-color: rgb(238, 53, 53);
                            color: #ffffff;
                        }
                    }

                    .ant-table-wrapper {
                        height: 100%;

                        .ant-spin-nested-loading {
                            height: 100%;

                            .ant-spin-container {
                                height: 100%;

                                .ant-table.ant-table-fixed-header {
                                    height: 100%;

                                    .ant-table-container {
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;

                                        .ant-table-header {
                                            flex-shrink: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        >.secondary_wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

    }

    :local(.race_list_header_wrapper_modal) {
        .wrapper_row {
            display: flex;
            align-items: center;

            >div:first-child {
                font-size: 14px;
                line-height: 21px;
                height: 21px;
                color: #101010;
                width: 190px;
            }

            &:nth-child(n+2) {
                margin-top: 10px;
            }
        }

        .time_range_wrapper {
            margin-top: 10px;

            .row {
                height: 40px;
                display: flex;
                align-items: center;

                .cus_checkbox_wrapper {
                    width: 190px;
                    padding-left: 20px;
                    display: flex;
                    align-items: center;

                    .title {
                        width: 0;
                        flex-grow: 1;
                        margin-left: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .row_first {
                background-color: rgba($color: grey, $alpha: 0.2);
            }
        }
    }
}