.productionTotal {
  width:440px;
  height:304px;
  background-color: #13191F;
  border-radius: 16px;
  //padding: 24px;
  //box-sizing: border-box;
  &-title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #A6B2C2;
    padding: 16px 0px 13px 16px;
    box-sizing: border-box;
  }
  &-list {
    list-style: none;
    padding: 0px 16px 16px 16px;
    color: #FFFFFF;
    opacity: 0.8;
    font-size: 14px;
    font-family: Microsoft YaHei;
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .thead {
        color: #A6B2C2;
        opacity: 0.8;
      }
      .name {
        width: 90px;
        .energy {
          color:#FEBC05;
          font-size: 12px
        }
        .type {
          color: #8DA1AF;
          opacity: 0.8;
          font-size:12px;
          margin-left:8px;
        }
      }
    }
  }
}
