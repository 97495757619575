:global {
    :local(.wrapper) {
        height: 100%;
        padding: 24px 56px 0 72px;

        >.content_wrapper {
            height: 100%;
            // overflow: hidden;
            position: relative;

            >.content {
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                left: 0px;
            }
        }
    }
}