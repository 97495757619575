:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.header_wrapper {
            position: relative;
        }

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
            background-color: #EBEFF2;
            padding: 24px;

            >.inner_wrapper {
                height: 100%;
            }
        }
    }
}