.miniCapsuleDetail {
    position: absolute;
    z-index: 1;
    right: 0;
    width: 472px;
    min-height: 200px;
    border-radius: 2px;
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    transform: translate(480px, -10%);
    cursor: default;
    overflow: hidden;

    .header {
        height: 40px;
        background-color: #EDF7F8;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
        padding: 0 16px;
        border-radius: 2px 2px 0 0;

        .title {
            font-size: 16px;
            line-height: 40px;
            font-family: 'Source Han Sans CN';
            font-weight: bold;
            color: #727272;
        }

        .header-right {
            display: inline-flex;
            align-items: center;

            &>div {
                width: 16px;
                height: 16px;
                margin-left: 16px;
                cursor: pointer;
            }

            .edit-icon {
                background: url('../../../assets/svg/edit.svg') no-repeat;
            }

            .enlarge-icon {
                background: url('../../../assets/svg/icon_enlarge.svg') no-repeat;
            }

            .qrcode-icon {
                background: url('../../../assets/svg/qrcode.svg') no-repeat;
            }

            .close-icon {
                margin-left: 48px;
                background: url('../../../assets/svg/icon_close.svg') no-repeat;
            }
        }
    }

    .content {
        padding: 16px;

        .classify-text {
            height: 24px;
            line-height: 24px;
            background: #357CF7;
            border-radius: 4px;
            font-size: 12px;
            font-family: 'Source Han Sans CN';
            font-weight: 400;
            color: #fff;
            padding: 0 8px;
            float: right;
        }

        .capsule-title {
            font-size: 16px;
            font-family: 'Source Han Sans CN';
            font-weight: bold;
            line-height: 27px;
            color: #3A3A3A;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        .capsule-info {
            font-size: 14px;
            font-family: 'Source Han Sans CN';
            font-weight: 400;
            line-height: 24px;
            color: #3A3A3A;
            word-break: break-all;
            white-space: pre-wrap;
        }
    }
}

.QrCodeModal .ant-modal-content {
    pointer-events: none;
}