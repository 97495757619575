:global {
    :local(.wrapper) {
        height: 184px;
        background-color: rgba(0, 0, 0, 0.02);
        display: flex;
        flex-direction: column;

        >.title_wrapper {
            padding-top: 14px;
            padding-left: 12px;
            display: flex;
            align-items: center;

            >.icon {
                width: 3px;
                height: 22px;
            }

            >.text {
                margin-left: 5px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 14px;
                color: #666666;
            }
        }

        >.chart_wrapper {
            height: 0;
            flex-grow: 1;

            >.chart {
                height: 100%;
            }
        }
    }
}