.energyTotal {
  width:432px;
  height:304px;
  background-color: #13191F;
  border-radius: 16px;
  margin-left:24px;
  &-title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    color: #A6B2C2;
    opacity: 1;
    padding: 16px 0px 16px 16px;
    box-sizing: border-box;
  }
  &-list {
    padding: 0px 24px 24px 24px;
    box-sizing: border-box;
    display: flex;
    &-item {
      width: 184px;
      height: 104px;
      background: rgba(13, 17, 21, 0.59);
      border: 1px solid #8DA1AF;
      opacity: 1;
      border-radius: 10px;
      .item-top {
        margin-bottom: 12px;
        padding-left: 16px;
        padding-top: 13px;
        &-name {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #FEBC05;
          opacity: 0.8;
          margin-right: 8px;
        }
        &-type {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #8DA1AF;
          opacity: 0.8;
        }
      }
      .item-center {
        padding-left: 16px;
        margin-bottom: 4px;
        &-week {
          width: 18px;
          height: 18px;
          background: #33C2A4;
          border-radius: 50%;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #13191F;
          opacity: 0.8;
          display: inline-block;
          text-align: center;
          line-height: 18px;
          margin-right: 4px;
          vertical-align: middle;
        }
        &-number {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          opacity: 1;
          display: inline-block;
          vertical-align: middle;
        }
      }
      .item-bottom {
        padding-left: 30px;
        box-sizing: border-box;
        &-number {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 16px;
          color: #FFFFFF;
          opacity: 1;
        }
        &-time {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 16px;
          color: #8DA1AF;
          opacity: 0.8;
        }
      }
    }
  }
}
