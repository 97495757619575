:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            padding: 0 16px;
            display: flex;
            flex-direction: column;

            >.header {
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;

                >.content {
                    display: flex;
                    align-items: center;

                    >.title {
                        height: 12px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 12px;
                    }

                    >.bars_wrapper {
                        margin-left: 9px;
                        width: 204px;
                        display: flex;
                        align-items: center;
                        height: 6px;

                        >.bar {
                            width: 0;
                            flex-grow: 0;
                            height: 100%;
                            background-color: transparent;
                            border-radius: 3px;
                            position: relative;

                            >.text {
                                position: absolute;
                                bottom: -8px;
                                left: 50%;
                                transform: translate(-50%, 100%);
                                height: 12px;
                                font-size: 12px;
                                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                font-weight: 400;
                                line-height: 12px;
                                white-space: nowrap;
                            }

                            &.bar_3 {
                                flex-grow: 112;
                                background-color: #1890FF;
                                color: #1890FF;
                            }

                            &.bar_2 {
                                flex-grow: 60;
                                background-color: #FFBE0D;
                                color: #FFBE0D;
                            }

                            &.bar_1 {
                                flex-grow: 32;
                                background-color: #FB6060;
                                color: #FB6060;
                            }
                        }
                    }

                    >.value {
                        margin-left: 10px;
                        height: 18px;
                        font-size: 18px;
                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        color: #FB6060;
                        line-height: 18px;
                    }
                }
            }

            >.content {
                margin-top: 14px;
                height: 0;
                flex-grow: 1;
                display: flex;

                >.left {
                    width: 0;
                    flex-grow: 178;
                    display: flex;
                    flex-direction: column;

                    >.title {
                        margin-top: 2px;
                        height: 12px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 12px;
                        text-align: center;
                    }

                    >.chart_wrapper {
                        height: 0;
                        flex-grow: 1;
                        margin-top: 6px;
                        position: relative;
                        overflow: hidden;

                        >.resize {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }

                        >.chart {
                            position: relative;
                            height: 100%;
                        }
                    }

                    >.tool_tip {
                        margin-top: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        >.item {
                            display: flex;
                            align-items: center;

                            >.icon {
                                width: 22px;
                                height: 8px;
                                border-radius: 4px;
                                background-color: transparent;
                            }

                            >.text {
                                margin-left: 5px;
                                height: 12px;
                                font-size: 12px;
                                font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                                font-weight: 400;
                                color: #666666;
                                line-height: 12px;
                            }

                            &:nth-child(n+2) {
                                margin-left: 18px;
                            }
                        }
                    }
                }

                >.line_wrapper {
                    padding-bottom: 19px;

                    >.line {
                        height: 100%;
                        border-style: dashed;
                        border-color: #F0F0F0;
                        border-width: 0 0 0 1px;
                    }
                }

                >.right {
                    width: 0;
                    flex-grow: 242;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    >.title {
                        margin-top: 2px;
                        height: 12px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 12px;
                        text-align: center;
                    }

                    >.rank_wrapper {
                        margin-top: 15px;
                        width: 100%;

                        >.item {
                            height: 28px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            padding-left: 8px;
                            padding-right: 12px;

                            >.text {
                                height: 13px;
                                font-size: 13px;
                                font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                                font-weight: 400;
                                color: #333333;
                                line-height: 13px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            >.rate {
                                margin-left: auto;
                                height: 13px;
                                font-size: 13px;
                                font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                                font-weight: 400;
                                color: #666666;
                                line-height: 13px;
                                white-space: nowrap;
                            }

                            &.odd {
                                background-color: #F8F8F8;
                            }
                        }
                    }
                }
            }
        }
    }
}