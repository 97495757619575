.main_page_three_comp_wrapper {
    height: 100%;
    position: relative;

    .three_chart_block {
        height: 100%;
    }

    @keyframes myOpacity {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .chart_info_wrapper {
        position: absolute;
        left: 0;
        top: 0;
        animation: myOpacity 3s;

        .top_block_wrapper {
            height: 63px;
            position: relative;

            .circle_outline_wrapper {
                width: 136px;
                height: 68px;
                overflow: hidden;
                position: absolute;
                top: -12px;
                left: 50%;
                transform: translateX(-50%);

                .circle_wrapper {
                    position: relative;
                    width: 136px;
                    height: 136px;
                    border-radius: 50%;
                    background-color: rgba(63, 69, 95, 1);
                    overflow: hidden;

                    .rotate_block_1 {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 50%;
                        left: 0;
                        overflow: hidden;

                        .colored_block {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            right: 0;
                            height: 100%;
                            // background-color: rgba(68, 243, 218, 1);
                            background-color: transparent;
                            transform-origin: 50% 0;
                            transform: rotate(0deg);
                        }
                    }

                    .inner_hover_circle {
                        position: absolute;
                        top: 7px;
                        right: 7px;
                        bottom: 7px;
                        left: 7px;
                        border-radius: 50%;
                        // background-color: rgba(25, 31, 39, 1);
                        background-color: #000000;
                    }
                }
            }

            .back_circle_wrapper {
                position: absolute;
                height: 100%;
                width: 112px;
                overflow: hidden;
                top: 0;
                left: 50%;
                transform: translateX(-50%);

                .back_circle {
                    height: 112px;
                    width: 112px;
                    border-radius: 50%;
                    // box-shadow: 0px 10px 8px 0px rgba(68, 243, 218, 0.34) inset;
                }
            }

            .value_text {
                position: absolute;
                height: 46px;
                font-size: 36px;
                font-weight: bold;
                color: transparent;
                line-height: 46px;
                left: 50%;
                top: 15px;
                transform: translateX(-50%);
            }
        }

        .icons_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .name {
            margin-top: 4px;
            height: 18px;
            font-size: 13px;
            // font-weight: 400;
            color: #9FA0BA;
            line-height: 18px;
            text-align: center;
        }
    }
}