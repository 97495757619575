:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            display: flex;
            flex-direction: column;

            >.top {
                padding: 24px 0 0 52px;

                >.hint_wrapper {
                    display: flex;
                    align-items: center;

                    >.colored_block {
                        width: 24px;
                        height: 10px;
                        border-radius: 2px;
                    }

                    >.text {
                        margin-left: 6px;
                        height: 12px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 12px;
                    }

                    >.date {
                        margin-left: 44px;
                        height: 12px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 12px;
                    }
                }
            }

            >.bottom {
                height: 0;
                flex-grow: 1;
                display: flex;

                >.left {
                    width: 0;
                    flex-grow: 1;

                    >.chart {
                        height: 100%;
                    }
                }

                >.right {
                    width: 255px;
                    padding-top: 14px;

                    >table {
                        margin-top: -2px;

                        >tbody {
                            >tr {
                                >td {
                                    height: 46px;
                                    font-size: 14px;
                                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                    font-weight: 400;
                                    line-height: 14px;

                                    &.title,
                                    &.dots {
                                        color: #999999;
                                    }

                                    &.title {
                                        >.value_wrapper {
                                            display: flex;
                                            justify-content: space-between;
                                        }
                                    }

                                    &.value {
                                        color: #333333;
                                        padding-left: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}