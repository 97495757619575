.factor_statistics_system_panel_wrapper {
    // height: 185px;
    border: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
    // display: flex;
    // flex-direction: column;

    .item_header_wrapper {
        height: 57px;
        border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
        padding-left: 12px;
        display: flex;
        align-items: center;
        background: #0D1115;

        .item_header_icon {
            width: 24px;
            height: 24px;
        }

        .title {
            // width: 193px;
            padding-left: 5px;
            height: 18px;
            font-size: 18px;
            // font-family: PingFangSC-Medium, PingFang SC;
            // font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
        }
    }

    .item_content_wrapper {
        background-color: rgba(33, 40, 48, 1);
        padding: 16px;

        .left_table_wrapper,
        .right_table_wrapper {
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            table {
                border-collapse: collapse;
                width: 100%;

                thead {
                    tr {
                        th {
                            position: sticky;
                            top: 0;
                            font-size: 16px;
                            color: #9FA0BA;
                            line-height: 16px;
                            padding: 23px 0 16px 0;
                        }
                    }
                }

                td {
                    padding: 0;
                    height: 40px;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 14px;
                }

            }
        }

        .left_table_wrapper {

            table {

                tbody {
                    tr {
                        cursor: pointer;

                        &.red_tr {
                            td {
                                background-color: rgba(255, 97, 112, 0.1);
                            }
                        }

                        &.selected_tr {
                            td {
                                border-top: 1px solid rgba(114, 54, 63, 1);
                                border-bottom: 1px solid rgba(114, 54, 63, 1);
                            }
                        }
                    }
                }
            }
        }

        .right_table_wrapper {

            th,
            td {
                &:first-child {
                    padding-left: 40px;
                }
            }

            tbody {
                tr {
                    &.red_tr {
                        td {
                            background-color: rgba(255, 97, 112, 0.1);
                        }
                    }
                }
            }
        }
    }
}