.quality_item_large_wrapper {
    height: 92px;
    background-color: #191F27;
    border-radius: 2px;
    display: flex;

    .left {
        width: 0;
        flex-grow: 1;
        padding: 9px 0 0 6px;

        .item_title {
            position: relative;

            .text {
                height: 14px;
                font-size: 14px;
                // font-weight: 500;
                color: #BFC0DA;
                line-height: 14px;
                margin-left: 4px;
            }

            .title_icon {
                position: absolute;
                top: 50%;
                left: -6px;
                transform: translateY(-50%);
                width: 2px;
            }
        }

        .rows_wrapper {
            margin-top: 17px;

            .row_wrapper {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                    margin-top: 8px;
                }

                .row_title {
                    height: 12px;
                    font-size: 12px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                    width: 52px;
                }

                .row_value {
                    height: 14px;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 14px;
                    // margin-left: 2px;

                    &.warning_row {
                        color: rgba(255, 97, 112, 1);
                    }
                }
            }
        }
    }

    .right {
        width: 250px;

        .chart_wrapper {
            // width: 210px;
            // margin-left: 12px;
            padding-top: 10px;

            .time {
                height: 12px;
                // font-size: 11px;
                font-size: 12px;
                // font-weight: 400;
                color: #9FA0BA;
                line-height: 12px;
                padding-right: 20px;
                text-align: right;
            }

            .chart_header_bar {
                display: flex;
                align-items: center;
                padding: 0 33px 0 14px;
                margin-top: 4px;

                .ave_color_icon {
                    width: 12px;
                    height: 4px;
                    // background: #44F3DA;
                    border-radius: 2px;
                }

                .ave_color_text {
                    margin-left: 4px;
                    height: 12px;
                    font-size: 11px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }

                .standard_color_icon {
                    margin-left: 9px;
                    width: 17px;
                    height: 0;
                    border-style: dashed;
                    border-width: 1px 0 0 0;
                    border-color: transparent;
                }

                .standard_color_text {
                    margin-left: 3px;
                    height: 12px;
                    font-size: 11px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }

                .value_text {
                    margin-left: auto;
                    height: 12px;
                    font-size: 11px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }

                .value_number {
                    // margin-right: 6px;
                    margin-left: 5px;
                    height: 12px;
                    font-size: 11px;
                    // font-weight: 400;
                    color: #FF6170;
                    line-height: 12px;
                }
            }

            .chart_self_wrapper {
                margin-top: 2px;
                height: 45px;
            }
        }
    }
}