.tabs {
  ul {
    display: flex;
  }
  .tabs-title {
    list-style: none;
    padding: 0px;
    margin-left: 16px;
    cursor: pointer;
    font-size: 27px;
  }
  .isBorder {
    border-bottom: 3px solid #00676B;
  }
}
