.home {
  background: #EDF7F8;
  min-height: 1080px;
  overflow-x: scroll;
  position: relative;
}
.clearFix {
  clear: both;
}
.basics {
  box-sizing: border-box;
  width: calc(100% - 144px);
  margin: 0 auto;
  display: flex;
}
.productionSituation {
  width: 688px;
  height: 416px;
  background: #FFFFFF;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  &-title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 27px;
    color: #727272;
    opacity: 1;
  }
  &-table {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #B3B5B5;
    span {
      text-align: right;
    }
  }
  &-list {
    display: flex;
    justify-content: space-between;
    span {
      text-align: right;
    }
  }
}
.cardList {
  display: flex;
  margin-bottom: 24px;
  padding-left: 0px;
  li {
    list-style: none;
    width: 200px;
    height: 120px;
    background: #FFFFFF;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 1;
    border-radius: 16px;
    margin-right: 16px;
  }
}
.noMarinLeft {
  margin-right: 0px;
}
.deviceSystem {
  width: 688px;
  height: 416px;
  background: #FFFFFF;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 16px;
  box-sizing: border-box;
  &-title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #727272;
    padding-bottom: 12px;
    border-bottom: 1px solid #B3B5B5;
  }
  &-tab {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    box-sizing: border-box;
  }
  &-panel {
    margin-top: 4px;
  }
}

:global {
    td.column-pro.blod {
        font-weight: bold;
        font-size: 20px;
        color: #3A3A3A;
    }
    td.column-pro.media {
      padding: 8px 5px !important;
      border-bottom: none !important;
    }
    td.column-pro.noBorder {
      border-right: none !important;
    }
    td.column-pro.right {
      text-align: right !important;
    }
    td.column-pro.left {
      text-align: left !important;
    }
    th.column-pro.center {
      text-align: center;
    }
    th.column-pro.th-left {
      text-align: left;
    }
    th.column-pro.th-right {
      text-align: right;
    }
}
