:global {
    :local(.SepTimePie) {
        padding: 48px 140px 0 32px;
        display: flex;
        align-items: center;
        height: 0;
        flex-grow: 1;

        .chartNode {
            width: 286px;
            height: 286px;
            margin: 0 120px 0;
            align-self: center;
        }

        .details_table {
            width: 0;
            flex-grow: 1;

            .table_line {
                width: 100%;
                display: flex;
                flex-direction: row;
                line-height: 30px;

                &:first-child,
                &:last-child {
                    border-bottom: 1px solid #B4B4B4;
                }

                &:not(:first-child){
                    margin-top: 24px;
                }

                &:last-child {
                    padding-bottom: 24px;
                }

                .col_1 {
                    width: 28%;
                }

                .col_2,
                .col_3 {
                    width: 0;
                    flex-grow: 1;
                }

                .col_1,
                .col_2,
                .col_3 {
                    display: flex;
                    align-items: center;
                    font-size: 24px;

                    .color_pt {
                        width: 22px;
                        height: 22px;
                        opacity: 1;
                        border-radius: 4px;
                        margin-right: 5px;
                    }
                }

                .pct {
                    display: inline-block;
                    // width: 40px;
                }

                .pct,
                .value {
                    color: #3A3A3A;
                    font-size: 24px;
                }

                .value {
                    margin-left: 14px;
                }
            }

            .title_line {
                padding-bottom: 8px;

                .col_2,
                .col_3 {
                    align-items: flex-end;
                }

                .name {
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 26px;
                    height: 26px;
                    color: #3a3a3a;
                }

                .unit {
                    color: #3A3A3A;
                    font-size: 19px;
                    line-height: 20px;
                    height: 20px;
                    margin-left: 4px;
                    // position: relative;// bottom: 1px;
                }
            }
        }
    }

}