.DeviceDynamicPicWrapper {
    // background-color: #FFFFFF;
    height: 100%;
    width: 100%;
    // display: block; // overflow-y: scroll;
    display: flex;
    justify-content: center;
    position: relative;

    >.resize_wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    >.picRoot {
        box-sizing: border-box;
        // width: 100%;
        position: relative;

        .type2 {
            cursor: pointer;
        }

        .type3 {
            cursor: pointer;
        }
    }
}