:global {
    :local(.wrapper) {
        height: 100%;
    }

    :local(.content) {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 16px;

        >.top {
            padding: 20px 16px 17px 0;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular;
            line-height: 14px;
            display: flex;

            >.title {
                color: #999999;

                &:not(:first-child) {
                    margin-left: 67px;
                }
            }

            >.value {
                color: #333333;
            }
        }

        >.bottom {
            height: 0;
            flex-grow: 1;
            padding-right: 2px;

            >.scroll_wrapper {
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                padding: 0 16px 12px 0;

                >table {
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 0;
                    font-size: 12px;
                    line-height: 12px;

                    >thead {

                        >tr {
                            >th {
                                position: sticky;
                                top: 0;
                                z-index: 1;
                                padding: 14px 0;
                                text-align: center;
                                background-color: #F5F5F5;
                                font-family: SourceHanSansCN-Medium;
                                color: rgba(51, 51, 51, 0.85);
                                font-weight: bold;
                                border-style: solid;
                                border-color: #E6E6E6 transparent transparent transparent;
                                border-width: 1px 0 0 0;

                                &:first-child {
                                    border-color: #E6E6E6 transparent transparent #E6E6E6;
                                    border-width: 1px 0 0 1px;
                                    border-top-left-radius: 2px;
                                }

                                &:last-child {
                                    border-color: #E6E6E6 #E6E6E6 transparent transparent;
                                    border-width: 1px 1px 0 0;
                                    border-top-right-radius: 2px;
                                }
                            }
                        }
                    }

                    >tbody {
                        >tr {
                            >td {
                                padding: 14px 0;
                                text-align: center;
                                font-family: Source Han Sans CN;
                                color: #666666;

                                &.name {
                                    padding-left: 16px;
                                    text-align: left;
                                }

                                &.value {
                                    color: #1890FF;
                                }

                                &.bar {
                                    cursor: pointer;

                                    &:hover {
                                        opacity: 0.8;
                                    }

                                    >.wrapper {
                                        display: inline-block;
                                        width: 140px;
                                    }
                                }

                                &:first-child {
                                    border-style: solid;
                                    border-color: transparent transparent transparent #E6E6E6;
                                    border-width: 0 0 0 1px;
                                }

                                &:last-child {
                                    border-style: solid;
                                    border-color: transparent #E6E6E6 transparent transparent;
                                    border-width: 0 1px 0 0;
                                }
                            }

                            &:nth-child(even) {
                                background-color: #FAFAFA;
                            }

                            &:last-child {
                                >td {
                                    border-style: solid;
                                    border-color: transparent transparent #E6E6E6 transparent;
                                    border-width: 0 0 1px 0;

                                    &:first-child {
                                        border-color: transparent transparent #E6E6E6 #E6E6E6;
                                        border-width: 0 0 1px 1px;
                                        border-bottom-left-radius: 2px;
                                    }

                                    &:last-child {
                                        border-color: transparent #E6E6E6 #E6E6E6 transparent;
                                        border-width: 0 1px 1px 0;
                                        border-bottom-right-radius: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}