:global {
    :local(.TimeLine) {
        position: relative;
        height: 100%;
        padding: 30px 0 66px 30px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .time-line-warpper {
            position: relative;
            width: 2px;
            height: 100%;
            background-color: #EAEAEA;

            .time-point {
                position: absolute;
                left: 13px;
                font-size: 12px;
                color: #33343F;
                white-space: nowrap;
                background: #ECF5FF;
                border-radius: 2px;
                line-height: 24px;
                padding: 0 8px;
                cursor: pointer;
                transform: translateY(50%);
                z-index: 1;

                &::before {
                    position: absolute;
                    left: -18px;
                    top: 6px;
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-radius: 6px;
                    background: #D5D5D5;
                    z-index: 0;
                }
            }

            .shift-time-point {
                color: #357CF7;
                background: no-repeat;

                &::before {
                    left: -20px;
                    top: 4px;
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    background: #357CF7 url('../../../../../../assets/svg/v3/shift_point.svg') no-repeat center;
                    z-index: 0;
                }
            }

            // 游标
            .cursor-range {
                position: absolute;
                width: 262px;
                margin-left: -28px;
                min-height: 1px;
                z-index: 0;
                background-color: rgba(244, 245, 247, 1);

                .active-line {
                    width: 2px;
                    background-color: #357CF7;
                    height: 100%;
                    margin-left: 28px;
                }
            }

            .mark-group {
                position: absolute;
                z-index: 0;
                display: flex;
                align-items: center;

                transform: translateY(-50%);

                .mark-item {
                    position: relative;
                    display: inline-block;
                    border-radius: 50%;
                    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                }
            }
        }

        .current-btn {
            position: absolute;
            left: 16px;
            bottom: 18px;
            width: 89px;
            height: 28px;
            border-radius: 14px;
            line-height: 28px;
            text-indent: 26px;
            color: #357CF7;
            cursor: pointer;
            background: #ECF5FF url('../../../../../../assets/images/v3/icon_location.png') no-repeat 2px
        }
    }
}