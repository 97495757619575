:global {
    :local(.wrapper) {
        height: 100%;
        padding: 0 0 0 24px;
        display: flex;
        flex-direction: column;

        >.content_wrapper {
            height: 0;
            flex-grow: 1;
            padding-bottom: 24px;

            >.inner_wrapper {
                height: 100%;
                display: flex;

                >.left {
                    width: 0;
                    flex-grow: 1;
                }

                >.right {
                    margin-left: 12px;
                    width: 0;
                    overflow-y: auto;

                    >.content {
                        display: flex;
                        flex-direction: column;

                        >.top {
                            height: 308px;
                        }

                        >.mid,
                        >.bottom {
                            margin-top: 12px;
                            display: flex;
                            align-items: flex-start;

                            >.left,
                            >.right {
                                width: 0;
                                flex-grow: 1;
                                height: 308px;
                            }

                            >.right {
                                margin-left: 12px;
                            }
                        }
                    }
                }

            }
        }
    }
}