:global {
    :local(.board_charts_wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        .right_header {
            flex-shrink: 0;
            padding: 16px 24px 0 24px;

            .title {
                height: 24px;
                font-size: 16px;
                color: #357CF7;
                line-height: 24px;
            }

            .info_bar {
                margin-top: 16px;
                display: flex;
                align-items: center;

                .color_icon {
                    width: 15px;
                    height: 15px;
                    border-radius: 2px;

                    &:not(:nth-of-type(1)) {
                        margin-left: 40px;
                    }
                }

                .text {
                    height: 22px;
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(53, 56, 77, 1);
                    line-height: 22px;
                    margin-left: 10px;
                }

                .hour_item {
                    width: 42px;
                    height: 24px;
                    background-color: rgba(250, 251, 253, 1);
                    border-style: solid;
                    border-width: 1px 0 1px 1px;
                    border-color: rgba(213, 213, 213, 1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(32, 34, 36, 1);
                    user-select: none;
                }

                .selected_hour_item {
                    border-width: 0;
                    background-color: #357CF7;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }

        .top_charts_wrapper {
            margin-left: 24px;
        }

        .right_content {
            margin-top: 10px;
            flex-grow: 1;
            height: 0;
            overflow-y: auto;
            overflow-x: hidden;
            padding-left: 24px;

            >div {
                padding-bottom: 24px;
                min-height: 100%;
                display: flex;
                flex-direction: column;

                .chart_wrapper {
                    border-top: 1px solid transparent;

                    &:first-child {
                        .top_elements_wrapper {
                            height: 35px;

                            .text_wrapper {
                                padding-top: 0;
                            }
                        }
                    }

                    .top_elements_wrapper {
                        height: 55px;
                        position: relative;

                        .text_wrapper {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            padding-top: 20px;
                            // padding-top: 24px;
                            padding-bottom: 7px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .title {
                                height: 24px;
                                font-size: 16px;
                                color: rgba(86, 87, 88, 1);
                                line-height: 24px;
                            }

                            .close_icon_wrapper {
                                width: 20px;
                                height: 20px;
                                background: rgba(235, 240, 255, 1);
                                border-radius: 2px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
                                user-select: none;

                                .close_icon {
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                        }
                    }

                    .chart {
                        height: 145px;
                        // margin-top: 7px;
                        background: rgba(238, 240, 242, 0.5);
                        border-radius: 2px;
                    }
                }

                .chart_wrapper_bottom_block {
                    min-height: 30px;
                    flex-grow: 1;
                }

                .chart_wrapper_dragover {
                    border-top: 1px dashed red;
                }
            }
        }
    }
}