.produce_statistics_wrapper {
    border: 1px solid rgba($color: #44F2D9, $alpha: 0.2);

    .item_header_wrapper {
        height: 57px;
        border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
        padding-left: 12px;
        display: flex;
        align-items: center;
        background: #0D1115;

        .item_header_icon {
            width: 24px;
            height: 24px;
        }

        .title {
            // width: 193px;
            padding-left: 5px;
            height: 18px;
            font-size: 18px;
            // font-family: PingFangSC-Medium, PingFang SC;
            // font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
        }
    }

    .item_content_wrapper {
        background-color: rgba(33, 40, 48, 1);
        padding: 16px;

        .inner_content_wrapper {
            height: 442px;
            background-color: rgba(25, 31, 39, 1);
            padding: 24px 48px;
            display: flex;

            .left {
                width: 0;
                flex-grow: 847;
                overflow-y: auto;

                table {
                    width: 100%;
                    // height: 100%;
                    // border-spacing: 0;
                    border-collapse: collapse;
                    // border-spacing: 0;

                    thead {

                        tr {
                            th {
                                position: sticky;
                                top: 0;
                                font-size: 16px;
                                // font-family: PingFangSC-Regular, PingFang SC;
                                // font-weight: 400;
                                color: #9FA0BA;
                                line-height: 16px;
                                padding: 0 0 12px 0;
                                background-color: rgba(25, 31, 39, 1);
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                font-size: 16px;
                                color: #9FA0BA;
                                line-height: 16px;
                                padding: 12px 0;
                                color: rgba(255, 255, 255, 1);

                                &>.name_block {
                                    height: 30px;
                                    width: 72px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 14px;
                                    color: rgba(255, 255, 255, 1);

                                    &.name_block_yellow {
                                        color: rgba(255, 217, 78, 1);
                                        border: 1px solid rgba(255, 217, 78, 1);
                                    }
                                }

                                &.bar_td {
                                    width: 160px;

                                    .bar_block {
                                        height: 30px;
                                        padding-right: 5px;
                                        // width: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        .bar_content {
                                            width: 120px;

                                            .bar {
                                                height: 8px;
                                                border-radius: 4px;
                                            }
                                        }

                                        &.redBar {

                                            .bar_content {
                                                .bar {
                                                    background-color: rgba(150, 75, 82, 1);
                                                }
                                            }
                                        }

                                        &.greenBar {

                                            .bar_content {
                                                .bar {
                                                    background-color: rgba(74, 155, 135, 1);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .divideLine {
                width: 1px;
                background-color: rgba(62, 64, 94, 1);
            }

            .right {
                width: 0;
                flex-grow: 680;
                overflow-y: auto;

                .table_wrapper {
                    padding-left: 41px;

                    table {
                        width: 100%;
                        border-collapse: collapse;
                        // border-spacing: 0;

                        thead {

                            tr {
                                th {
                                    position: sticky;
                                    top: 0;
                                    font-size: 16px;
                                    // font-family: PingFangSC-Regular, PingFang SC;
                                    // font-weight: 400;
                                    color: #9FA0BA;
                                    line-height: 16px;
                                    padding: 0 0 12px 0;
                                    background-color: rgba(25, 31, 39, 1);
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    font-size: 16px;
                                    color: #9FA0BA;
                                    line-height: 16px;
                                    padding: 12px 0;
                                    color: rgba(255, 255, 255, 1);

                                    &.bar_td {
                                        width: 160px;

                                        .bar_block {
                                            height: 30px;
                                            padding-right: 5px;
                                            // width: 100%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;

                                            .bar_content {
                                                width: 120px;

                                                .bar {
                                                    height: 8px;
                                                    border-radius: 4px;
                                                }
                                            }

                                            &.redBar {

                                                .bar_content {
                                                    .bar {
                                                        background-color: rgba(150, 75, 82, 1);
                                                    }
                                                }
                                            }

                                            &.greenBar {

                                                .bar_content {
                                                    .bar {
                                                        background-color: rgba(74, 155, 135, 1);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}