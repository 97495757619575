.alarm_page_header_wrapper {
    height: 70px;
    background: rgb(33, 40, 48);
    // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.11);
    display: flex;
    align-items: center;
    padding: 0 24px;

    .tab_title {
        font-size: 14px;
        line-height: 22px;
        padding: 24px 0;
        width: 112px;
        text-align: center;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.65);
        cursor: pointer;
        position: relative;

        .unfinished_circle {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-image: linear-gradient(270deg, rgba(235, 47, 150, 1) 0%, rgba(246, 38, 76, 1) 100%);
            text-align: center;
            font-size: 12px;
            color: rgba(255, 255, 255, 1);
            line-height: 20px;
            margin-left: 4px;
        }
    }

    .tab_title_selected {
        color: rgb(68, 243, 218);
        font-weight: 500;
    }

    .tab_title_selected::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1px;
        height: 2px;
        background: rgb(68, 243, 218);
    }

    .divide_line {
        margin-left: 28px;
        width: 1px;
        height: 28px;
        background: rgba(0, 0, 0, 1);
        opacity: 0.18;
    }

    .opt_title {
        margin: 0 12px 0 24px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 22px;
    }

    .btn_setting {
        margin-left: auto;
    }
}