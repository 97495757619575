.aFnAtivod {

    &.scroll_bar_restyle,
    .scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .wrapper_pos_block {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: -5px;
            right: 0;
            left: 0;
            height: 10px;
            background-color: rgb(33, 40, 48);
        }

        .table_wrapper {
            max-height: 70vh;
            overflow-y: auto;

            >table {

                thead {
                    tr {
                        th {
                            position: sticky;
                            top: 0;
                            z-index: 100;
                            background-color: rgb(33, 40, 48);
                        }
                    }
                }
            }
        }
    }
}