.NumInputWrapper {
    display: flex;

    .btn {
        border: 1px solid grey;
        border-radius: 2px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        user-select: none;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 2px blue;
            background-color: aquamarine;
        }
    }
}