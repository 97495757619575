.main_page_right_table_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(61, 205, 185, 0.2);
    background-color: rgba(33, 40, 48, 1);

    .title_wrapper {
        height: 45px;
        background-color: rgba(13, 17, 21, 1);
        padding-left: 12px;
        display: flex;
        align-items: center;

        .title_icon {
            width: 24px;
            height: 24px;
        }

        .text {
            height: 18px;
            font-size: 18px;
            // font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
            margin-left: 4px;
        }
    }

    .table_aLine {
        height: 1px;
        background-color: rgba(68, 243, 218, 0.2);
    }

    .table_content_wrapper {
        height: 0;
        flex-grow: 1;
        display: flex;

        .content_left_block {
            width: 0;
            flex-grow: 1;
            overflow-y: auto;

            .items_content_wrapper {
                .item_top_line {
                    height: 1px;
                    background: #3E405E;
                    margin: 0 12px 0 16px;
                }

                .item_self_wrapper {
                    height: 162px;
                    padding: 21px 20px 0 16px;
                }
            }
        }

        .content_right_block {
            width: 4px;
            padding: 2px 2px 2px 0;
            display: flex;
            flex-direction: column;

            .status_bar {
                height: 0;
                flex-grow: 1;
                border-radius: 2px;

                &:not(:first-child) {
                    margin-top: 5px;
                }

                &.warning_bar {
                    background: #FF6170;
                }

                &.normal_bar {
                    background: #747794;
                }
            }
        }
    }
}