:global {
    :local(.exam_rule_config_cement_analysis) {
        height: 100%;
        display: flex;
        flex-direction: column;

        .root_title {
            line-height: 21px;
            color: rgba(16, 16, 16, 1);
            font-size: 14px;
            font-weight: bold;
            padding: 28px 24px 18px;
            border-bottom: 1px solid rgba(187, 187, 187, 1);
        }

        .analysis_content_wrapper {
            height: 0;
            flex-grow: 1;
            overflow-y: auto;

            .top_setting_bar {
                padding: 10px 3px;
                border-bottom: 1px solid rgba(187, 187, 187, 1);

                .info_bar_content {
                    padding: 2px 10px;
                    background-color: rgba($color: #651FFF, $alpha: 0.5);
                    border-radius: 5px;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    >div:not(:first-child) {
                        margin-left: 40px;
                    }
                }

                .select_bar_wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;

                    .title {
                        font-weight: bold;
                        margin: 0 3px;
                    }
                }

                .cus_btn_wrapper {
                    display: flex;
                    margin-top: 10px;
                    justify-content: flex-end;
                }
            }

            .table_wrapper {
                padding: 10px 3px;

                .bar_wrapper {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: bold;
                        margin: 0 3px;
                    }
                }

                .info_bar_content {
                    padding: 2px 10px;
                    background-color: rgba($color: #651FFF, $alpha: 0.5);
                    border-radius: 5px;
                    color: #FFFFFF;
                    margin-top: 10px;
                    display: inline-block;

                    .flex_wrapper {
                        display: flex;
                        align-items: center;

                        >div:not(:first-child) {
                            margin-left: 40px;
                        }
                    }
                }

                table {
                    margin-top: 20px;
                    width: 100%;

                    >thead {
                        >tr {
                            >th {
                                font-weight: bold;
                                text-align: center;
                            }
                        }
                    }

                    >tbody {
                        >tr {
                            >td {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}