:global {

    :local(.subtitle) {
        height: 100%;
        padding-left: 63px;
        padding-right: 12px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        line-height: 14px;

        >.info {
            display: flex;
            align-items: center;

            >.title,
            >.value {
                height: 14px;
            }

            >.title {
                color: #999999;
            }

            >.value {
                margin-left: 3px;
                width: 304px;
                color: #333333;
            }

        }

        >.btn {
            margin-left: auto;
        }
    }

    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;

            >.scroll_wrapper {
                height: 100%;
                overflow-y: auto;

                table {
                    width: 100%;
                    font-size: 12px;

                    thead {
                        tr {

                            th {
                                padding: 0;
                                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                font-weight: 400;
                                line-height: 12px;
                                color: #999999;
                                text-align: center;

                                &.head {
                                    color: #666666;
                                }
                            }

                            &.head_scroll {
                                th {
                                    // box-shadow: 0 10px 5px rgba(61, 205, 185, 0.3);
                                    // box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, .15);
                                    // box-shadow: inset 0 -10px 8px -8px rgba(61, 205, 185, 0.3);
                                    box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.1);
                                }
                            }
                        }
                    }

                    td {
                        padding: 0;
                    }

                    &.factor {

                        th {
                            // 用padding替代height，因为在遇到换行时有更好的兼容性
                            padding: 13px 0 13px 0;
                            position: sticky;
                            top: 0;
                            z-index: 1;
                            background-color: #FFFFFF;

                            &.head,
                            &.name {
                                text-align: left;
                            }

                            &.head {
                                padding-left: 16px;
                                width: 95px;
                            }

                            &.name {
                                padding-left: 28px;
                            }

                            &.text_2_lines {
                                padding: 0;
                                // 视具体情况，在这里，标题行高度不可变，采用height更好
                                height: 38px;
                                line-height: 16px; // why default 16px ???
                            }

                            &.sort {
                                >.wrapper {
                                    display: inline-block;
                                    user-select: none;
                                    cursor: pointer;

                                    &:hover {
                                        opacity: 0.8;
                                    }

                                    >.content {
                                        display: flex;
                                        // 这里不用inline-block可以少一层嵌套
                                        // 然而这样和hover opacity冲突了
                                        // 还是需要多一层，包裹点击事件
                                        align-items: center;
                                        justify-content: center;

                                        >.text {
                                            margin-right: 4px;
                                        }

                                    }
                                }
                            }

                            &.degree {
                                width: 129px;
                            }

                            &.end {
                                width: 20px;
                            }
                        }

                        tbody {

                            // tip:tr可以指定height
                            tr {
                                &.level_1 {
                                    td {
                                        padding: 4px 0 10px 0;
                                        font-family: PingFangSC-Medium, PingFang SC;

                                        &.icon {
                                            width: 24px;

                                            >.icon_wrapper {
                                                height: 17px;
                                                display: flex;
                                                justify-content: center;
                                                padding-top: 3px;

                                                >.icon {
                                                    width: 12px;
                                                    height: 12px;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.level_2 {
                                    td {
                                        padding: 6px 0 6px 0;
                                        font-family: PingFangSC-Regular, PingFang SC;

                                        &.vertical_line {
                                            width: 13px;
                                            position: relative;

                                            &::after {
                                                content: "";
                                                position: absolute;
                                                left: 0;
                                                top: 8px; // 这里8px与上面6px相关，应该设置一个css变量
                                                bottom: 8px;
                                                width: 1px;
                                                background-color: #EFEFEF;
                                            }
                                        }
                                    }
                                }

                                &.level_1,
                                &.level_2 {
                                    td {
                                        text-align: center;
                                        font-size: 12px;
                                        font-weight: 500;
                                        color: #55616C;
                                        line-height: 17px;

                                        &.name {
                                            text-align: left;
                                        }

                                        &.action {
                                            >.btn {
                                                cursor: pointer;
                                                user-select: none;
                                                color: #1890FF;
                                                display: inline-block;

                                                &:hover {
                                                    opacity: 0.8;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.bottom {
                                    height: 27px;

                                    td {
                                        &.line {
                                            position: relative;

                                            &::after {
                                                content: "";
                                                position: absolute;
                                                left: 22px;
                                                right: 0;
                                                bottom: 0;
                                                height: 1px;
                                                background-color: #C9C9C9;
                                                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
                                                border-radius: 2px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.event {

                        th {
                            // 用padding替代height，因为在遇到换行时有更好的兼容性
                            padding: 20px 0 19px 0;
                            position: sticky;
                            top: 0;
                            background-color: #FFFFFF;

                            &.head {
                                text-align: left;
                                padding-left: 16px;
                                width: 95px;
                            }

                            &.detail {
                                width: 367px;
                            }

                            &.end {
                                width: 20px;
                            }
                        }

                        tbody {
                            tr {
                                &.data {
                                    td {
                                        padding: 12px 0 12px 0;
                                        text-align: center;

                                        &.detail {
                                            text-align: left;
                                        }

                                        &.action {
                                            >.btn {
                                                cursor: pointer;
                                                user-select: none;
                                                color: #1890FF;
                                                display: inline-block;

                                                &:hover {
                                                    opacity: 0.8;
                                                }
                                            }
                                        }
                                    }

                                    &.odd {
                                        td {
                                            &:not(.blank) {
                                                background-color: #F8F8F8;
                                            }

                                            &.content_first {
                                                border-top-left-radius: 8px;
                                                border-bottom-left-radius: 8px;
                                            }

                                            &.content_last {
                                                border-top-right-radius: 8px;
                                                border-bottom-right-radius: 8px;
                                            }
                                        }
                                    }
                                }

                                &.space {
                                    height: 1px;
                                }
                            }
                        }

                    }

                }

                >.space {
                    height: 12px;
                }
            }

            >.no_data_img_wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-bottom: 119px;

                >.img {
                    width: 230px;
                    height: 116px;
                }

                >.text {
                    font-size: 16px;
                    line-height: 28px;
                    height: 28px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B7B7B7;
                }
            }
        }
    }

}