.statistical_report_FtWJc {
    height: 100%;
    display: flex;
    flex-direction: column;

    .scroll_bar_restyle_0 {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 0;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .statistical_report_content_wrapper {
        height: 0;
        flex-grow: 1;
        padding: 24px 24px 28px 24px;

        .left_list_item_wrapper {
            height: 48px;
            position: relative;
            user-select: none;
            border-width: 0 1px 0 1px;
            border-style: solid;
            border-color: transparent rgba(68, 243, 218, 0.4) transparent transparent;

            .edit_icon {
                display: none;
            }

            &:hover {

                background-color: rgba(68, 243, 218, 0.05);
                border-color: transparent rgba(68, 243, 218, 0.4) transparent transparent;

                .edit_icon {
                    display: block;
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    opacity: 0.8;
                }
            }

            &.selected_left_list_item_wrapper {
                border-width: 1px;
                background-color: rgba(21, 25, 30, 1);
                border-color: rgba(68, 243, 218, 0.4) rgba(21, 25, 30, 1) rgba(68, 243, 218, 0.4) rgba(68, 243, 218, 0.4);
            }
        }
    }
}