:global {
    :local(.alarm_page_header_wrapper) {
        height: 32px;
        display: flex;
        cursor: pointer;
        user-select: none;
        background-color: #fff;
        border: 1px solid #fff;
        .tab_title {
            display: flex;
            align-items: center;
            border-color: #333;
            padding-left: 16px;
            padding-right: 16px;

            // &:first-child {
            //     border-style: solid;
            //     border-width: 2px;
            //     border-radius: 2px 0 0 2px / 2px 0 0 2px;
            // }

            // &:nth-child(n+2) {
            //     border-style: solid;
            //     border-width: 2px 2px 2px 0;
            // }

            // &:last-child {
            //     border-radius: 0 2px 2px 0 / 0 2px 2px 0;
            // }

            >.text {
                font-size: 14px;
                line-height: 22px;
                color: #333;
            }

            .unfinished_circle {
                display: inline-block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-image: linear-gradient(270deg, rgba(235, 47, 150, 1) 0%, rgba(246, 38, 76, 1) 100%);
                text-align: center;
                font-size: 12px;
                color: rgba(255, 255, 255, 1);
                line-height: 20px;
                margin-left: 4px;
            }


            &.tab_title_selected {
                border-color:#fff;
                background-color:#355B99;

                >.text {
                    color: #FFFFFF;
                }

            }
        }

    }
}