:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            padding: 12px;
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;

            >.resize {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            >.tree_map_dom {
                position: relative;
                height: 100%;
            }
        }
    }
}