.factor_statistics_wrapper {
    height: 100%;
    padding: 0 24px 0;
    overflow-y: auto;

    &.scroll_bar_restyle,
    .scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .content_wrapper {
        padding: 24px 0 42px;

        .content_root_title {
            height: 20px;
            font-size: 20px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
        }

        .item_wrapper {
            margin-top: 16px;
            // height: 185px;
            border: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
            // display: flex;
            // flex-direction: column;

            .item_header_wrapper {
                height: 57px;
                border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
                padding-left: 12px;
                display: flex;
                align-items: center;
                background: #0D1115;

                .item_header_icon {
                    width: 24px;
                    height: 24px;
                }

                .title {
                    // width: 193px;
                    padding-left: 5px;
                    height: 18px;
                    font-size: 18px;
                    // font-family: PingFangSC-Medium, PingFang SC;
                    // font-weight: 500;
                    color: #FFFFFF;
                    line-height: 18px;
                }
            }

            .item_content_wrapper {
                background-color: rgba(33, 40, 48, 1);
                padding: 16px;

                .inner_item_wrapper {
                    background: #191F27;
                    border-radius: 2px;
                    padding: 12px 0 24px 0;
                }
            }
        }
    }
}