:global {
    :local(.wrapper) {
        height: 40px;
        width: 102px;
        display: inline-block;
        text-align: left;

        >.chart {
            width: 100%;
            height: 100%;
        }
    }
}