.productionConst {
  width: 456px;
  height: 624px;
  background-color: #13191f;
  border-radius: 16px;
  margin-bottom: 24px;
  padding: 16px;
  font-family: 'Microsoft YaHei';
  .model-title {
    font-size: 18px;
    color: #a6b2c2;
    line-height: 24px;
  }

  &-top {
    padding: 13px 8px 0;

    .header {
      width: 100%;
      display: flex;

      &>div {
        font-size: 14px;
        color: #a6b2c2;
        flex: 1;

        opacity: 0.8;
      }
    }

    .row {
      margin-top: 12px;

      &-title {
        display: flex;

        &>div {
          position: relative;
          display: flex;
          align-items: flex-end;
          flex: 1;
          font-size: 12px;
          color: #8da1af;

          opacity: 0.8;

          &:first-child {
            font-size: 12px;
            color: #febc05;

            opacity: 0.8;
          }

          &:nth-child(2) {
            font-size: 18px;
            color: #fff;
            line-height: 24px;
            opacity: 1;
            &.up{
               color: #f16994;
            }
          }

        }
      }

      &-process {
        position: relative;
        height: 12px;
        border-radius: 8px;
        margin-top: 8px;

        .process-border{
            top: 0px;
            left: 0px;
            content: '';
            position: absolute;
            display: block;
            width: 0;
            height: 100%;
            border: 1px solid #8494bf;
            border-radius: 8px;
        }

        &>div:first-child {
          width: 0;
          height: 100%;
          padding: 2px;
          background-color: #33c2a4;
          background-clip: content-box;
          border-radius: 8px 0px 0px 8px;
          &.up{
            background-color: #f16994;
          }
        }
      }
    }

    hr {
      height: 1px;
      background-color: #8494bf;
    }

    .ant-divider {
      margin: 16px 0 0;
      border-color: #8494bf;
    }

    .triangle {
      display: inline-block;
      // width: 8px;
      // height: 8px;
      margin-bottom: 5px;

      &.up {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 8px solid #f16994;
      }

      &.down {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 8px solid#33C2A4;
      }

      &+span {
        text-indent: 10px;
      }
    }
  }

  &-bottom {
    padding-top: 13px;
    padding-left: 20px;
    display: flex;
    margin-left: 110px;
    position: relative;
    .part-text-left {
      position: absolute;
      left: -80px;
      top: 35px;
      &-item {
        margin-bottom: 16px;
        &>span {
          display: inline-block;
          margin-right: 8px;
          color: #febc05;
          font-size: 12px;
          line-height: 12px;
          opacity: 0.8;

          &:last-child {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            opacity: 1;
            margin-right: 0;
          }
        }
      }
    }
    .part-text-right {
      position: absolute;
      right: 40px;
      top: 35px;
      &-item {
        margin-bottom: 16px;
        &>span {
          display: inline-block;
          margin-right: 8px;
          color: #febc05;
          font-size: 12px;
          line-height: 12px;
          opacity: 0.8;

          &:last-child {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            opacity: 1;
            margin-right: 0;
          }
        }
      }
    }
    .partLeft,
    .partRight {
      display: flex;
      flex-direction: column;
      //justify-content: flex-end;
      //flex: 1;

      &-title {
        font-size: 14px;
        color: #a6b2c2;
      }
    }

    .partLeft {
      padding-right: 14px;
    }

    .partRight {
      padding-left: 14px;

      &-title {
        //text-indent: 80px;
      }

      .part-text {
        padding-left: 16px;
      }
    }

    .part-item {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 8px;

      .part-text {
        flex: 1;
        text-align: left;
        height: 18px;
        white-space: nowrap;

        &>span {
          display: inline-block;
          margin-right: 8px;
          color: #febc05;
          font-size: 12px;
          line-height: 12px;
          opacity: 0.8;

          &:last-child {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            line-height: 18px;
            opacity: 1;
            margin-right: 0;
          }
        }
      }
    }

    .bar-item {
      display: inline-block;
      width: 64px;
    }
  }
}
