:global {
    :local(.exam_rules_wrapper) {
        height: 100%;
        position: relative;

        >.inner_wrapper {
            height: 100%;
            padding: 24px 56px 0 72px;
        }

        >.secondary_wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}