.RoleMngSetting.layout-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .bottom_content_wrapper {
    height: 0;
    flex-grow: 1;
    background: #15191E;
    padding: 24px;

    >div {
      height: 100%;
      background: #191F27;
      border-radius: 2px;
    }

    .content_wrapper_header {
      height: 54px;
      box-shadow: 0px 1px 0px 0px rgba(68, 242, 217, 0.3);
      padding: 0 24px;
      display: flex;
      align-items: center;
    }

    .content_wrapper_content {
      height: calc(100% - 54px);
      padding: 24px 24px 24px 24px;
      display: flex;
      justify-content: space-between;

      .middle_block_item {
        align-self: center;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 1px solid rgba(68, 242, 217, 0.5);
        margin: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::after {
          content: '';
          display: block;
          width: 9px;
          height: 9px;
          border-width: 1px 1px 0 0;
          border-style: solid;
          border-color: rgba(68, 242, 217, 0.5);
          transform: rotate(45deg);
          position: relative;
          right: 3px;
        }
      }

      .block_wrapper {
        flex-grow: 1;
        width: 0;
        border-radius: 4px;
        border: 1px solid rgba(68, 242, 217, 0.3);
        display: flex;
        flex-direction: column;

        // &:nth-child(n+2) {
        //   margin-left: 56px;
        // }

        .block_header {
          height: 54px;
          background: rgba(0, 0, 0, 0.04);
          font-size: 16px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 54px;
          padding-left: 16px;
        }

        .block_content {
          // height: calc(100% - 54px);
          height: 0;
          flex-grow: 1;
          background: #212830;
          overflow-y: auto;

          .role_list_row {
            height: 54px;
            display: flex;
            align-items: center;
            padding: 16px 52px 16px 16px;
            cursor: pointer;

            &:hover {
              background: rgba(24, 144, 255, 0.05);

              .opt_box {
                display: flex;
              }
            }

            .name {
              height: 22px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.65);
              line-height: 22px;
            }

            .opt_box{
                margin-left: auto;
                display: none;
                .delete {
                    display: flex;
                    height: 20px;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(24, 144, 255, 1);
                    line-height: 20px;
                    align-items: center;
                }
                .on_off_switch {
                    margin-left: 20px;
                }
            }
          }

          .role_list_row_selected {
            background: rgba(24, 144, 255, 0.05);

            // .delete {
            //   display: flex;
            // }
          }

          .role_list_row_selected_input {
            background: rgba(24, 144, 255, 0.15);

            &:hover {
              background: rgba(24, 144, 255, 0.15);

              .save {
                display: block;
              }
            }

            .save {
              margin-left: auto;
              height: 20px;
              font-size: 12px;
              font-weight: 400;
              color: rgba(24, 144, 255, 1);
              line-height: 20px;
              display: none;
            }

            .delete {
              margin-left: 20px;
            }
          }

          //hardcode
          .ant-tree-treenode {
            // width: 100%;
            height: 54px;
            // align-items: center;

            .ant-tree-switcher {
              // height: 24px;
              margin-top: 12px;
            }

            .ant-tree-checkbox {
              margin-top: 16px;
            }

            .ant-tree-node-content-wrapper {
              // width: 100%;
              align-self: stretch;
              line-height: 50px;
            }
          }

          .auth_list_row {
            height: 54px;
            display: flex;
            align-items: center;
            padding: 16px;

            .name {
              height: 22px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(255, 255, 255, 0.65);
              line-height: 22px;
              margin-left: 8px;
            }
          }

        }
      }
    }
  }
}