:global {
    :local(.optimization_custom-content-wrapper) {
        padding-bottom: 60px;
        .ant-radio-inner {
            border-color: #375B98 !important;
        }
    
        .ant-radio-wrapper-checked .ant-radio-inner::after {
            background-color: #375B98 !important;
            border-color: #375B98 !important;
        }
        .ant-slider-handle{
            border: 2px solid #375B98 !important;
        }
        .ant-btn-primary{
            background-color: #375B98 !important;
            border-color: #375B98 !important;
            color: #fff !important;
        }
        .ant-btn-link{
            color: #375B98 !important;
        }
        .ant-checkbox-checked .ant-checkbox-inner{
            background-color: #375B98 !important; 
            border-color: #375B98 !important;
        }
        .ant-radio-checked .ant-radio-inner{
            border-color: #375B98 !important;
        }
        .ant-drawer-body{
            padding-top:10px ;
        }
        .ant-radio-group{
            font-size: 14px;
        }
        .ant-drawer-header{
            background: #E2E4E7;
        }
        .wrapper{
            padding: 12px 0 18px 0;
            border-bottom: 1px solid #E6E6E6;
            .title{
                margin-right: 34px;
            }
        }
        .slider-wrapper {
            display: flex;
            align-items: center;
            .custom-tooltip {
            //     position: relative;

            //     >img {
            //         display: block;
            //     }

                .content {
                   margin-right: 10px;
                }
            }

            .slider-bar {
                flex-grow: 1;

                .ant-slider-step {
                    //hardcode
                    background-color: #375B98;
                }
            }
        }

        .period_row {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            height: 44px;
            padding: 0 24px;
            border-bottom:1px solid #E6E6E6 ;
            &:hover {
                background: rgba(24, 144, 255, 0.05);
            }

            >div:first-child {
                margin-right: auto;
            }
        }

        table {
            // border: 1px solid #D8D8D8;
        }

        th {
            text-align: center;
            background-color: #fff;
            height: 40px;
        }

        td {
            text-align: center;
            height: 40px;
            // border-top: 1px solid rgba(0, 0, 0, 0.0784313725490196);;
        }

        .td-title {
            text-align: left;
            padding-left: 8px;
        }

        .issue_wrapper {
            background: #F5F7FA;
            padding: 0 12px 12px;
            display: flex;
            flex-wrap: wrap;

            .issue_block {
                margin-top: 12px;
                margin-right: 12px;
                display: flex;
                padding: 3px 8px;
                align-items: center;
                background: #F5F5F5;
                border-radius: 14px;
                border: 1px solid #C9CFD5;

                .content {
                    font-size: 12px;
                    color: #666666;
                    line-height: 22px;
                }
            }
        }
    }

    :local(.optimization_footer) {
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        left: 0;
        background: #ffffff;
        border-radius: 0 0 4px 4px;
        .btn{
            background-color: #375B98 !important;
            border-color: #375B98 !important;
            color: #fff !important;
        }
    }
}