:global {
    :local(.wrapper) {
        height: 44px;
        display: flex;
        align-items: center;

        // >.left {
        //     >.text {
        //         height: 14px;
        //         font-size: 14px;
        //         font-family: PingFangSC-Regular, PingFang SC;
        //         font-weight: 400;
        //         color: #2B334B;
        //         line-height: 14px;
        //     }
        // }

        >.right {
            margin-left: auto;
        }
    }
}