.energy_consumption_item_wrapper {
    width: 480px;
    height: 248px;
    background-color: rgba(25, 31, 39, 1);
    padding-top: 9px;

    .title_header {
        position: relative;

        .text {
            padding-left: 10px;
            height: 14px;
            font-size: 14px;
            // font-weight: 500;
            color: #BFC0DA;
            line-height: 14px;
        }

        .title_icon {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 2px;
        }
    }

    .item_body_wrapper {
        display: flex;

        .left {
            width: 0;
            flex-grow: 1;
            padding-top: 24px;
            padding-left: 10px;

            .info_item_wrapper {

                &:not(:first-child) {
                    margin-top: 24px;
                }

                .name_wrapper {
                    display: flex;
                    align-items: center;

                    .text {
                        height: 14px;
                        font-size: 14px;
                        // font-weight: 400;
                        color: #8C8F93;
                        line-height: 14px;
                    }

                    .icon {
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 8px 5px 0 5px;
                        border-color: rgba(68, 243, 218, 1) transparent transparent transparent;
                        margin-left: 8px;
                    }
                }

                .value_wrapper {
                    margin-top: 8px;
                    display: flex;
                    align-items: flex-end;

                    .value {
                        height: 20px;
                        font-size: 20px;
                        color: #FFFFFF;
                        line-height: 20px;
                    }

                    .unit {
                        margin-left: 3px;
                        height: 14px;
                        font-size: 14px;
                        // font-weight: 500;
                        color: #FFFFFF;
                        line-height: 14px;
                        opacity: 0.5;
                    }
                }
            }
        }

        .right {
            width: 353px;

            .chart_color_desc_wrapper {
                padding-left: 101px;
                display: flex;
                align-items: center;

                .ave_color_icon {
                    width: 12px;
                    height: 4px;
                    // background: #44F3DA;
                    border-radius: 2px;
                }

                .ave_color_text {
                    margin-left: 6px;
                    height: 12px;
                    font-size: 12px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }

                .standard_color_icon {
                    margin-left: 30px;
                    width: 16px;
                    height: 1px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 9px;
                        height: 9px;
                        border-radius: 50%;
                        background-color: inherit;
                        left: 50%;
                        top: -4px;
                        transform: translateX(-50%);
                        opacity: 0.3;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        background-color: inherit;
                        left: 50%;
                        top: -1px;
                        transform: translateX(-50%);
                    }
                }

                .standard_color_text {
                    margin-left: 6px;
                    height: 12px;
                    font-size: 12px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }
            }

            .chart_wrapper {
                margin-top: 12px;
                display: inline-block;
                width: 346px;
                height: 143px;
            }

            .hourly_sign_wrapper {
                margin-top: 18px;
                display: inline-block;
                width: 346px;

                .hourly_items {
                    display: flex;
                    padding: 0 0 0 1px;

                    .sign_item {
                        width: 0;
                        flex-grow: 1;
                        height: 2px;
                        background: #747794;

                        &:not(:first-child) {
                            margin-left: 1px;
                        }

                        &.sign_item_warning {
                            background: #FF6170;
                        }
                    }
                }

                .value_wrapper {
                    margin-top: 5px;
                    display: flex;
                    justify-content: space-between;

                    .name {
                        height: 12px;
                        font-size: 12px;
                        // font-weight: 400;
                        color: #9FA0BA;
                        line-height: 12px;
                    }

                    .value {
                        height: 12px;
                        font-size: 12px;
                        // font-weight: 400;
                        color: #FF6170;
                        line-height: 12px;
                    }
                }
            }
        }
    }
}