.table_wrapper_gPxgP {
    overflow: auto;

    &.scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    >table {
        min-width: 100%;
        // width: 100%;
        // border-collapse: separate;
        // border-spacing: 0 16px;

        thead {
            color: #9FA0BA;
            font-size: 16px;

            tr {
                th {
                    padding: 0;
                    height: 48px;
                    position: sticky;
                    top: 0;
                    background-color: rgb(21, 25, 30);

                    &:first-child {
                        padding-left: 24px;
                        left: 0;
                        z-index: 1;
                    }

                    &:nth-child(n+2) {
                        padding-right: 20px;
                        min-width: 170px;
                        white-space: nowrap;
                    }
                }

                &.head_scroll {
                    th {
                        // box-shadow: 0 10px 5px rgba(61, 205, 185, 0.3);
                        // box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, .15);
                        box-shadow: inset 0 -10px 8px -8px rgba(61, 205, 185, 0.3);
                    }
                }

                &.left_head_scroll {
                    th {
                        &:first-child {
                            box-shadow: inset -10px 0 8px -8px rgba(61, 205, 185, 0.3);
                        }
                    }

                    &.head_scroll {
                        th {
                            &:first-child {
                                box-shadow: inset -10px 0 8px -8px rgba(61, 205, 185, 0.3), inset 0 -10px 8px -8px rgba(61, 205, 185, 0.3);
                            }
                        }
                    }
                }
            }
        }

        tbody {
            color: #FFFFFF;
            font-size: 14px;

            tr {

                &.data_tr {
                    td {
                        padding: 0;
                        height: 32px;

                        &:first-child {
                            padding-left: 24px;
                            padding-right: 12px;
                            white-space: nowrap;
                        }
                    }

                    &:hover {
                        td {
                            &:first-child {
                                background-color: rgba(22, 35, 39, 1);
                            }

                            &:nth-child(n+2) {
                                background-color: rgba(68, 243, 218, 0.05);
                            }
                        }
                    }
                }

                td {
                    &:first-child {
                        position: sticky;
                        left: 0;
                        background-color: rgb(21, 25, 30);

                        &.head_scroll {
                            box-shadow: inset -10px 0 8px -8px rgba(61, 205, 185, 0.3);
                        }
                    }
                }
            }
        }
    }
}