:global {
    :local(.wrapper) {
        width: 144px;
        height: 96px;
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: rgba(13, 17, 21, 0.59);
            border: 1px solid rgba(141, 161, 175, 1);
            opacity: 0.7;
            border-radius: 10px;
        }

        >.content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 13px 10px 0 16px;

            >.title {
                height: 19px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 19px;
                color: #FEBC05;
                // opacity: 0.8;
            }

            >.row {
                display: flex;
                align-items: flex-end;

                >.title {
                    width: 16px;
                    height: 24px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    line-height: 24px;
                    color: #72838E;
                    opacity: 1;
                }

                >.value {
                    width: 0;
                    flex-grow: 1;
                    height: 24px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    line-height: 24px;
                    color: #FFFFFF;
                }

                >.unit {
                    width: 38px;
                    height: 19px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    line-height: 19px;
                    color: #8DA1AF;
                    opacity: 0.8;
                }
            }
        }
    }
}