html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  font-family: 'PingFang SC Regular','Lantinghei SC Extralight','Microsoft Yahei Regular',Helvetica,'\5b8b\4f53',sans-serif;
}

#root {
    height: 100%;
    // .ant-layout{
    //     background-color: #FFF;
    // }
}

/** modal弹窗公共样式 */
.common-modal {
    .ant-modal-close {
        right: 10px;
    }
    .ant-modal-close-x{
        width: 56px !important;
        height: 56px !important;
        line-height: 56px !important;
        font-size: 30px !important;
        background: url('./assets/images/close.png') no-repeat center;
        &>span{
            display: none;
        }
    }
    .ant-modal-header{
        position: relative;
        height: 56px;
        padding: 0 24px !important;       
        border-radius: 4px 4px 0 0 !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #E2E4E7;
        .ant-modal-title{
            padding: 0 !important;
            font-size: 16px;
            color: #333;
            line-height: 56px;           
        }
        &::before {
            position: absolute;
            left: 0;
            content: '';
            display: inline-block;
            border-top: 4px solid transparent;
            border-left: 4px solid #375B98;
            border-bottom: 4px solid transparent;
            height: 24px;
            width: 4px;
        }
    }
}
