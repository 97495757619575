.main_page_bottom_panels_energy_consumption_modal_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 1);
        opacity: 0.7;
    }

    .cus_modal_wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1032px;
        height: 614px;
        // opacity: 0.6;
        border: 1px solid rgba($color: #44F2D9, $alpha: 0.6);
        background-color: rgba(33, 40, 48, 1);
        display: flex;
        flex-direction: column;

        .modal_header {
            height: 46px;
            background: #0D1115;
            border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
            padding: 0 16px 0 16px;
            display: flex;
            align-items: center;

            .title_icon {
                width: 24px;
            }

            .title {
                height: 18px;
                font-size: 18px;
                // font-weight: 500;
                color: #FFFFFF;
                line-height: 18px;
                margin-left: 5px;
            }

            .time {
                margin-left: 12px;
                height: 16px;
                font-size: 16px;
                // font-weight: 400;
                color: #9FA0BA;
                line-height: 16px;
            }

            .cus_close_icon {
                margin-left: auto;
                margin-right: 4px;
                font-size: 24px;
                color: rgba(89, 97, 104, 1);
            }
        }

        .modal_body {
            height: 0;
            flex-grow: 1;
            // overflow-y: auto;
            padding-top: 24px;

            .cus_content_wrapper {
                height: 100%;
                overflow-y: auto;
                display: flex;
                flex-wrap: wrap;

                .item_self_wrapper {
                    margin-left: 24px;
                    margin-bottom: 24px;
                }
            }
        }
    }
}