:global {
  :local(.ant-advanced-search-form) {
    .ant-form-item {
      display: flex;
    }
    .row_item {
      display: flex;
      align-items: center;

      &:nth-child(n+2) {
        margin-top: 24px;
      }

      &:last-child {
        margin-top: 16px;
      }

      .item_title {
        width: 110px;
        padding-right: 40px;
        text-align: right;
      }

      .select_root_wrapper {
        width: 300px;
        position: relative;

        .role_select_wrapper {
          width: 100%;
          min-height: 34px;
          padding: 5px 5px 2px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          display: flex;
          flex-wrap: wrap;

          .role_item {
            height: 22px;
            background: rgba(230, 247, 255, 1);
            border-radius: 2px;
            border: 1px solid rgba(145, 213, 255, 1);
            padding: 1px 8px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: rgba(24, 144, 255, 1);
            margin-right: 8px;
            margin-bottom: 3px;

            >span {
              margin-left: 4px;
              cursor: pointer;
            }
          }
        }

        .role_select_values_wrapper {
          position: absolute;
          z-index: 1;
          left: 0;
          right: 0;
          top: 0;
          background: rgba(255, 255, 255, 1);
          // background: rgba(20, 20, 20, 1);
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
          border-radius: 4px;

          .select_values_content_wrapper {
            display: flex;
            flex-wrap: wrap;
            padding: 16px 16px 21px;

            .role_value_item {
              cursor: pointer;
              height: 22px;
              // background: rgba(0, 0, 0, 0.04);
              background: rgba(245, 245, 245, 1);
              border-radius: 2px;
              border: 1px solid rgba(0, 0, 0, 0.15);
              padding: 1px 8px;
              font-size: 12px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
              display: flex;
              align-items: center;
              margin-right: 8px;
              margin-bottom: 3px;
            }

            .role_value_item_selected {
              background: rgba(230, 247, 255, 1);
              border: 1px solid rgba(145, 213, 255, 1);
              color: rgba(24, 144, 255, 1);
            }
          }
        }
      }
    }
  }
  :local(.SchedulingManagement) {
    // height: 100%;
    // background-color: #EDF7F8;
    // padding: 0 48px;

    .layout-first {
      background-color:#fff;
      // margin: 24px;
      border-radius: 2px;
      // padding: 20px 0 16px 16px;
      color: #333;
      box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
      .layout-second {
        font-size: 16px;
        border-bottom: 1px solid #ddd;
        .title{
          font-weight: bold;
          font-size: 16px;
          color: #666;
          padding: 20px 16px;
        }
        .setting{
          height: 100%;
          padding: 16px 16px 16px 32px;
          // background-color: #fafafa;
          .setting-row{
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            .setting-info{
              background-color: #EfEfEf;
              overflow-x: auto;
              width: 50%;
              border-radius: 2px;
              // border: 1px solid #98c1c3;
              min-height: 70px;
              margin-right: 16px;
              display: flex;
              align-items: center;
              // justify-content: space-between;
              padding: 16px 24px;
              &>:first-child{
                width: 12%;
                justify-content: space-between;
                display: flex;
                align-items: center;
              }
              &>:last-child{
                width: 88%;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                color: #666;
              }
              .period{
                font-size: 20px;
                font-weight: bold;
              }
              .selects{
                width: 72px;
                .ant-select-selector{
                    border-color: #c9c9c9 !important;
                }                
              }
              .second-title{
                font-weight: bold;
                font-size: 16px;
              }
              .setting-time{
                text-align: center;
                &>:first-child{
                  font-weight: bold;
                }
                p{
                  margin: 0;
                }
              }
              .group-details{
                overflow: hidden;
                margin-left: 8%;
                display: -webkit-box;
              }
              .setting-group{
                text-align: center;
                word-break:keep-all;           
                white-space:nowrap;
                margin: 0 5px;  
                max-width: 200px;
                &>:first-child{
                  font-weight: bold;
                  min-width: 80px;
                  max-width: 180px;
                  height: 32px;
                  padding: 0 5px;
                  line-height: 32px;
                  color: #fff;
                  text-align: center;
                  margin: 0 auto;
                  border-radius: 4px;
                  margin-bottom: 8px !important;
                  text-overflow:ellipsis;
                  overflow:hidden; 
                }
                &>:last-child{
                  max-width: 300px;
                  overflow:hidden; 
                  text-overflow:ellipsis;
                }
                p{
                  margin: 0;
                }
              }
            }
          }
        }
        .setting-footer{
          margin-right: 16px;
          text-align: right;
        }
        .setting-results{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 32px;
          .ant-btn-primary{
            background-color: #357CF7;
            border-radius: 4px;
            border: none;
            margin-left:16px;
          }
          .ant-picker-range{
            margin-left: 56px;
          }
          .calender{
            margin: 0 16px;
          }
          .calender-arrow-right,.calender-arrow-left{
            font-size: 14px;
            display: block;
            cursor: pointer;
            background-color: #F5F6F8;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 4px;
          }
          .calender-arrow-right:hover,.calender-arrow-left:hover{
            color: #aaa;
          }

        }
        .table_wrapper {
          height: calc(100% - 80px);
        //   overflow-y: auto;
          margin: 0 32px;
          .ant-table-thead{
              tr:first-child {
                  th {
                      border-top: 1px solid #E6E6E6;
                      border-right: 1px solid #E6E6E6;
                      font-weight: bold !important;
                      padding-bottom: 0 !important;
                  }

                  th:first-child {
                      border-radius: 2px 0px 0px 0px;
                      border-left: 1px solid #E6E6E6;
                      font-weight: bold !important;
                  }

                  th:last-child {
                      border-radius: 0px 2px 0px 0px;
                  }
              }
              tr:nth-child(2){
                  th{
                      border-top: none;
                      
                      &.border-right{
                        border-right: 1px solid #E6E6E6;
                      }
                  }
              }
            
            th{
              width: 150px;
              min-width: 150px;
              background-color: #F5F5F5;
              color: #333;
            //   border-top: 1px solid #98c1c3;
            //   border-bottom: 1px solid #98c1c3;
            //   border-left: 1px solid #98c1c3;
            }
          }
          .ant-table-body{
            border-left: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
            color: #666;
            table{
              tbody{
                tr:nth-child(odd) {
                  background-color: #FAFAFA;
                  .ant-table-cell-fix-left,.ant-table-cell-fix-right{
                    background-color: #FAFAFA;
                  }
                }
                td{
                  border-right: 1px solid #e6e6e6;
                }
              }
              // .ant-table-cell-fix-left{
              //   background-color: #FAFAFA;
              // }
            }
          }
          .edit-btn{
              display: block;
              width: 16px;
              height: 16px;
              background: url('../../assets/edit.png') no-repeat;
              cursor: pointer;
              margin: auto;
          }
        }

        .grey_table_row {
          >td {
            background-color: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
    .yellow{
      background-color: #FFBE0D;
    }
    .blue{
      background-color:#5CA9C8;
    }
    .orange{
      background-color: #5E7BC4;
    }
  }
  :local(.Scheduling){
      padding: 0 24px 24px;
  }
}
