.main_page_bottom_panels_production_comp_wrapper {
    height: 100%;
    overflow-y: auto;

    .production_content_wrapper {
        padding: 2px 0 19px 16px;

        .item_wrapper {
            display: flex;
            align-items: flex-start;
            padding-top: 19px;

            .left,
            .mid,
            .right {
                display: flex;
                flex-direction: column;

                .title {
                    height: 14px;
                    font-size: 14px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 14px;
                }
            }

            .left {
                width: 0;
                flex-grow: 1;

                .bar {
                    margin-top: 14px;
                    height: 8px;
                    background: #3F455F;
                    border-radius: 4px;

                    .bar_content {
                        height: 8px;
                        background-color: transparent;
                        border-radius: 4px;
                    }
                }
            }

            .mid {
                width: 72px;
                align-items: center;

                .value_wrapper {
                    margin-top: 9px;
                    height: 16px;
                    font-size: 16px;
                    // font-weight: 400;
                    color: #44F3DA;
                    line-height: 16px;
                }
            }

            .right {
                width: 106px;
                align-items: center;

                .value_wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: 9px;

                    .text {
                        height: 16px;
                        font-size: 16px;
                        // font-weight: 400;
                        color: #44F3DA;
                        line-height: 16px;
                    }

                    .arrow_top {
                        margin-left: 8px;
                        width: 0;
                        height: 0;
                        border-width: 0 5px 8px 5px;
                        border-style: solid;
                        border-color: transparent transparent rgba(68, 243, 218, 1) transparent;
                    }

                    .arrow_down {
                        margin-left: 8px;
                        width: 0;
                        height: 0;
                        border-width: 8px 5px 0 5px;
                        border-style: solid;
                        border-color: rgba(255, 97, 112, 1) transparent transparent transparent;
                    }

                    .arrow_null {
                        width: 10px;
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}