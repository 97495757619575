:global {
    :local(.wrapper) {
        padding: 24px 32px 0 32px;
        display: flex;
        align-items: flex-start;
        color: #3A3A3A;

        .left {
            display: flex;
            align-items: flex-end;
            font-family: Source Han Sans CN;

            .prod_name {
                height: 36px;
                font-size: 36px;
                font-weight: 800;
                line-height: 36px;
            }

            .comp_name,
            .sys_name {
                position: relative;
                bottom: 4px;
                margin-left: 8px;
            }

            .comp_name {
                height: 20px;
                font-size: 20px;
                font-weight: bold;
                line-height: 20px;
            }

            .sys_name {
                height: 16px;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .right {
            margin-left: auto;
            display: flex;
            align-items: center;

            .avatar {
                width: 40px;
                height: 43px;
            }

            .user_name {
                margin-left: 8px;
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                line-height: 14px;
            }
        }
    }
}