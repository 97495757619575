.filterCapsuleModal {
    .ant-modal{
        height: 80vh;
    }
    .ant-modal-content {
        border-radius: 16px;
        width: 1264px;
        height: 100%;
        min-height: 400px;
    }

    .ant-modal-close-x {
        height: 40px;
        line-height: 40px;
    }

    .ant-modal-body {
        padding: 0;
        height: 100%;

        .title {
            width: 50%;
            font-size: 16px;
            font-family: 'Source Han Sans CN';
            font-weight: bold;
            color: #727272;
            line-height: 40px;
            padding-left: 40px;
            background: url('../../../../assets/svg/icon_filter.svg') no-repeat 16px;
        }

        .filter-container {

            background-color: #EDF7F8;
            display: flex;
            padding: 16px 0;

            &>div {
                display: flex;
                flex-direction: column;
                padding: 0 24px;

                &:not(:first-child):not(:last-child) {
                    border-left: 1px solid #707070;
                }

                .ant-picker {
                    border-color: #AFDAD8 !important;
                    margin-bottom: 5px;
                    padding: 4px 6px;
                }

                .ant-tabs {
                    .ant-tabs-nav {
                        margin-bottom: 8px;
                        &::before {
                            border: none;
                        }
                    }

                    .ant-tabs-tab {
                        padding: 0;
                        margin-right: 8px;

                        .ant-tabs-tab-btn {
                            font-size: 16px;
                            font-family: 'Source Han Sans CN';
                            font-weight: 500;
                            line-height: 22px;
                            color: #727272;

                            :hover {
                                color: #375B98;
                            }
                        }
                    }

                    .ant-radio-inner {
                        border-color: #375B98;
                    }

                    .ant-radio-wrapper-checked .ant-radio-inner::after {
                        background-color: #375B98;
                    }

                    .ant-tabs-tabpane {
                        white-space: nowrap;
                    }

                    .ant-tabs-ink-bar {
                        background: #375B98;
                    }

                    .ant-tabs-tab-active {
                        .ant-tabs-tab-btn {
                            color: #375B98;
                        }

                    }

                    span.ant-radio+* {
                        padding-right: 3px;
                        padding-left: 4px;
                    }
                }

                &>label {
                    font-size: 16px;
                    font-family: 'Source Han Sans CN';
                    font-weight: 500;
                    line-height: 22px;
                    color: #727272;
                    margin-bottom: 8px;
                }

                .ant-select-selector {
                    border-color: #AFDAD8 !important;
                }

                .ant-input {
                    padding: 4px;
                    border-color: #AFDAD8;
                }
            }

            .search-icon {
                color: #fff;
                border: 1px solid #375B98;
                width: 72px;
                height: 32px;
                background: #375B98;
                border-radius: 4px;
                cursor: pointer;
                margin-top: 30px;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }

    .ant-modal-footer {
        display: none;
    }
}