.LeftSettingPart {
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
    display: flex;

    .left {
        flex: 1;
        box-sizing: border-box;
        padding-left: 5px;

        .thirdSetting {
            display: flex;
        }

        .bckResize {
            display: flex;
            align-items: center;
            margin-right: 16px;

            .tip {
                word-break: keep-all;
                white-space: nowrap;
            }

            .inputWrapper {
                // margin-left: 5px;
                width: 100px;
            }
        }
    }

    .right {
        flex: 1;
        box-sizing: border-box;
        border-left: 1px solid grey;
        padding-left: 5px;

        .resizeWrapper {
            display: flex;
            align-items: center;

            .sliderWrapper {
                flex-grow: 1;
                min-width: 40px;
            }

            .inputWrapper {
                flex-shrink: 0;
                width: 80px;
                margin-left: 5px;
            }

            .colorWrapper {
                width: 80px;
            }
        }

        .coordWrapper {
            display: flex;

            .xyWrapper {
                >div {
                    >input {
                        width: 100px;
                    }
                }
            }

            .directionWrapper {
                display: flex;
                align-items: center;
                margin-left: 5px;

                .direction {
                    box-sizing: border-box;
                    width: 31px;
                    font-size: 20px;
                    line-height: 100%;
                    height: 31px;
                    border: 1px solid grey;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    user-select: none;
                }

                .direction:hover {
                    background-color: aliceblue;
                }

                .dirMid {
                    margin: 0px 2px;

                    .direction:first-child {
                        margin-bottom: 2px;
                    }
                }
            }
        }
    }
}