:global {
    :local(.wrapper) {
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        background: #FFFFFF;
        padding: 20px;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;

        >.tab_row {
            display: flex;

            .tab_item {
                height: 40px;
                padding: 0 16px;
                user-select: none;
                cursor: pointer;

                &:nth-child(n+2) {
                    margin-left: 16px;
                }

                .text {
                    height: 16px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 16px;
                    color: #999;
                    display: flex;
                    img{
                        margin-right: 8px;
                    }
                }

                &.selected_tab_item {
                    position: relative;
                    .text{
                        color: #357CF7 !important;
                    }
                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        content: '';
                        height: 3px;
                        background-color:#357CF7;
                    }
                }
            }

            .switch_btn_wrapper {
                width: 120px;
                height: 24px;
                border-radius: 2px;
                user-select: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 2px;

                .text {
                    height: 16px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 16px;
                    color: #3A3A3A;
                }

                .ball {
                    width: 20px;
                    height: 20px;
                    border-radius: 2px;
                    background-color: #FFFFFF;
                }
            }
        }

        >.content_wrapper {
            margin-top: 24px;
            height: 0;
            flex-grow: 1;
            overflow: auto;

            >table {
                min-width: 100%;
                border-collapse: separate;
                border-spacing: 0;

                >thead {
                    >tr {
                        >th {
                            padding: 0;
                            border: 1px solid #E6E6E6;
                            background-color: #F5F5F5;
                            position: sticky;
                        }

                        &:first-child {
                            font-size: 16px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            line-height: 16px;
                            color: #333;

                            >th {
                                padding-left: 15px;
                                top: 0;

                                &:first-child {
                                    border-top-left-radius: 2px;
                                    height: 56px;
                                }

                                &:last-child {
                                    border-top-right-radius: 2px;
                                }

                                &:nth-child(n+2) {
                                    border-left-width: 0;
                                    height: 32px;
                                    padding-bottom: 1px;

                                    &:nth-child(n+3) {
                                        border-bottom-width: 0;
                                    }
                                }
                            }
                        }

                        &:nth-child(2) {
                            >th {
                                border-left-width: 0;
                                border-top-width: 0;
                                // padding-bottom: 7px;
                                top: 32px;

                                >.th_content {
                                    display: flex;
                                    align-items: flex-end;
                                    height: 23px;
                                    padding-bottom: 7px;

                                    >.name {
                                        height: 16px;
                                        font-size: 16px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 400;
                                        line-height: 16px;
                                        color: #333;
                                        margin-left: 15px;
                                    }

                                    >.unit {
                                        height: 12px;
                                        font-size: 12px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 400;
                                        line-height: 12px;
                                        color: #333;
                                    }

                                    &.need_left_line {
                                        &::before {
                                            content: '';
                                            display: inline-block;
                                            width: 1px;
                                            height: 16px;
                                            background-color: #E6E6E6;
                                        }
                                    }
                                }

                                >.compare_content {
                                    height: 23px;
                                    display: inline-block;

                                    >.text {
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        padding: 0 4px;
                                        height: 16px;
                                        background-color: #FEBC05;
                                        border-radius: 2px;
                                        font-size: 12px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 400;
                                        line-height: 12px;
                                        color: #333;
                                    }
                                }
                            }
                        }
                    }

                    // &.head_scroll {
                    //     >tr {
                    //         &:first-child {
                    //             >th {

                    //                 &:first-child,
                    //                 &:nth-child(2) {
                    //                     box-shadow: 0 -10px 8px -8px rgba(61, 205, 185, 0.3);
                    //                 }
                    //             }
                    //         }
                    //     }
                    // }
                }

                >tbody {
                    >tr {
                        >td {
                            padding: 16px 0;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 22px;
                            color: #3A3A3A;
                            padding-left: 16px;

                            &.compare {
                                padding-left: 0;
                            }

                            &.time {
                                padding-left: 14px;
                            }

                            &.bold {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
}