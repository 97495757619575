:global {
    :local(.commentWrapper) {
        padding-top: 24px;
        border-top: 1px solid #E2E4E7;
        .title {
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            color: #000;
            text-indent: 26px;
            margin-bottom: 24px;
            background: url('../../../../../../../../assets/images/v3/comment_tag.png') no-repeat left;
            &>span{
                color: #999;
            }
        }

        .comments {
            list-style: none;
            padding: 0;
            margin: 0;

            .comment-item {
                display: flex;
                flex-direction: column;
                margin-bottom: 24px;

                .info{
                    line-height: 20px;
                    margin-bottom: 8px;
                    
                    .creator{
                        font-size: 14px;
                        color: #2B3249;
                    }
                    .org-name{
                        font-size: 12px;
                        color: #999;
                    }
                    .create-time{
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.65);
                    }
                }

                .text {
                    max-width: 450px;
                    padding: 8px 12px;
                    border-radius: 2px;
                    background-color: #CFEBFF;

                    span {
                        max-width: 100%;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: pre-wrap;
                        word-break: break-all;
                    }
                }

                &.right {
                    align-items: flex-end;
                    .text{
                        background-color: #F5F5F5;
                    }
                }
            }
        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ant-btn {
                background-color: #375B98;
                border-color: #375B98;

                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}