:global{
    :local(.QrCodeModal){
        .ant-modal-content {
            pointer-events: none;
        }
        .ant-modal-close{
            display: none;
        }
        .ant-modal-footer{
            display: none;
        }
    }
}