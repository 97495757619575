:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            padding: 0 16px;
            display: flex;
            flex-direction: column;

            >.header {
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: space-around;

                >.text_wrapper {
                    display: flex;
                    align-items: center;

                    >.title {
                        height: 12px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #001529;
                        line-height: 12px;
                    }

                    >.value {
                        height: 14px;
                        font-size: 14px;
                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                        font-weight: 500;
                        line-height: 14px;

                        &.value_1 {
                            color: #355B99;
                        }

                        &.value_2 {
                            color: #3AA372;
                        }

                        &.value_3 {
                            color: #F69A23;
                        }
                    }
                }
            }

            >.content {
                margin-top: 14px;
                height: 0;
                flex-grow: 1;
                display: flex;

                >.left {
                    width: 0;
                    flex-grow: 178;
                    display: flex;
                    flex-direction: column;

                    >.title {
                        margin-top: 2px;
                        height: 12px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 12px;
                        text-align: center;
                    }

                    >.chart_wrapper {
                        width: 145px;
                        height: 145px;
                        margin-top: 6px;
                        align-self: center;
                        padding: 4px;
                        position: relative;
                        overflow: hidden;

                        >.resize {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }

                        >.inner_wrapper {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            >.content {
                                width: 86px;
                                height: 86px;
                                background: #FFFFFF;
                                box-shadow: 0px 0px 11px 0px rgba(38, 71, 117, 0.2), 0px 1px 4px 0px rgba(38, 71, 117, 0.27);
                                border-radius: 50%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                >.title {
                                    margin-top: 17px;
                                    height: 14px;
                                    font-size: 12px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #081C2F;
                                    line-height: 14px;
                                }

                                >.value {
                                    margin-top: 2px;
                                    height: 28px;
                                    font-size: 28px;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #FB6060;
                                    line-height: 28px;
                                }

                                >.line {
                                    margin-top: 2px;
                                    width: 14px;
                                    height: 1px;
                                    background-color: #FB6060;
                                    border-radius: 1px;
                                }
                            }
                        }

                        >.chart {
                            height: 100%;
                            position: relative;
                        }
                    }

                    >.tool_tip {
                        margin-top: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        >.item {
                            display: flex;
                            align-items: center;

                            >.icon {
                                width: 22px;
                                height: 8px;
                                border-radius: 4px;
                                background-color: transparent;
                            }

                            >.text {
                                margin-left: 5px;
                                height: 12px;
                                font-size: 12px;
                                font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                                font-weight: 400;
                                color: #666666;
                                line-height: 12px;
                            }

                            &:nth-child(n+2) {
                                margin-left: 18px;
                            }
                        }
                    }
                }

                >.line_wrapper {
                    padding-bottom: 19px;

                    >.line {
                        height: 100%;
                        border-style: dashed;
                        border-color: #F0F0F0;
                        border-width: 0 0 0 1px;
                    }
                }

                >.right {
                    width: 0;
                    flex-grow: 242;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    >.title {
                        margin-top: 2px;
                        height: 12px;
                        font-size: 12px;
                        font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                        font-weight: 400;
                        color: #666666;
                        line-height: 12px;
                        text-align: center;
                    }

                    >.rank_wrapper {
                        margin-top: 15px;
                        width: 100%;

                        >.item {
                            height: 28px;
                            border-radius: 4px;
                            display: flex;
                            align-items: center;
                            padding-left: 8px;
                            padding-right: 12px;
                            font-size: 13px;
                            font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                            font-weight: 400;
                            line-height: 15px;

                            >.text {
                                height: 15px;
                                color: #333333;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            >.rate {
                                margin-left: auto;
                                height: 15px;
                                color: #666666;
                                white-space: nowrap;
                            }

                            &.odd {
                                background-color: #F8F8F8;
                            }
                        }
                    }
                }
            }
        }
    }
}