:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        >.card_group_row {
            height: 392px;
            display: flex;
            justify-content: space-between;
        }

        >.card_wrapper {
            height: 0;
            flex-grow: 1;
            margin-top: 24px;
        }
    }
}