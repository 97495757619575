:global {
    :local(.AbnormalPageWrapper) {
        height: 100%;
        padding: 24px 56px 0 72px;

        .AbnormalPage {
            height: 100%;
            padding: 24px;
            background-color: #FFF;
            // border-radius: 16px;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);

            .optLine {
                display: flex;
                justify-content: space-between;
            }

            .desp_table_td {
                padding: 16px;

                >.desp_text_wrapper {
                    width: 410px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .statusColor {
                width: 5px;
                height: 5px;
                border-radius: 10px;
                margin-right: 5px;
                flex-shrink: 0;
            }
        }
    }
}