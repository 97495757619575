:global {
    :local(.wrapper) {
        padding-top: 32px;
        margin-left: 72px;
        display: flex;

        :local(.tab_item) {
            display: flex;
            align-items: flex-end;

            .name {
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 14px;
            }

            .desc {
                margin-left: 12px;
                height: 12px;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 12px;
                position: relative;
                bottom: 1px;
            }
        }
    }
}