:global {
    :local(.tplSelectorWrapper) {
        .search-container {
            position: relative;
            display: flex;
            align-items: center;

            .search-icon {
                position: absolute;
                width: 32px;
                height: 32px;
                display: block;
                background: url('../../../../../../../../assets/svg/input_search.svg') no-repeat center;
            }

            .ant-select-selector {
                padding: 0 11px 0 43px;
                border-color: #375B98 !important;
                border-radius: 4px;

                .ant-select-selection-search {
                    left: 43px;
                }
            }
        }

        .tabs-container {
            list-style: none;
            padding: 0;
            margin: 16px 0 0;
            

            &>li {
                width: 96px;
                height: 36px;
                line-height: 36px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: rgba(0, 0, 0, 0.65);
                cursor: pointer;

                &>i{
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-color: red;
                    margin-right: 1px;
                    &.commonly{
                        background: url('../images/commonly.svg');
                    }
                    &.lately{
                        background: url('../images/lately.svg');
                    }
                    &.classify{
                        background: url('../images/classify.svg');
                    }
                }

                &.active {
                    color: #fff;
                    background-color: #375B98;
                    border-radius: 4px 4px 0px 0px;
                    .commonly{
                        background: url('../images/commonly_active.svg');
                    }
                    .lately{
                        background: url('../images/lately_active.svg');
                    }
                    .classify{
                        background: url('../images/classify_active.svg');
                    }
                }
            }
        }
        .tab-panel {
            padding: 16px 16px 8px;
            border-radius: 0px 0px 4px 4px;
            border: 1px solid #375B98;
            .classify-tag-list{
                list-style: none;
                padding: 0;
                margin: 0;
                margin-bottom: 16px;
                li{
                    display: inline-block;
                    padding: 0 12px;
                    height: 24px;
                    line-height: 24px;
                    cursor: pointer;
                    &.active{
                        border-bottom: 1px solid #375B98;
                    }
                }
            }

            .tpl-wrapper {
                list-style: none;
                padding: 0;
                margin: 0;
                .tpl-item{
                    display: inline-block;
                    height: 32px;
                    line-height: 30px;
                    margin-right: 8px;
                    margin-bottom: 8px;
                    padding: 0 12px;
                    border-radius: 16px;
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    border: 1px solid #D9D9D9;
                    cursor: pointer;
                    &:hover{
                        border-color: #375B98;
                        color: #375B98;
                    }
                }
            }
        }
    }
}