.loadRate {
  width: 325px;
  height: 305px;
  background: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0px 0px 7px  rgba(222,235,236,1);
}
.title {
  height: 37px;
  background: #F2F2F2;
  line-height: 37px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding-left: 12px;
  box-sizing: border-box;
  span {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #3A3A3A;
    line-height: 16px;
    text-shadow: 0px 0px 7px #DEEBEC;
  }
}
.wrapper {
    min-width: 132px;
    min-height: 131px;
}
