:global {
    :local(.wrapper) {
        height: 100%;
        background-color: #EDF7F8;
        position: relative;

        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 8px;
                background-color: rgba(0, 0, 0, 0);
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color:#357CF7, $alpha: 1);
                border-radius: 5px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
                background-color: #E9E9E9;
                border-radius: 5px;
            }
        }

        >.scroll_wrapper {
            height: 100%;
            overflow: auto;

            >.content {
                // 内部元素超出外部后是不管的
                height: 100%;
                min-height: 1080px;
                min-width: 1920px;
                display: flex;
                flex-direction: column;

                >.content {
                    height: 0;
                    flex-grow: 1;
                    overflow: hidden;
                    padding-bottom: 24px;
                }
            }
        }
    }
}