:global {
    :local(.exam_rule_config_wrapper) {
        height: 100%;
        padding: 24px 56px 0 72px;
        display: flex;
        flex-direction: column;

        .content_root_wrapper {
            height: 0;
            flex-grow: 1;
        }
    }
}