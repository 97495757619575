:global {
    :local(.wrapper) {
        height: 100%;
        background: #FFFFFF;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        padding: 16px 24px 24px 0;
        display: flex;
        flex-direction: column;

        >.icons_wrapper {
            padding-left: 16px;
            display: flex;
            align-items: center;

            >.icon {
                width: 12px;
                height: 16px;
            }

            >.icon_text {
                margin-left: 8px;
                width: 32px;
                height: 16px;
            }
            .filter-btn {
                display: inline-block;
                font-size: 16px;
                line-height: 16px;
                padding-left: 24px;
                font-family: 'Source Han Sans CN';
                font-weight: bold;
                color: #727272;
                background: url('../../../assets/svg/icon_filter.svg') no-repeat;
                margin-left: 28px;
                cursor: pointer;
            }
        }

        >.event_bar_comp_wrapper {
            margin-top: 16px;
        }

        >.content_wrapper {
            margin-top: 32px;
            height: 0;
            flex-grow: 1;
            display: flex;

            >.left {
                width: 448px;
            }

            >.right {
                width: 0;
                flex-grow: 1;
                padding-right: 24px;
            }
        }
    }
}