:global {
    :local(.wrapper) {
        width: 528px;

        >.header {
            height: 22px;
            display: flex;

            >.close_btn_wrapper {
                margin-left: auto;
                padding: 8px 8px 0 8px;
                cursor: pointer;
                display: flex;
                align-items: flex-start;

                >.icon {
                    width: 10px;
                    height: 8px;
                    user-select: none;
                }
            }
        }

        >.table_wrapper {
            >table {
                width: 100%;
                font-family: Source Han Sans CN;
                font-size: 12px;
                line-height: 12px;
                font-weight: 400;
                color: #3A3A3A;

                >tbody {
                    >tr {
                        >td {
                            padding: 0;
                            height: 28px;

                            >.key {
                                padding-left: 8px;

                                >.text {
                                    height: 12px;
                                }
                            }

                            >.time {
                                height: 12px;
                            }

                            >.content_wrapper {
                                display: flex;
                                padding-right: 8px;

                                >.text_wrapper {
                                    width: 0;
                                    flex-grow: 1;
                                    padding-right: 8px;

                                    >.text {
                                        height: 12px;
                                    }
                                }

                                >.tool_wrapper {
                                    width: 40px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    >.icon {
                                        width: 12px;
                                        height: 12px;
                                        user-select: none;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        &:hover {
                            background-color: #E7F4F3;
                        }
                    }
                }
            }
        }

        >.footer {
            height: 40px;
            padding-bottom: 4px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            >.cus_pagination_wrapper {
                padding-right: 8px;
            }
        }
    }
}