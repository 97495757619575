:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;

        >.left {
            width: 606px;
            padding: 0 0 0 104px;
            display: flex;
            flex-direction: column;

            >.title {
                height: 20px;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 20px;
                color: #3A3A3A;
                margin-bottom: 24px;
                margin-top: 20px;
            }

            >.content {
                height: 0;
                flex-grow: 1;
                padding-top: 86px;
            }
        }

        >.right {
            width: 0;
            flex-grow: 1;
            // padding: 0 16px;
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 0 104px 0 65px;

            >.tab_row {
                display: flex;
                align-items: flex-start;
                margin-bottom: 20px;
                margin-top: 8px;
                height: 58px;

                .item{
                    
                    height: 58px;
                    border: 1px solid #3A3A3A;
                    padding: 0 16px;
                    min-width: 128px;
                    .text{
                        color: #3a3a3a;
                        font-size: 25px;                       
                    }
                    &.selected {
                        border-color: #00676B;
                        background-color: #00676B;
                        .text{
                            color: #fff;
                        }
                    }
                    &:first-child {
                        border-top-left-radius: 16px;
                        border-bottom-left-radius: 16px;
                    }
                    &:last-child {
                        border-top-right-radius: 16px;
                        border-bottom-right-radius: 16px;
                    }
                    &:not(:first-child){
                        border-left: none;
                    }
                }
                
            }

            >.tab_row_absolute {
                position: absolute;
                top: -5px;
                right: 16px;
                transform: translateY(-100%);
            }

            >.content_wrapper {
                align-self: center;
                height: 0;
                flex-grow: 1;
                width: 100%;
                // max-width: 1472px;
            }
        }
    }
}