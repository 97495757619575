.newScreen {
  background: #1F2831;
  min-height: 1080px;
  // overflow-x: scroll;
  &-header {
    width: 1920px;
    height: 84px;
    background-image: url("../../assets/screen_header.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    &-left {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #A6B2C2;
      padding-left: 32px;
      padding-top: 24px;
      box-sizing: border-box;
    }
    &-right {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 34px;
      color: #A6B2C2;
      padding-top: 28px;
      padding-right: 32px;
      box-sizing: border-box;
      img {
        margin-left: 36px;
        margin-right: 8px;
        vertical-align: center;
        cursor: pointer;
      }
      span {
        vertical-align: middle;
      }
    }
  }
  &-content {
    padding: 12px 32px 32px 32px;
    box-sizing: border-box;
    display: flex;
    &-left {
      width: 456px;
      height: 952px;
      background: #13191F;
      box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 16px;
      padding: 16px;
      box-sizing: border-box;
      &-title {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #A6B2C2;
        opacity: 1;
        margin-bottom: 13px;
      }
    }
    &-center {
      margin: 0px 24px;
    }
  }
}
