:global {
    :local(.miniCapsuleDetail) {
        position: absolute;
        z-index: 1;
        right: 0;
        width: 500px;
        min-height: 200px;
        border-radius: 4px;
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
        background-color: #fff;
        transform: translate(508px, -8px);
        cursor: default;
        overflow: hidden;

        .header {
            height: 56px;
            background-color: #E2E4E7;
            line-height: 56px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 27px 0 20px;
            border-radius: 4px 4px 0 0;

            .title {
                font-size: 16px;               
                font-family: 'Source Han Sans CN';
                font-weight: bold;
                color: rgba(0, 0, 0, 0.85);
            }

            .header-right {
                display: inline-flex;
                align-items: center;

                &>div {
                    width: 24px;
                    height: 24px;
                    margin-left: 24px;
                    cursor: pointer;
                }

                .edit-icon {
                    background: url('../EditAndViewCapsuleModal/images/edit.png') no-repeat;
                }

                .enlarge-icon {
                    background: url('../EditAndViewCapsuleModal/images/scale_add.png') no-repeat;
                }

                .qrcode-icon {
                    background: url('../EditAndViewCapsuleModal/images/qrcode.png') no-repeat;
                }

                .close-icon {
                    background: url('../EditAndViewCapsuleModal/images/close.png') no-repeat;                    
                }
            }
            &::before {
                position: absolute;
                left: 0;
                content: '';
                display: inline-block;
                border-top: 4px solid transparent;
                border-left: 4px solid #375B98;
                border-bottom: 4px solid transparent;
                height: 24px;
                width: 4px;
            }
        }
        .content-top{
            flex-wrap: wrap;           
            div{
                white-space: nowrap;
            }
        }
        .content{
            border-bottom: none;
        }
    }
}