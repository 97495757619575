.ScriptEditor{
    margin-top: 4px;
    padding-bottom: 24px;
    padding-right: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    justify-content: flex-start;

    >.toolBar{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        >.leftTools{
            display: flex;
            flex-direction: row;
            align-items: center;
            .nameInput{
                margin-left: 10px;
            }
        }
        >.rightTools{
            .saveBtn{
                margin-right: 0px;
            }
        }
    }

    &>.downContent{
        margin-top: 20px;
        height: 100%;
        flex-grow: 1;
        overflow-y: auto;
        border: 1px solid #ccc;
        .scriptEditor{
            width: 100%;
            min-height: 100%;
            // height: 100%;
            // 此处不可设置height，设置后长文本显示异常
            flex-shrink: 0;
            flex-frow: 1;
            textarea:focus { 
                outline: none !important;
                // border-color: #719ECE;
                // box-shadow: 0 0 10px #719ECE;
            }
        }
    }
}
