.main_page_bottom_panels_energy_consumption_comp_wrapper {
    height: 100%;
    overflow-y: auto;

    .comp_content_wrapper {
        padding: 7px 15px 16px 8px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(182px, 1fr));

        .item_wrapper {
            // height: 92px;
            margin-left: 8px;
            margin-top: 8px;
            background-color: #191F27;
            border-radius: 2px;
            padding: 9px 8px 0 8px;
            display: flex;
            flex-direction: column;

            .item_title {
                position: relative;

                .text {
                    height: 14px;
                    font-size: 14px;
                    // font-weight: 500;
                    color: #BFC0DA;
                    line-height: 14px;
                    margin-left: 2px;
                }

                .title_icon {
                    position: absolute;
                    top: 50%;
                    left: -8px;
                    transform: translateY(-50%);
                    width: 2px;
                }
            }

            .rate_text {
                height: 12px;
                font-size: 12px;
                // font-weight: 400;
                color: #9FA0BA;
                line-height: 12px;
                text-align: right;
            }

            .values_wrapper {
                display: flex;
                align-items: flex-end;

                .value {
                    height: 20px;
                    font-size: 20px;
                    color: #FFFFFF;
                    line-height: 20px;
                }

                .unit {
                    margin-left: 2px;
                    height: 14px;
                    font-size: 14px;
                    // font-weight: 500;
                    color: rgba($color: #FFFFFF, $alpha: 0.5);
                    line-height: 14px;
                }

                .arrow {
                    margin-left: auto;
                    transform: translateY(-1px);

                    &.arrow_top {
                        width: 0;
                        height: 0;
                        border-width: 0 5px 8px 5px;
                        border-style: solid;
                        border-color: transparent transparent rgba(255, 97, 112, 1) transparent;
                    }

                    &.arrow_down {
                        width: 0;
                        height: 0;
                        border-width: 8px 5px 0 5px;
                        border-style: solid;
                        border-color: rgba(68, 243, 218, 1) transparent transparent transparent;
                    }
                }

                .rate {
                    margin-left: 4px;
                    height: 12px;
                    font-size: 12px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }
            }

            .item_aLine {
                margin-top: 6px;
                height: 1px;
                background: #3E405E;
            }

            .ave_wrapper {
                min-height: 30px;
                padding: 6px 0;
                display: flex;
                align-items: center;

                .ave_title {
                    // height: 12px;
                    font-size: 12px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;

                }

                .ave_value_wrapper {
                    margin-left: 8px;
                    height: 14px;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 14px;
                }
            }
        }
    }
}