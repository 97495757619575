:global {
    :local(.exam_analysis_left_viewer_wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;

        // 左侧视图-----------------------------

        .common_row_block {
            display: flex;

            .left_block {
                width: 164px;
                flex-shrink: 0;
            }

            .right_row_block {
                display: flex;
                align-items: center;

                .right_block_item {
                    // width: 0;
                    // flex-grow: 1;
                    display: flex;

                    &:nth-child(1) {
                        justify-content: flex-start;
                        width: 75px;
                        flex-shrink: 0;
                    }

                    &:nth-child(2) {
                        justify-content: flex-end;
                        // flex-grow: 100;
                        width: 100px;
                        flex-shrink: 0;
                    }

                    &:nth-child(3) {
                        margin-left: 60px;
                        flex-grow: 116;
                        flex-shrink: 116;
                    }

                    &:nth-child(4) {
                        flex-grow: 185;
                        flex-shrink: 185;
                    }

                    &:nth-child(n+3) {
                        justify-content: center;
                        width: 0;
                    }
                }
            }
        }

        .viewerTitle {
            display: flex;
            align-items: center;
            // padding-left: 80px;
            height: 48px;
            flex-shrink: 0;
            background-color: rgba(0, 0, 0, 0.03);
            border-radius: 2px;

            .right_block {
                width: 0;
                flex-grow: 1;

                .right_block_item {

                    &.right_block_item_rank {
                        font-size: 16px;
                        font-weight: bold;
                        color: #357CF7;
                        line-height: 16px;
                    }

                    &:not(.right_block_item_rank) {
                        font-size: 14px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 1);
                        line-height: 14px;
                        opacity: 0.84;
                    }
                }
            }
        }

        .leftTables {
            margin-top: 15px;
            height: 0;
            flex-grow: 1;
            overflow-y: auto;

            //滚动条背景
            &::-webkit-scrollbar {
                width: 10px;
                background-color: rgba(0, 0, 0, 0);
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: #dadada;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
                background-color: rgba(200, 200, 200, 0.1);
            }

            .sys_block_wrapper {
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
                border-radius: 2px;
                // align-items: center;

                &:nth-child(n+2) {
                    margin-top: 15px;
                }

                .left_block {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .sys_icon_name_wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        padding: 10px 0;

                        .img {
                            display: block;
                            width: 80px;
                            height: 80px;
                        }

                        .sys_name {
                            margin-top: 12px;
                            // height: 20px;
                            font-size: 14px;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 1);
                            line-height: 20px;
                            opacity: 0.84;
                            text-align: center;
                            max-width: 120px;

                            &[cusattribute=""] {
                                display: none;
                            }
                        }
                    }
                }

                .right_table_block {
                    width: 0;
                    flex-grow: 1;

                    .right_row_block {
                        height: 55px;
                        cursor: pointer;
                        //
                        user-select: none;
                        position: relative;

                        &:not(:last-child) {

                            &::after {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                left: -16px;
                                height: 1px;
                                background-color: rgba(0, 0, 0, 1);
                                opacity: 0.08;
                                border-radius: 2px;
                            }
                        }

                        .right_block_item_username {
                            display: flex;
                            align-items: center;

                            .user_icon {
                                width: 11px;
                                margin-right: 5px;
                            }
                        }

                        &:not(.selected_right_row_block) {
                            .right_block_item_username {
                                .user_icon {
                                    filter: grayscale(100%);
                                }
                            }
                        }
                    }

                    .selected_right_row_block {
                        // background-color: rgba(24, 144, 255, 0.03);

                        &::before {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            left: -16px;
                            background-color: rgba(24, 144, 255, 0.03);
                            border-right: solid 2px rgba(24, 144, 255, 1);
                            // width: 2px;
                            // background-color: rgba(24, 144, 255, 1);
                        }
                    }
                }
            }
        }
    }
}
