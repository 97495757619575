.ExcelWindow{
    // padding: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    >.toolBar{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        >.leftTools{
            display: flex;
            flex-direction: row;
            align-items: center;
            .backBtn{
                margin-left: 10px;
            }
            .readonlyHintWrapper{
                display: flex;
                flex-direction: row;
                align-items: center;
                .readonlyHint{
                    color: #1890ff;
                    margin-left: 20px;
                    font-size: 16px;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .qMarkIcon{
                    margin-left: 4px;
                    font-size: 17px;
                    color: #1890ff;
                    cursor: pointer;
                }
            }
        }
        >.rightTools{
            .saveBtn{
                margin-right: 10px;
            }
            .delBtn{
                margin-right: 0px;
            }
            .covertBtn{
                margin-right: 10px;
            }
        }
    }

    &>.downContent{
        display: flex;
        flex-grow: 1;
        // padding-top: 8px;
        // padding-bottom: 24px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        overflow-y: auto;
        position: relative;

        .ant-spin-nested-loading,
        .ant-spin-container{
            width: 100%;
            // 设置position: absolute;以及绝对位置，并且父div设置position: relative; 避免高度不被撑起。
            // Ref: https://stackoverflow.com/questions/33636796/chrome-safari-not-filling-100-height-of-flex-parent
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // height: 100%;
        }
    }

    .ScriptModal{
        box-shadow: 3px 3px 5px #888888;
        // .modalToolBar{
        //     height: 50px;
        //     width: 100%;
        //     position: absolute;
        //     top: 0px;
        //     left: 0px;
        //     z-index: 1;
        //     background-color: #ccc;
        // }
        // .saveScriptBtn{
        //     position: absolute;
        //     top: 8px;
        //     right: 10px;
        //     z-index: 1;
        // }
        // .runScriptBtn{
        //     position: absolute;
        //     top: 8px;
        //     right: 75px;
        //     z-index: 1;
        // }
        .scriptModalContent{
            display: flex;
            width: 100%;
            height: 100%;
            padding-top: 60px;
            padding-left: 10px;
            padding-right: 10px;
            .modalTitle{
                position: absolute;
                top: 12px;
                left: 0px;
                /* z-index: 1; */
                color: rgba(0,0,0,.85);
                font-size: 16px;
                width: 100%;
                text-align: center;
                letter-spacing: 1px;
                font-weight: 600;
                font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            }
            .closeBtn{
                position: absolute;
                top: 0px;
                right: 0px;
                z-index: 1;
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &:hover{
                    color: #00b8ff;
                }
            }
        }
    }
    .flexible-modal {
        position: absolute;
        z-index: 1;
        border: 1.5px solid #ccc;
        background: white;
    }
    .flexible-modal-mask {
        display: none;
        position: fixed;
        height: 100%;
        background: rgba(55, 55, 55, 0.4);
        top:0;
        left:0;
        right:0;
        bottom:0;
    }
    .flexible-modal-resizer {
        position:absolute;
        right:0;
        bottom:0;
        cursor:se-resize;
        margin:5px;
        border-bottom: solid 2px #333;
        border-right: solid 2px #333;
    }
    .flexible-modal-drag-area{
        // background: #ccc;
        border-bottom: 1px solid #b0b0b0;
        height: 50px;
        position:absolute;
        right:0;
        top:0;
        cursor:move;
    }
    .flexible-modal-drag-area-left,
    .flexible-modal-drag-area-right{
        display: none;
    }
}

.convertModal{
    span{
        font-weight: bold;
    }
}