:global {
    :local(.wrapper) {
        height: 100%;
        background: #FFFFFF;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        // padding: 16px 16px 48px 16px;

        >.title {
            display: flex;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 16px;
            color: #357CF7;
            border-bottom: 1px solid #c9c9c9;
            padding: 20px 16px;
            img{
                margin-right: 8px;
            }
        }

        >.value_wrapper {
            margin-top: 65px;
            align-self: center;
            display: flex;
            align-items: flex-end;

            >.value {
                height: 30px;
                font-size: 30px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 30px;
                color: #355B99;
            }

            >.unit {
                margin-left: 4px;
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 14px;
                color: #355B99;
                position: relative;
                bottom: 2px;
            }
        }

        >.img {
            width: 176px;
            height: 40px;
            align-self: center;
            margin-top: 48px;
        }
    }
}