:global {
    :local(.board_table_wrapper) {
        height: 100%;
        padding: 16px 0 16px 0;

        .ant-table-wrapper {
            height: 100%;

            .ant-spin-nested-loading {
                height: 100%;

                .ant-spin-container {
                    height: 100%;

                    .ant-table.ant-table-fixed-header {
                        height: 100%;

                        .ant-table-container {
                            height: 100%;
                            display: flex;
                            flex-direction: column;

                            .ant-table-header {
                                flex-shrink: 0;
                            }
                        }
                    }
                }
            }
        }

        .ant-table-cell-scrollbar {
            opacity: 0;
        }

        .first_column_td {
            border-width: 0;
        }

        th.first_column_td {
            opacity: 0;
        }

        .lock_td {
            padding-left: 0;
            padding-right: 0;

            .lock_wrapper {
                cursor: pointer;
                display: inline-block;

                >img {
                    display: inline-block;
                    width: 18px;
                }
            }
        }

        .table_title {
            text-align: center;
            font-size: 9px;
            color: rgba(0, 0, 0, 1);
        }

        .table_title_corr {
            display: flex;
            align-items: center;
            cursor: pointer;
            user-select: none;
            justify-content: center;

            .sort_wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-left: 5px;

                .top,
                .bottom {
                    width: 0;
                    height: 0;
                    border-color: transparent;
                    border-style: solid;
                }

                .top {
                    border-width: 0 3px 5px 3px;
                    border-color: transparent transparent rgba(216, 216, 216, 1) transparent;
                }

                .bottom {
                    margin-top: 2px;
                    border-width: 5px 3px 0 3px;
                    border-color: rgba(216, 216, 216, 1) transparent transparent transparent;
                }
            }
        }

        .corr_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .corr_block {
                width: 14px;
                height: 5px;
                background: rgba(240, 242, 245, 1);
                border-radius: 2px;

                &:nth-child(n+2) {
                    margin-left: 2px;
                }
            }

            .colored_corr_block {
                background: rgba(24, 144, 255, 1);
            }
        }

        .cus_table_name-cell,
        .curr-value-cell,
        .rcmd_range-cell {
            padding-left: 3px;
            padding-right: 3px;
        }

        .cus_table_name-cell {

            .cus_table_name {
                cursor: pointer;
                opacity: 0.65;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .curr-value-cell {
            .curr_value_wrapper {
                height: 20px;
                font-size: 16px;
                font-weight: 500;
                color: #1890FF;
                line-height: 20px;
            }
        }

        .rcmd_range-cell {
            opacity: 0.65;
        }

        .mainpage_range_block_wrapper {
            cursor: pointer;
            padding: 4px 0;
        }

        .move_td {
            padding-left: 0;
            padding-right: 0;
            border-width: 0;
            //上下居中???

            .move_icon_wrapper {
                cursor: move;
                width: 20px;
                height: 20px;
                background: rgba(235, 240, 255, 1);
                border-radius: 2px;
                transform-origin: center center;
                transform: rotate(90deg);
                // display: none;
                // display: flex;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;

                .move_icon {
                    font-size: 10px;
                }
            }
        }

        th.move_td {
            opacity: 0;
        }

        .cus_table_wrapper_in_board_table {
            thead {
                tr {
                    >.ant-table-cell {

                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6),
                        &:nth-child(7) {
                            background-color: #F5F5F5;
                            opacity: 1;
                        }

                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5),
                        &:nth-child(6) {
                            border-width: 1px 0 1px 0;
                        }

                        &:nth-child(2) {
                            border-width: 1px 0 1px 1px;
                            border-top-left-radius: 2px;
                        }

                        &:nth-child(7) {
                            border-width: 1px 1px 1px 0;
                            border-top-right-radius: 2px;
                        }
                    }
                }
            }

            tbody {
                >tr {
                    >td {
                        border-style: solid;
                        border-color: transparent;
                        border-width: 1px 0;
                        font-size: 12px;
                        color: rgba(0, 0, 0, 1);
                    }
                }

                >tr:hover {

                    // drag后hover未消除???
                    >td {
                        border-color: #1890FF;
                        border-style: solid;
                        border-width: 1px 0;

                        &:first-child {
                            border-width: 1px 0 1px 1px;
                        }

                        &:last-child {
                            border-width: 1px 1px 1px 0;
                        }

                        background-color: transparent; //这个好像没用
                    }

                    &:last-child {
                        >td {
                            border-color: transparent;
                        }
                    }

                    .move_icon_wrapper {
                        // display: flex;
                        opacity: 1;
                    }
                }

                //一定要写在hover后面用来覆盖hover的antd默认背景色
                >tr.grey_row {
                    >td {
                        background-color: rgba($color: #000000, $alpha: 0.02);

                        &:first-child,
                        &:last-child {
                            background-color: transparent;
                        }
                    }
                }

                >tr.dragover_row {
                    >td {
                        border-color: red transparent transparent transparent;
                        border-style: dashed;
                        border-width: 1px 0;
                    }
                }
            }
        }
    }
}