:global {
    :local(.board_second_wrapper) {
        height: 228px;
        flex-shrink: 0;
        background: #FFFFFF;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        display: flex;
        flex-direction: column;

        .board_top {
            flex-grow: 1;
            height: 0;
            padding: 16px 24px;
            display: flex;
            flex-wrap: wrap;

            .board_block_wrapper {
                // width: 50%;
                display: flex;
                align-items: center;

                &:nth-child(2n-1) {
                    width: 55%;
                }

                &:nth-child(2n) {
                    width: 45%;
                }

                .img_wrapper {
                    width: 50px;
                    height: 50px;
                    background: rgba(235, 240, 255, 1);
                    border-radius: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >img {
                        display: block;
                    }
                }

                .text_wrapper {
                    margin-left: 10px;

                    .title {
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(36, 36, 36, 1);
                        line-height: 20px;
                        opacity: 0.65;
                    }

                    .value_wrapper {
                        display: flex;
                        align-items: flex-end;
                        opacity: 0.85;

                        .value {
                            height: 38px;
                            font-size: 30px;
                            color: #357CF7;
                            line-height: 38px;
                        }

                        .unit {
                            height: 20px;
                            font-size: 12px;
                            color: #357CF7;
                            line-height: 20px;
                            margin-left: 3px;
                        }
                    }
                }

                // .start_opt_wrapper {
                //     cursor: pointer;
                //     user-select: none;
                //     margin-left: 10px;
                //     width: 88px;
                //     height: 32px;
                //     background: rgba(24, 144, 255, 1);
                //     border-radius: 16px;
                //     display: flex;
                //     align-items: center;
                //     justify-content: center;
                //     font-size: 14px;
                //     font-weight: 500;
                //     color: rgba(255, 255, 255, 1);
                //     line-height: 22px;
                // }
            }
        }

        .board_bottom {
            height: 46px;
            flex-shrink: 0;
            border-top: 1px solid rgba(230, 230, 230, 1);
            padding: 0 26px 0 24px;
            display: flex;
            align-items: center;

            .icon {
                font-size: 14px;
                color: #357CF7;
            }

            .result_text {
                width: 62px;
                margin-left: 8px;
                font-size: 14px;
                color: #357CF7;
                font-weight: 500;
                height: 14px;
                line-height: 14px;
            }

            .time_text {
                margin-left: 10px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: #595959;
                line-height: 14px;
            }

            .setting_wrapper {
                margin-left: auto;
                display: flex;
                align-items: center;
                cursor: pointer;
                user-select: none;

                .setting_icon {
                    display: block;
                    width: 18px;
                    height: 18px;
                }

                .setting_text {
                    margin-left: 4px;
                    height: 14px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #357CF7;
                    line-height: 14px;
                }
            }
        }
    }
}