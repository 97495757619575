:global {
    :local(.wrapper) {
        height: 100%;
        padding: 29px 72px 0 72px;
        display: flex;

        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 8px;
                background-color: rgba(0, 0, 0, 0);
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color:#357CF7, $alpha: 1);
                border-radius: 5px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
                background-color: #E9E9E9;
                border-radius: 5px;
            }
        }

        >.left {
            width: 415px;
            display: flex;
            flex-direction: column;
            padding-top: 12px;

            >.title {
                height: 20px;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 20px;
                color: #3A3A3A;
            }

            >.body {
                height: 0;
                flex-grow: 1;
                margin-top: 24px;
                background: #FFFFFF;
                // box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
                border-radius: 16px;
            }
        }

        >.right {
            width: 0;
            flex-grow: 1;
            margin-left: 24px;
            display: flex;
            flex-direction: column;

            >.dialog_panel_wrapper {
                margin-top: 24px;
                height: 0;
                flex-grow: 1;
            }
        }
    }
}