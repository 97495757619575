:global {
    :local(.wrapper) {
        width: 152px;
        height: 88px;
        background-color: #F1F1F1;
        border-radius: 4px;
        padding: 16px 3px 16px 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 16px;

        >.value_wrapper {
            display: flex;
            align-items: flex-end;
            color: #333;

            >.value {
                height: 28px;
                font-size: 28px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 28px;
            }

            >.unit {
                margin-left: 4px;
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 14px;
            }
        }

        >.name {
            height: 14px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 14px;
            color: #333;
        }
    }
}