.mediaCard {
  width: 152px;
  height: 72px;
  border-radius: 10px;
  font-size: 38px;
  font-family: MicrosoftYaHei;
  background: #AFDAD8;
  color: #015959;
  line-height: 50px;
  text-shadow: 0px 0px 7px #DEEBEC;
  padding: 11px 21px;
  box-sizing: border-box;
  text-align: center;
}

