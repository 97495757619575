.login-form-forgot {
  float: right;
}

.login-form-button {
  width: 100%;
  height: 40px;
  background-color: #357CF7;

  &:hover {
    background-color: #357CF7;
  }
}

#loginFormInnerWrapper {
  width: 384px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 176px;

  .titleText {
    margin-bottom: 22px;
    white-space: nowrap;

    height: 26px;
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 26px;
    color: #3A3A3A;
  }
}

#loginFormOuterWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}