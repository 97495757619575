:global {
    :local(.detail_rank_block_wrapper) {

        .row {
            display: flex;
            align-items: center;
            padding: 12px 0;

            .row_left {
                width: 100px;
                text-align: right;
            }

            .row_right {
                width: 120px;
                padding-left: 10px;
                justify-content: space-between;
                display: flex;
                align-items: center;
            }
        }

        .add_icon_wrapper {
            padding-left: 50px;
        }
    }
}