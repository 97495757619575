:global {
    :local(.exam_rules_content_wrapper) {
        height: 100%;
        background: rgba(255, 255, 255, 1);
        // border-radius: 16px;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);

        .content_wrapper {
            height: 100%;
            padding: 24px;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                align-items: center;
                padding-bottom: 24px;

                .time_link {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.65);
                    line-height: 22px;
                    margin: 0 4px;
                }
            }

            .content_table_wrapper {
                flex-grow: 1;
                flex-basis: 0;
                position: relative;

                .table_wrapper_area {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    .ant-table-wrapper {
                        height: 100%;

                        .ant-spin-nested-loading {
                            height: 100%;

                            .ant-spin-container {
                                height: 100%;

                                .ant-table.ant-table-fixed-header {
                                    height: 100%;

                                    .ant-table-container {
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;

                                        .ant-table-header {
                                            flex-shrink: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}