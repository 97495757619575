.tpl {
  width: 912px;
//   height: 392px;
  background: #EDF7F8;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  padding: 10px 16px;
  box-sizing: border-box;
  img {
    vertical-align: middle;
  }
  .ant-input {
    border-radius: 4px;
    border: 1px solid #AFDAD8;
  }
  &-title {
    margin-bottom: 8px;
  }
  &-operator {
    display: flex;
    justify-content: space-between;
    &-left {
      .left {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 27px;
        color: #727272;
        opacity: 1;
        margin-right: 8px;
      }
      .center {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: #727272;
        opacity: 1;
        margin-right: 32px;
      }
      .right {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 27px;
        color: #727272;
        opacity: 1;
      }
    }
    &-right {
      .ant-select{
        width: 72px;
         margin-right: 18px;
         font-size: 12px; 
         .ant-select-selector{
            padding: 0 8px;
            .ant-select-selection-item{
                padding-right: 12px;
            }
        }
      }
      
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: #357CF7;
        color: #FFFFFF;
        border-radius: 4px;
      }
      .reset-icon{
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url('../../../assets/images/v3/reset_icon.png') no-repeat center;
          vertical-align: middle;
      }
      .reset {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 24px;
        color: #357CF7;
        opacity: 1;
        margin-left: 4px;
        vertical-align: middle;        
      }
    }
  }
  &-input {
    margin-top: 24px;
    margin-bottom: 18px;
  }
  &-info {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 24px;
    color: #3A3A3A;
    opacity: 1;
  }
  &-textarea {

  }
  &-line {
    width: 880px;
    height: 1px;
    background: #FFFFFF;
    margin: 16px 0px;
  }
  &-point {
    display: flex;
    .point {
      height: 32px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #3A3A3A;
      opacity: 1;
      line-height: 32px;
      margin-right: 20px;
    }
  }
  &-fa {
    display: flex;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #AFDAD8;
      border-radius: 4px;
    }
    &-name {
      min-width: 30px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 32px;
      color: #3A3A3A;
      opacity: 1;
      margin-right: 18px;
    }
    &-unit {
      margin-left: 5px;
      line-height: 32px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #3A3A3A;
    }
  }
  &-capacity {
    display: flex;
  }
  ul {
    padding-left: 0;
    display: flex;
    li {
      list-style: none;
      display: flex;
      flex-direction: column;
    }
  }
  .firstUl {
    li {
      margin-right: 12px;
      span {
        display: inline-block;
        margin-bottom: 19px;
        width: 56px;
        text-align: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3A3A3A;
      }
    }
  }
  .fiveUl {
    li {
      margin-right: 16px;
      .top {
        margin-bottom: 19px;
        text-align: center;
      }
      .ant-checkbox {
        border: 1px solid #AFDAD8;
        border-radius: 4px;
      }
    }
  }
  .float-input{
    font-size: 12px;
    width: 56px;
    height: 24px;
    padding: 4px;
    font-family: 'Source Han Sans CN';
  }
}
