:global {
    :local(.wrapper) {
        height: 44px;
        display: flex;
        align-items: center;
        padding-right: 24px;

        >.tabs_wrapper {
            margin-left: 45px;
        }

        >.right {
            margin-left: auto;
        }
    }
}