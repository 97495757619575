:global {
    :local(.wrapper) {
        >.content {
            height: 76px;
            border-radius: 8px;
            background-color: #F8F8F8;
            display: flex;
            align-items: center;

            >.left {
                width: 0;
                flex-grow: 325;
                padding-left: 8px;

                >.row {
                    display: flex;

                    >.block_1 {
                        width: 0;
                        flex-grow: 203;
                        display: flex;
                        align-items: center;
                        height: 14px;

                        >.text_1 {
                            height: 100%;
                            font-size: 14px;
                            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                            font-weight: 400;
                            color: #546677;
                            line-height: 14px;
                        }

                        >.title {
                            width: 70px;
                            height: 12px;
                            font-size: 12px;
                            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                            font-weight: 400;
                            color: #999999;
                            line-height: 12px;
                        }

                        >.bar {
                            width: 0;
                            flex-grow: 1;
                            height: 6px;
                            background-color: #EFEFEF;
                            border-radius: 4px;

                            >.bar_value {
                                width: 0;
                                height: 100%;
                                background-color: #355B99;
                                border-radius: 4px;
                            }
                        }

                        >.value {
                            width: 39px;
                            text-align: right;
                            height: 12px;
                            font-size: 12px;
                            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                            font-weight: 400;
                            color: #355B99;
                            line-height: 12px;

                            &.red {
                                color: #FB6060;
                            }
                        }
                    }

                    >.block_2 {
                        width: 0;
                        flex-grow: 114;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 14px;
                        font-weight: 400;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;

                        >.arrow {
                            width: 0;
                            height: 0;
                            border-style: solid;
                            margin-right: 4px;

                            &.up {
                                border-width: 0 4px 8px 4px;
                                border-color: transparent transparent #546677 transparent;
                            }

                            &.down {
                                border-width: 8px 4px 0 4px;
                                border-color: #546677 transparent transparent transparent;
                            }
                        }

                        >.text {
                            height: 100%;
                            font-size: 14px;
                            color: #546677;
                            line-height: 14px;
                        }

                        >.text_2 {
                            height: 12px;
                            font-size: 12px;
                            color: #355B99;
                            line-height: 12px;
                        }

                        &.green {
                            >.text_2 {
                                color: green;
                            }

                            >.arrow {

                                &.up {
                                    border-width: 0 4px 8px 4px;
                                    border-color: transparent transparent green transparent;
                                }

                                &.down {
                                    border-width: 8px 4px 0 4px;
                                    border-color: green transparent transparent transparent;
                                }
                            }
                        }

                        &.red {
                            >.text_2 {
                                color: red;
                            }

                            >.arrow {

                                &.up {
                                    border-width: 0 4px 8px 4px;
                                    border-color: transparent transparent red transparent;
                                }

                                &.down {
                                    border-width: 8px 4px 0 4px;
                                    border-color: red transparent transparent transparent;
                                }
                            }
                        }
                    }

                    &:nth-child(n+2) {
                        margin-top: 13px;
                    }
                }
            }

            >.right {
                width: 0;
                flex-grow: 123;

                >.content {
                    padding-right: 8px;

                    >.chart_wrapper {
                        height: 64px;
                        padding: 0 6px;

                        >.chart {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}