:global {
    :local(.sample_maintenance_right_block_wrapper) {
        height: 100%;

        .block_header {
            height: 48px;
            display: flex;
            position: relative;

            .text {
                height: 22px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                line-height: 22px;
            }

            .title_block {
                width: 109px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                display: flex;
                align-items: center;
            }

            .items_wrapper {
                width: 0;
                flex-grow: 1;
                display: flex;

                .item_block {
                    width: 0;
                    flex-grow: 1;
                    position: relative;

                    .text {
                        position: absolute;
                        top: 12px;
                        left: 0;
                        transform: translateX(-50%);
                    }
                }
            }

            .out_text {
                position: absolute;
                top: 12px;
                right: 0;
                transform: translateX(50%);
            }
        }

        .row_block {
            display: flex;

            .block_title {
                width: 109px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #EBEBEB;
            }

            .block_title_first {
                justify-content: flex-start;
                font-size: 14px;
                font-weight: 500;
                color: #1890FF;
                padding-left: 35px;
            }

            .block_title_1 {
                cursor: pointer;
                user-select: none;

                .text {
                    margin-left: 8px;
                    height: 22px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #1890FF;
                    line-height: 22px;
                }
            }

            .block_title_2 {
                font-size: 14px;
                font-weight: 400;
                color: rgba(102, 102, 102, 1);
            }

            .items_wrapper {
                width: 0;
                flex-grow: 1;
                display: flex;
                position: relative;

                .block_item {
                    width: 0;
                    flex-grow: 1;
                    border-right: 1px solid #EBEBEB;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .circle {
                        width: 16px;
                        height: 16px;
                        border: 1px solid #C9C9C9;
                        border-radius: 50%;
                        cursor: pointer;
                    }

                    .circle_checked {
                        border-color: #1890FF;
                        position: relative;

                        &::after {
                            position: absolute;
                            content: '';
                            width: 10px;
                            height: 10px;
                            background-color: #1890FF;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                        }
                    }
                }

                .display_wrapper {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;

                    .event_item {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 100%;
                        padding: 8px 0 0;

                        .name {
                            height: 14px;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .time {
                            margin-top: 6px;
                            height: 12px;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 12px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .chart_wrapper {
                        height: 100%;
                    }
                }
            }
        }

        .row_block_item {
            height: 48px;
            background: rgba(249, 249, 249, 1);
        }

        .row_block_chart {
            height: 62px;
            background: rgba(249, 249, 249, 1);
        }

        .row_block_space {
            height: 24px;
        }

        .row_block_events_bottom {
            height: 24px;
            background: rgba(249, 249, 249, 1);
        }
    }
}