:global {
    :local(.wrapper) {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 32px 0 48px;

        >.content {
            height: 0;
            flex-grow: 1;
        }
    }
}