:global {
    :local(.pointLineWrapper) {
        width: 100%;
        padding: 16px;
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 4px;
        margin-top: 16px;

        .operation {
            display: flex;
            position: relative;
            justify-content: center;

            .switch-group {
                div {
                    width: 72px;
                    line-height: 24px;
                    background-color: #fff;
                    font-size: 12px;
                    color: #333;
                    display: inline-block;
                    border: 1px solid #D5D5D5;
                    text-align: center;
                    cursor: pointer;

                    &:first-child {
                        border-radius: 12px 0 0 12px;
                        border-right: none;
                    }

                    &:last-child {
                        border-radius: 0 12px 12px 0;
                        border-left: none;
                    }

                    &.active {
                        border-color: #375B98;
                        color: #fff;
                        background-color: #375B98;
                    }
                }
            }

            .filter-switch {
                position: absolute;
                top: 0;
                right: 16px;
                
                .filter-icon{
                    color: #1890FF;
                    text-indent: 20px;
                    font-size: 12px;
                    line-height: 24px;
                    background: url('../../../../../../../../../assets/svg/icon_filter_blue.svg') no-repeat left;
                    cursor: pointer;
                    user-select: none;
                }
                .filter-options {
                    position: absolute;
                    right: -16px;
                    background-color: #fff;
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    padding: 8px;
                    display: flex;
                    flex-direction: column;
                    z-index: 1;
    
                    .ant-checkbox-wrapper {
                        white-space: nowrap;
    
                        &:not(:last-child) {
                            margin-bottom: 16px;
                        }
                    }
    
                    .ant-checkbox+span {
                        display: inline-block;
                        width: 160px;
                        padding-right: 8px;
                        padding-left: 8px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        vertical-align: middle;
                        white-space: nowrap;
                        text-indent: 0;
                    }
                }
            }
        }

        .chart-wrapper{
            .chart-item{
                display: flex;
                margin: 20px 0;
                .chart-left{
                    width: 124px;
                    height: 88px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding-right: 4px;
                    .title{
                        width: 100%;
                        font-weight: 500;
                        font-size: 12px;
                        color: #333;
                    }
                }
            }
        }

        

    }

    :local(.pointFilter) {
        width: 200px;
        min-width: 200px !important;

        .ant-checkbox-group {
            background-color: #fff;           
            border-radius: 4px;           
            display: flex;
            flex-direction: column;

            .ant-checkbox-wrapper {
                white-space: nowrap;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }

            .ant-checkbox+span {
                display: inline-block;
                width: 160px;
                padding-right: 8px;
                padding-left: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
                white-space: nowrap;
                text-indent: 0;
            }
        }
    }
}