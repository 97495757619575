$template-color:#357CF7;

.report-modal {
    .ant-modal {
        width: auto;
    }

    .ant-modal-close-x {
        width: 48px;
        height: 40px;
        line-height: 40px;
        color: #3A3A3A;
        font-size: 22px;
    }
    .ant-modal-content{
        border-radius: 16px;
    }

    .ant-modal-body {
        min-height: 456px;
        padding-top: 40px;
        padding: 40px 16px 0;
        border-radius: 16px 16px 0 0;
    }

    .toolbar {
        position: absolute;
        top: 0;
        left: 0;
        height: 40px;
        line-height: 40px;
        width: 100%;
        display: flex;
        background-color: #F1F1F1;
        padding-left: 16px;
        border-radius: 16px 16px 0 0;

        .date-info {
            min-width: 100px;
            margin-right: 10px;
            
        }

        .shift-btn {
            display: flex;
            align-items: center;
            i {
                width: 5px;
                height: 10px;
                display: inline-block;
                cursor: pointer;
            }
        }

        .right-arrow {
            background: url('../../../../assets/images/right_arrow.png') no-repeat center;
            margin-left: 9px;
        }

        .left-arrow {
            background: url('../../../../assets/images/left_arrow.png') no-repeat center;
            margin-right: 9px;
        }
    }

    .full-screen-btn {
        position: absolute;
        top: 0;
        display: block;
        right: 56px;
        line-height: 56px;
        cursor: pointer;
    }

    .title {
        color: #727272;
        font-size: 16px;
        line-height: 56px;
    }

    .reportForm {
        min-height: 360px;
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: auto hidden;

        .table-head {
            display: flex;
            align-items: center;
            border-radius: 8px 8px 0px 0px;
            display: -webkit-box;

            &>div {
                flex: 1;
                font-family: 'Source Han Sans CN';
                font-size: 12px;
                line-height: 16px;
                color: #3A3A3A;
                min-height: 40px;
                padding: 8px 10px;
                width: 100px;
                min-width: 100px;
                display: flex;
                align-items: center;
                white-space: pre-wrap;
                word-break: break-all;
                background-color: #AFDAD8;

                &:first-child {
                    width: 88px;
                    min-width: 88px;
                    max-width: 88px;
                    border-top-left-radius: 8px;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        .table-row {
            flex: 1;

            .row-item {
                width: 100%;
                height: 40px;
                display: inline-flex;
                margin-bottom: 0px;         

                &>div {
                    flex: 1;
                    color: #3A3A3A;
                    font-size: 12px;
                    width: 100px;
                    min-width: 100px;
                    padding: 0 10px;
                    display: inline-flex;
                    line-height: 32px;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 0;
                    border-bottom: 1px dashed #AFDAD8;

                    &:first-child {
                        width: 88px;
                        min-width: 88px;
                        max-width: 88px;
                    }
                }
                &:last-child>div{
                    border-bottom: 1px solid #AFDAD8;
                }
            }
        }

        .highlight-row>div{
            background-color: rgba(175, 218, 216, 0.3);
        }

        .ant-form-item {
            position: relative;

            input {
                border-color: #AFDAD8;
                height: 24px;
                border-radius: 4px;

                &:focus {
                    box-shadow: 0 0 0 2px rgba(0, 103, 107, 0.2);
                }
            }

            .error-msg {
                visibility: hidden;
                position: absolute;
                font-size: 12px;
                line-height: 14px;
                color: red;
                white-space: nowrap;

                &.is-error {
                    visibility: visible;
                }
            }
        }
    }

    .ant-modal-footer {
        border-top: none;
        padding: 16px;
        button {
            border-radius: 4px;
            &:not(.ant-btn-primary){
                float: right;
                margin-left: 16px;
            }
            &:hover {
                color: $template-color;
                border-color: $template-color;
            }

            &.ant-btn-primary {
                background-color: $template-color;
                border-color: $template-color;

                &:hover {
                    color: #fff;
                    background-color: rgba(0, 103, 107, 0.8);
                    border-color: rgba(0, 103, 107, 0.8);
                }
            }
        }
    }
}