.edit_report_vaQ3t {

    .search_meta_wrapper {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 10px;
            background-color: transparent;
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: #343D47;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            // box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: #212830;
        }
    }

    .selected_meta_wrapper {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 10px;
            background-color: transparent;
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: #212830;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            // box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: #343D47;
        }
    }

    .selected_meta_item {
        border-width: 1px;
        border-style: solid;

        &:not(:hover) {
            border-color: #212830;

            .icon_del {
                display: none;
            }
        }

        &:hover {
            border-color: rgba(68, 242, 217, 0.6);
        }
    }
}