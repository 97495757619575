.factor_summary_v2_single_factor_detail_custom_factor_panel_wrapper {
    .row {
        .icon {
            display: none;
        }

        &:hover {
            .icon {
                display: block;
            }
        }
    }
}