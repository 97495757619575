:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            padding: 21px 0 16px 16px;
            display: flex;
            flex-direction: column;

            >.resize {
                height: 0;
            }

            >.inner_wrapper {
                height: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                >.mid {
                    overflow-y: auto;
                    overflow-x: hidden;
                }
            }
        }
    }

    :local(.table) {
        border-collapse: separate;
        border-spacing: 0;
        // default 0,不设置，空的table有4px高度???
        // table-layout: fixed;
        text-align: center;

        >thead {
            >tr {
                height: 38px;

                >th {
                    padding: 0;
                    background-color: #F5F5F5;
                    border-style: solid;
                    border-color: #E6E6E6;
                    border-width: 1px 0 0 0;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Medium;
                    line-height: 12px;
                    color: rgba(51, 51, 51, 0.85);
                    font-weight: normal;
                    position: sticky;
                    top: 0;
                    z-index: 1;

                    &:first-child {
                        border-width: 1px 0 0 1px;
                        border-top-left-radius: 2px;
                    }

                    &:last-child {
                        border-width: 1px 1px 0 0;
                        border-top-right-radius: 2px;
                    }

                    &.name {
                        width: 136px;
                    }

                    &.sort {
                        >.wrapper {
                            display: inline-block;
                            user-select: none;
                            cursor: pointer;

                            &:hover {
                                opacity: 0.8;
                            }

                            >.content {
                                display: flex;
                                // 这里不用inline-block可以少一层嵌套
                                // 然而这样和hover opacity冲突了
                                // 还是需要多一层，包裹点击事件
                                align-items: center;
                                justify-content: center;

                                >.text {
                                    margin-right: 4px;
                                }
                            }
                        }
                    }

                    &.bar {
                        width: 416px;

                        >.wrapper {
                            display: flex;
                            justify-content: space-between;

                            >.item {
                                width: 0;
                                position: relative;

                                >.text {
                                    white-space: nowrap;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }

                                &.bold {
                                    font-weight: bold;
                                    font-family: Source Han Sans CN;
                                }

                                &:first-child {
                                    >.text {
                                        transform: translate(0, -50%);
                                    }
                                }

                                &:last-child {
                                    >.text {
                                        transform: translate(-100%, -50%);
                                    }
                                }
                            }
                        }
                    }

                    &.shift {
                        // width: 144px;
                        border-width: 1px 0 0 1px;

                        &.last {
                            border-width: 1px 1px 0 1px;
                        }

                        >.wrapper {
                            >.name {
                                font-weight: bold;
                                font-family: Source Han Sans CN;
                            }

                            >.subtitle {
                                margin-top: 2px;
                                color: rgba(102, 102, 102, 0.85);
                                color: #666666;
                            }
                        }
                    }

                    &.space {
                        width: 12px;
                    }
                }
            }
        }

        >tbody {
            >tr {
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                >td {
                    padding: 0;
                    height: 56px;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Normal;
                    line-height: 12px;
                    color: #666666;

                    &:first-child {
                        border-style: solid;
                        border-color: #E6E6E6;
                        border-width: 0 0 0 1px;
                    }

                    &:last-child {
                        border-style: solid;
                        border-color: #E6E6E6;
                        border-width: 0 1px 0 0;
                    }

                    &.name {
                        text-align: left;
                        padding-left: 12px;
                        line-height: 14px;
                    }

                    &.cpl_rate {

                        &.red {
                            color: #FB6060;
                        }
                    }

                    &.bar {
                        >.wrapper {
                            display: flex;
                            justify-content: space-between;

                            >.item {
                                width: 128px;
                                height: 12px;
                                // background-color: #40A1E1;
                                position: relative;
                                overflow: hidden;

                                >.color_item {
                                    position: absolute;
                                    height: 100%;
                                    top: 0;
                                    left: 0;
                                    width: 0;
                                }
                            }
                        }
                    }

                    &.avg {
                        border-style: solid;
                        border-color: #E6E6E6;
                        border-width: 0 0 0 1px;
                    }

                    &.rate {
                        &.last {
                            border-style: solid;
                            border-color: #E6E6E6;
                            border-width: 0 1px 0 0;
                        }
                    }

                    // &.action {
                    //     color: #1890FF;
                    //     user-select: none;

                    //     >.btn {
                    //         display: inline-block;
                    //         cursor: pointer;

                    //         &:hover {
                    //             opacity: 0.8;
                    //         }
                    //     }

                    //     >.cancel {
                    //         width: 14px;
                    //         height: 14px;
                    //         display: inline-block;
                    //         cursor: pointer;

                    //         &:hover {
                    //             opacity: 0.8;
                    //         }
                    //     }
                    // }
                }

                &:nth-child(even) {
                    >td {
                        background-color: #FAFAFA;
                    }
                }

                &:last-child {
                    >td {
                        border-style: solid;
                        border-color: #E6E6E6;
                        border-width: 0 0 1px 0;

                        &.avg {
                            border-width: 0 0 1px 1px;
                        }

                        &.rate {
                            &.last {
                                border-width: 0 1px 1px 0;
                            }
                        }

                        &:first-child {
                            border-width: 0 0 1px 1px;
                            border-bottom-left-radius: 2px;
                        }

                        &:last-child {
                            border-width: 0 1px 1px 0;
                            border-bottom-right-radius: 2px;
                        }
                    }
                }

                &.selected {
                    >td {
                        background-color: #E3F2FF;

                        // &:last-child {
                        //     position: relative;

                        //     &::after {
                        //         content: "";
                        //         position: absolute;
                        //         right: -6px;
                        //         top: 50%;
                        //         transform: translateY(-50%);
                        //         width: 0;
                        //         height: 0;
                        //         border-style: solid;
                        //         border-color: transparent transparent transparent #E3F2FF;
                        //         border-width: 10px 0 10px 9px;
                        //     }
                        // }
                    }
                }
            }
        }
    }

    :local(.subtitle) {
        height: 100%;
        display: flex;

        >.left {
            padding-left: 17px;
            padding-top: 4px;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 12px;
            color: #666666;

            >.space {
                width: 45px;
                flex-shrink: 1;
            }

            >.text_item,
            >.select_item {
                display: flex;
                align-items: center;
                flex-shrink: 0;
            }

            >.text_item {
                >.value {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 20px;
                    position: relative;
                    bottom: 4px;
                }

                >.unit {
                    margin-left: 4px;
                }

                &.green {

                    >.value,
                    >.unit {
                        color: #3AA372;
                    }
                }

                &.red {

                    >.value,
                    >.unit {
                        color: #FB6060;
                    }
                }
            }

            >.select_item {
                >.selector {
                    margin-left: 4px;
                }
            }
        }

        >.right {
            margin-left: auto;
            display: flex;
            flex-shrink: 0;

            >.btn {
                align-self: center;
                margin-left: 12px;
            }

            >.tabs {
                margin-left: 16px;
                display: flex;

                >.item {
                    padding: 0 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    line-height: 14px;
                    color: #999999;

                    &:hover {
                        opacity: 0.8;
                    }

                    >.text {
                        position: relative;
                        user-select: none;
                        bottom: 3px;
                    }

                    &.selected {
                        background-color: #FAFAFA;
                        position: relative;
                        color: #1890FF;

                        &::after {
                            content: "";
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 2px;
                            background-color: #1890FF;
                        }
                    }
                }
            }
        }
    }
}