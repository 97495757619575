.FileItem{
    padding: 5px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 140px;
    height: 140px;
    flex-shrink: 0;
    align-items: center;
    margin-top: 8px;
    margin-left: 8px;

    &:hover,
    &.selected{
        background-color: #dadada;
        .checkRect{
            display: flex;
            position: absolute;
            top: 0px;
            right: 0px;
            border: 1px solid #aaa;
            width: 20px;
            height: 20px;
            background-color: #FFF;
            cursor: pointer;
        }
    }
    &.selected{
        .checkedIcon{
            display: flex;
            position: absolute;
            top: 2px;
            right: 2px;
        }
    }

    .checkRect,
    .checkedIcon{
        display: none;
    }

    img {
        width: 90px;
        height: 90px;
        user-select:unset;
    }

    .fileName{
        word-break:break-all; /*支持IE，chrome，FF不支持*/
    　　word-wrap:break-word;/*支持IE，chrome，FF*/
        text-align: center;
        width: 100%;
        height: 40px;
        line-height: 20px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        display:-webkit-box; //作为弹性伸缩盒子模型显示。
        -webkit-box-orient:vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
        -webkit-line-clamp:2; //显示的行
        // padding-left: 2px;
        // padding-right: 2px;
        user-select:unset;
    }
}
