.main_page_bottom_panels_improve_chance_comp_wrapper {
    height: 100%;
    // overflow-y: auto;
    display: flex;

    .left {
        width: 201px;
        // position: relative;
        // display: flex;
        // align-items: center;
        // justify-content: center;

        .position_wrapper {
            margin-top: 23px;
            position: relative;
            display: flex;
            justify-content: center;

            .chart_wrapper {
                width: 147px;
                height: 147px;
            }

            .circle {
                position: absolute;
                width: 115px;
                height: 115px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                background: #222443;
                box-shadow: 0px 0px 32px 0px rgba(68, 243, 218, 0.38) inset;
                display: flex;
                align-items: center;
                justify-content: center;

                .circle_content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .title {
                        height: 20px;
                        font-size: 14px;
                        // font-weight: 400;
                        color: #44F3DA;
                        line-height: 20px;
                    }

                    .value {
                        height: 48px;
                        font-size: 40px;
                        // font-family: Helvetica;
                        color: #44F3DA;
                        line-height: 48px;
                    }
                }
            }
        }
    }

    .right {
        width: 0;
        flex-grow: 1;
        overflow-y: auto;

        .comp_content_wrapper {
            padding: 16px;
            // padding-left: 17px;
            // min-height: 100%;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;

            .row_wrapper {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                    margin-top: 15px;
                }

                .icon {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: transparent;
                }

                .title {
                    height: 14px;
                    font-size: 14px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 14px;
                    margin-left: 9px;
                    width: 64px;
                }

                .rate {
                    height: 14px;
                    font-size: 14px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 14px;
                    // margin-left: 8px;
                    width: 48px;
                }

                .value {
                    height: 14px;
                    font-size: 14px;
                    // font-weight: 400;
                    color: #44F3DA;
                    line-height: 14px;
                }
            }
        }
    }
}