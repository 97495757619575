.FactorList {
    height: 100%;
    padding: 24px 24px;
    overflow-y: auto;

    .toolBar{
        display: flex;
        flex-direction: row;
        align-items: center;
        .filterBox{
            height: 30px;
            width: 300px;
            margin-left: 20px;
        }
    }

    .downContent{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        .leftTable{
            flex-grow: 45;
            width: 45%;
            flex-basis: 1px;
            margin-right: 10px;

            .factor_tag:hover{
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .rightContent{
            margin-left: 10px;
            flex-grow: 55;
            width: 55%;
            flex-basis: 1px;
            // background-color: #ddd;
            // color: #000;
            // padding: 20px;
            .nameRow, .tagRow{
                margin-top: 20px;
                margin-left: 25px;
                font-size: 17px;
            }
            .tagRow{
                margin-top: 8px;
            }
            .limitRow{
                display: flex;
                flex-direction: row;
                padding-left: 25px;
                margin-top: 10px;
                align-items: center;
                span{
                    width: 90px;
                }
                .inputBox{
                    width: 150px;
                }
            }
            .factorTable{
                margin-top: 20px;
                margin-left: 25px;

                .factor_tag_block{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .delBtn{
                        color: #008bdc;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }

}