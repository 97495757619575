:global {
    :local(.new_main_page_range_block_wrapper) {
        height: 8px;
        background: rgba(240, 242, 245, 1);
        border-radius: 2px;
        display: flex;
        position: relative;

        .left,
        .mid,
        .right {
            flex-basis: 0;
            // opacity: 0;
        }

        .left,
        .right {
            // background: rgba(246, 38, 76, 1);
            flex-grow: 42;
        }

        .mid {
            // background: rgba(53, 207, 201, 1);
            flex-grow: 65;
        }

        .left {
            border-radius: 2px 0px 0px 2px;
        }

        .right {
            border-radius: 0px 2px 2px 0px;
        }

        .sign_block {
            position: absolute;
            width: 2px;
            height: 4px;

            &::after {
                content: '';
                display: block;
                height: 100%;
                background: rgba(140, 140, 140, 1);
                border-radius: 1px;
                position: relative;
                right: 1px;
            }
        }

        .top_sign {
            top: -5px;
        }

        .bottom_sign {
            bottom: -5px;
        }
    }
}