:global{
    :local(.editAndViewCapsuleModal){
        &.detail-modal{
            .ant-modal-footer{
                display: none;
            }
        }
        .ant-modal-body{
            padding: 4px;
        }
        .header-bar{
            position: absolute;
            top: 0;
            right: 73px;
            height: 56px;
            display: flex;
            align-items: center;
            &>div{
                width: 24px;
                height: 24px;
                background-size: 24px !important;
                margin-left: 24px;
                cursor: pointer;
            }
            .del-btn{                
                background: url('../../../../../../../assets/delete.png') no-repeat;
            }
            .edit-btn{
                background: url('./images/edit.png') no-repeat;
            }
            .qrcode{
                width: 24px;
                background: url('./images/qrcode.png') no-repeat;
            }
        }
       .scroll-wrapper{
           padding: 20px;
         max-height: 600px;
         overflow: hidden auto;
         .forward-content{
            background-color: #F5F7FA;
            border-radius: 4px;
            padding: 16px;
            margin-bottom: 8px; 
         } 
         &::-webkit-scrollbar{
             width: 4px;
         } 
         &::-webkit-scrollbar-thumb {
            background-color: #C9C9C9;
            border-radius: 2px;
        }    
       }
       .ant-modal-footer{
           height: 96px;
           line-height: 96px;
           border-top:none;
           padding: 0 16px;
       }
    }
}