:global {
    :local(.HorizLine) {
        width: 100%;
        height: 20px;
        display: flex;
        flex-direction: row;

        .LineCell {
            height: 20px;
            flex-basis: 1;
            display: flex;
            flex-direction: column;

            .ColorBlock {
                height: 8px;
                width: 100%;
            }

            .TextBlock {
                height: 12px;
                width: 100%;
                text-align: center;
                font-size: 12px;
            }
        }

        .LeftPad,
        .RightPad {
            width: 40px;
            height: 1px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 1;
        }

        .LeftPad {
            width: 101px;
        }

        .RightPad {
            width: 75px;
        }
    }

}