:global {
    :local(.wrapper) {
        height: unset;

        .color_line{
            display: flex;
            flex-direction: row;
            // margin-left: 14px;
            // margin-right: 14px;
            >div{
                &:first-child{
                    border-bottom-left-radius: 6px;
                    border-top-left-radius: 6px;
                }
                &:last-child{
                    border-bottom-right-radius: 6px;
                    border-top-right-radius: 6px;
                }

                width: 33.33%;
                text-align: center;
                line-height: 30px;
                height: 30px;
                font-weight: bold;
                color: black;
                letter-spacing: 1px;
                text-fill-color: #FFF;
                text-stroke: 0.4px #444;
                -webkit-text-fill-color: #FFF;
                -webkit-text-stroke: 0.4px #444;
                font-size: 17px;
            }
        }

        .interval_line{
            display: flex;
            flex-direction: row;
            // margin-left: 14px;
            // margin-right: 14px;
            height: 14px;
            line-height: 14px;

            >div{
                width: 33.33%;
                display: flex;
                flex-direction: row;
                font-size: 14px;
                color: #888;
                font-weight: bold;

                &.left_limit{
                    flex-direction: row;
                }
                &.right_limit{
                    flex-direction: row-reverse;
                }
                &.center_limits{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    :first-child{
                        width: 46px;
                        margin-left: -23px;
                        text-align: center;
                        display: block;
                    }
                    :last-child{
                        width: 46px;
                        margin-right: -23px;
                        text-align: center;
                        display: block;
                    }
                }
            }
        }
    }
}