.produce_trend_content_item_wrapper {
    height: 278px;
    border: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
    display: flex;
    flex-direction: column;

    .item_header_wrapper {
        height: 47px;
        border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
        padding-left: 12px;
        display: flex;
        align-items: center;
        background: #0D1115;

        .item_header_icon {
            width: 24px;
            height: 24px;
        }

        .title {
            width: 193px;
            padding-left: 5px;
            height: 18px;
            font-size: 18px;
            // font-family: PingFangSC-Medium, PingFang SC;
            // font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
        }

        .select_wrapper {
            display: flex;
            align-items: center;

            .single_select_wrapper {
                display: flex;
                align-items: center;
                width: 102px;
                padding-right: 6px;

                .check_block {
                    width: 16px;
                    height: 16px;
                    border: 1px solid #9FA0BA;
                    cursor: pointer;
                    user-select: none;
                }

                .check_name {
                    margin-left: 6px;
                    height: 12px;
                    font-size: 12px;
                    // font-family: PingFangSC-Regular, PingFang SC;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }
            }
        }
    }

    .item_content_wrapper {
        height: 0;
        flex-grow: 1;
        padding: 16px;
        display: flex;
        background: #212830;

        .left_chart_wrapper {
            width: 368px;
        }

        .right_chart_wrapper {
            margin-left: 16px;
            width: 0;
            flex-grow: 1;
        }

        .left_chart_wrapper,
        .right_chart_wrapper {
            background: #191F27;
            border-radius: 2px;
            display: flex;
            flex-direction: column;

            .chart_header {
                height: 32px;
                display: flex;
                align-items: center;

                .icon {
                    width: 2px;
                    height: 16px;
                }

                .chart_title {
                    margin-left: 8px;
                    height: 14px;
                    font-size: 14px;
                    // font-family: PingFangSC-Medium, PingFang SC;
                    // font-weight: 500;
                    color: #BFC0DA;
                    line-height: 14px;
                }

                .subtitle {
                    margin-left: 8px;
                    height: 12px;
                    font-size: 12px;
                    // font-family: PingFangSC-Regular, PingFang SC;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                }
            }

            .chart_content {
                height: 0;
                flex-grow: 1;

                .chart_dom {
                    height: 100%;
                }
            }
        }
    }
}