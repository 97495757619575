:global {
    :local(.custom_1) {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        color: rgba(0, 0, 0, 0.65);

        .column_1 {
            width: 40px;
            min-width: 40px;

            &>div {
                height: 32px;
                line-height: 32px;
            }
        }

        .column_2 {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &>div {
                flex-direction: column;

                &>div {
                    height: 32px;
                    line-height: 32px;
                    text-align: center;
                }
            }
        }
    }

    :local(.custom_2) {
        .fa-item {
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.65);

            .name {
                width: 40px;
                min-width: 40px;
            }

            .ant-input {
                width: 80px;
                border-color: rgba(0, 0, 0, 0.15) !important;
                margin-right: 4px;
                border-radius: 4px;
            }

            .ant-select {
                width: 104px;
                margin-right: 14px;

                .ant-select-selector {
                    border-color: rgba(0, 0, 0, 0.15) !important;
                    border-radius: 4px;
                }
            }
        }
    }

    :local(.custom_3) {
        .row-item{
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.65);

            .name {
                width: 40px;
                min-width: 40px;
            }

            .ant-input {
                width: 80px;
                border-color: rgba(0, 0, 0, 0.15) !important;
                margin-right: 4px;
                border-radius: 4px;
            }

            .ant-select {
                width: 104px;
                margin-right: 14px;

                .ant-select-selector {
                    border-color: rgba(0, 0, 0, 0.15) !important;
                    border-radius: 4px;
                }
            }
        }
    }

    :local(.custom_4) {}

    :local(.custom_5) {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        color: rgba(0, 0, 0, 0.65);
        li {
            display: flex;
            flex-direction: column;
            align-items: center;           

            .text {
              width: 40px;
              min-width: 40px;   
              line-height: 32px;
              text-align: left;                     
            }

            .ant-input {
                width: 80px;
                border-color: rgba(0, 0, 0, 0.15) !important;
                margin-right: 4px;
                border-radius: 4px;
            }

            .ant-checkbox-wrapper{
                line-height: 32px;
            }
            .ant-checkbox {
              border: 1px solid rgba(0, 0, 0, 0.15) !important;
              border-radius: 4px;
            }
          }
    }

    :local(.custom_6) {
        .row-item{
            display: flex;
            align-items: center;
            color: rgba(0, 0, 0, 0.65);
            .name {
                width: 60px;
                min-width: 60px;
            }
            .ant-input {
                width: 104px;
                border-color: rgba(0, 0, 0, 0.15) !important;
                margin-right: 4px;
                border-radius: 4px;
            }
        }
    }
}