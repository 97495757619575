.ExcelDemo{
    padding: 30px;
    min-width: 1100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.excel_modal{
    // width: 800px;
    // height: 500px;
    .ant-modal-body{
        padding: 0px;
    }

    .modalEditContent{
        display: flex;
        flex-direction: row;
        .scriptEditor{
            width: 400px;
            height: 550px;
            flex-shrink: 0;
        }
    }
}
