:global {
    :local(.sample_maintenance_left_table_wrapper) {
        height: 100%;
        padding: 16px 24px;
        display: flex;
        flex-direction: column;

        .top_row {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .table_size_wrapper {
            height: 0;
            flex-grow: 1;
            margin-top: 32px;

            .ant-table-wrapper {
                height: 100%;

                .ant-spin-nested-loading {
                    height: 100%;

                    .ant-spin-container {
                        height: 100%;

                        .ant-table.ant-table-fixed-header {
                            height: 100%;

                            .ant-table-container {
                                height: 100%;
                                display: flex;
                                flex-direction: column;

                                .ant-table-header {
                                    flex-shrink: 0;
                                }
                            }
                        }
                    }
                }
            }

            .td_wrapper {
                display: inline-block;

                .flex_wrapper {
                    display: flex;
                    align-items: center;

                    .icon {
                        height: 12px;
                    }

                    .name {
                        height: 12px;
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba(153, 153, 153, 1);
                        line-height: 12px;
                        margin-left: 4px;
                    }
                }
            }

            .action_td_wrapper {
                display: inline-block;

                .action_wrapper {
                    width: 20px;
                    height: 20px;
                    background: rgba(235, 240, 255, 1);
                    border-radius: 2px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >.close_img {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
}