.surviveTable {
  width: 1064px;
  height: 312px;
  background: #FFFFFF;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  &-title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #727272;
    opacity: 1;
    margin-bottom: 24px;
  }
  &-content {
    display: flex;
    .surviveTable-table {
      display:table;
      min-width: 704px;
      border: 1px solid #E6E6E6;
      opacity: 1;
      border-radius: 2px;
      .pl16 {
        padding-left: 16px;
        box-sizing: border-box;
      }
      .pr16 {
        padding-right: 16px;
      }
      .left {
        text-align: left;
      }
      .right {
        text-align: right;
      }
      &-th {
        display:table-row;
        padding: 0 16px;
        div {
          display: table-cell;
          height: 56px;
          line-height: 56px;
          font-size: 16px;
          text-align: center;
        }
      }
      &-tr {
        display:table-row;
        .bold {
          font-size: 20px;
          font-weight: bold;
          color: #3A3A3A;
        }
        .light {
          font-size: 12px;
          color: #727272;
          margin-left: 4px;
        }
        div {
          display: table-cell;
          height: 56px;
          line-height: 56px;
          text-align: center;
          font-size: 14px;
        }
        .borderRight {
          border-right: 1px solid #E6E6E6;
        }
      }
    }
    &-right {
      margin-left: 72px;
      .title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        line-height: 27px;
        color: #3A3A3A;
        opacity: 1;
        text-align: center;
      }
      .legend {
        display: flex;
        justify-content: space-between;
        .blue {
          width: 10px;
          height: 10px;
          background: #5073B8;
          opacity: 1;
          border-radius: 2px;
          display: inline-block;
          margin-right: 8px;
        }
        .green {
          width: 10px;
          height: 10px;
          background: #0AB39C;
          opacity: 1;
          border-radius: 2px;
          display: inline-block;
          margin-right: 8px;
        }
        .orange {
          width: 10px;
          height: 10px;
          background: #F16548;
          opacity: 1;
          border-radius: 2px;
          display: inline-block;
          margin-right: 8px;
        }
      }
    }
  }
}
