:global {
    :local(.wrapper) {
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        background: #FFFFFF;
        padding: 16px 16px 0 16px;
        height: 100%;
        position: relative;

        .title_row {
            display: flex;
            align-items: center;

            .title {
                height: 16px;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 16px;
                color: #666;
            }

            .arrow {
                margin-left: auto;

                &.down_arrow {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 8px 4px 0 4px;
                    border-color: #FB6060 transparent transparent transparent;
                }
            }

            .percent_text {
                margin-left: 7px;
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 14px;
                color: #FB6060;
            }
        }

        .value_row {
            margin-top: 8px;
            display: flex;
            align-items: flex-end;

            .value {
                height: 30px;
                font-size: 30px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 30px;
                color: #333;
            }

            .unit {
                margin-left: 4px;
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 14px;
                color: #666;
            }
        }

        .divide_line_row {
            margin-top: 26px;
            height: 1px;
            background-color: #c9c9c9;
        }

        .switch_time_row {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .time_text {
                height: 14px;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 14px;
                color: #333;
                margin: 0 10px;
                user-select: none;
            }
        }

        // .chart_block_row {}
    }
}