:global {
    :local(.setting-modal) {
        .ant-modal-header{
            padding: 0;
            .ant-modal-title{
                padding: 20px 24px;
            }
        }
        .ant-modal-close-x{
            width: 50px;
            height: 40px;
            line-height: 40px;
            font-size: 22px;
            margin-top: 10px;
        }
        .add-btn{
            position: absolute;
            top: 12px;
            right: 56px;
            background: url('../../assets/add.png') no-repeat left;
            padding-left: 24px;
            line-height: 40px;
            font-size: 16px;
            color:#375B98;
            cursor: pointer;
        }
        .ant-modal-body {
            padding: 0;
            height: 400px;
            .ant-empty{
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .ant-modal-footer {
            border-top: none !important;
            .ant-btn:hover{
               color: #375B98;
               border-color: #375B98 !important;
            }
            .ant-btn-primary {
                background: #375B98;
                border: 1px solid #375B98;
                &:hover{
                    color: #fff !important;
                    opacity: .8;
                } 
            }
        }

        ::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: #fff;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #dadada;
            border-radius: 2px;
        }

        ::-webkit-scrollbar-track {
            background-color: #fff;
        }

        .ant-modal-header {
            border-radius: 2px 2px 0 0;
            background: #E2E4E7;
        }

        .ant-modal-title {
            color: #333;
        }

        .ant-modal-content {
            border-radius: 2px;
            overflow: hidden;
        }
        
        .ant-tabs{
            height: 400px;
            max-height: 400px;
            .ant-tabs-nav{
                width: 168px;
                background-color: #fafafa;
                .ant-tabs-ink-bar{
                    display: none;
                }
                .ant-tabs-tab{
                    display: flex;
                    justify-content: space-between;
                    border: none;
                    padding: 16px;
                    // background: #fff;
                    .ant-tabs-tab-btn{
                        max-width: 130px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #333 !important;
                        cursor: pointer;
                        padding-left: 16px;
                    }
                    &.ant-tabs-tab-active {
                        .ant-tabs-tab-btn{
                            // border-bottom: 2px solid #357CF7;
                            background: url('../../assets/path.png') no-repeat left;
                        }
                        
                        .ant-tabs-tab-remove{
                            visibility: visible;
                        }
                    }
                    .ant-tabs-tab-remove{
                        visibility: hidden;
                        width: 16px;
                        height: 16px;
                        background: url('../../assets/delete.png');
                        float: right;
                        .anticon-close svg{
                            display: none;
                        }
                    }
                    
                }
                .ant-tabs-nav-operations{
                    display: none;
                }
            } 
            .ant-tabs-content-holder{
                border-left: none;
            }   
        }

        .form {
            max-height: 400px;
            height: 400px;
            padding-right: 20px;
            overflow-y: auto;
            .total-days {
                font-size: 16px;
                line-height: 46px;
                color: #333; 
                padding-left: 24px;               
            }
            
            ul.setting-info {
                list-style: none;
                padding: 0;

                li.setting-item {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    color: #666;
                    &>div{
                        flex: 1;
                        text-align: center;
                        padding: 0 16px;
                        min-width: 120px;
                        
                        &.order{
                            min-width: 80px;
                            max-width: 80px;                           
                        }
                        .ant-select-selector{
                            border-radius: 4px;
                            border-color: #C9C9C9 !important;
                            .ant-select-selection-item{
                                padding-right: 12px;
                            }
                        }
                    }

                    .operation {
                        width: 120px;
                        height: 24px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        .btn-item {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            cursor: pointer;
                        }

                        .delete-btn {
                            background: url('../../assets/delete.png');
                        }
                        .add-before-btn{
                            background: url('../../assets/icon_add_before.png');
                        }
                        .add-after-btn{
                            background: url('../../assets/icon_add_after.png');
                        }
                    }
                }           
            }
        }
    }
    :local(.setting-modal-empty){
        .ant-modal-footer{
            display: none;
        }
    }
}
