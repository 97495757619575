.ExcelEditor{
    width: 100%;
    height: 100%;
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}