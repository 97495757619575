.survive {
  width: 1064px;
  height: 312px;
  background: #FFFFFF;
  box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  &-title {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #727272;
    opacity: 1;
    margin-bottom: 24px;
  }
  &-content {
    display: flex;
    &-left {
      width: 704px;
      border: 1px solid #E6E6E6;
      box-sizing: border-box;
      border-radius: 2px;
      .title {
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        height: 56px;
        line-height: 56px;
        align-items: center;
        padding: 0 16px;
        box-sizing: border-box;
        div {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 27px;
          color: #3A3A3A;
        }
        .title-single {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #3A3A3A;
          display: flex;
          min-width: 120px;
        }
        .title-unit {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3A3A3A;
        }
        .title-name {
          width: 48px;
        }
      }
      .progress {
        display: flex;
        box-sizing: border-box;
        &-type {
          box-sizing: border-box;
          height: 32px;
          text-align: center;
          color: #fff;
          font-size: 12px;
        }
      }
    }
    &-right {
      margin-left: 72px;
      .title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        line-height: 27px;
        color: #3A3A3A;
        opacity: 1;
        text-align: center;
      }
      .surviveType {
        padding-left: 0px;
        &-list {
          display: flex;
          padding: 0px;
          margin-bottom: 5px;
          justify-content: space-between;
          &-color {
            margin-right: 30px;
            width: 60px;
            span {
              display: inline-block;
            }
            .text {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 24px;
              color: #3A3A3A;
              margin-left: 2px;
            }
            .blue {
              width: 12px;
              height: 12px;
              background: #5073B8;
              opacity: 1;
              border-radius: 4px;
            }
            .green {
              width: 12px;
              height: 12px;
              opacity: 1;
              border-radius: 4px;
              background: #0AB39C;
            }
            .orange {
              width: 12px;
              height: 12px;
              opacity: 1;
              border-radius: 4px;
              background: #F1963A;
            }
            .red {
              width: 12px;
              height: 12px;
              opacity: 1;
              border-radius: 4px;
              background: #FE8569;
            }
          }
        }
      }
    }
  }
}
