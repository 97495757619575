:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            padding: 12px;
            display: flex;
            flex-direction: column;
            position: relative;

            >.resize {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            >.table_wrapper {
                position: relative;
                border: 1px solid #E6E6E6;

                .ant-table-cell {
                    padding-left: 0;
                    padding-right: 0;
                }

                th {
                    white-space: nowrap;
                }

                .ant-table-body {
                    //hardcode

                    //滚动条背景
                    &::-webkit-scrollbar {
                        width: 4px;
                    }

                    // //滚动条前景
                    &::-webkit-scrollbar-thumb {
                        background-color: rgba($color:#C9C9C9, $alpha: 1);
                        border-radius: 2px;
                    }

                    &::-webkit-scrollbar-track {
                        background-color: transparent;
                        border-radius: 2px;
                    }
                }
            }
        }
    }
}