:global {
    :local(.wrapper) {
        padding-right: 8px;
        display: flex;
        align-items: flex-end;

        >.time_selector_wrapper {
            margin-left: 40px;
            position: relative;

            >.picker_wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;

                >.hover_block {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }

            >.content {
                position: relative;
                display: flex;
                align-items: center;

                >.icon_wrapper {
                    >.icon {
                        cursor: pointer;
                    }
                }

                >.block_wrapper {
                    border: 1px solid #C9C9C9;
                    margin-left: 6px;
                    border-radius: 4px;
                    background-color: #fff;
                    >.date_picker_wrapper {
                        border-radius: 2px;
                        padding: 7px 12px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .text {
                            height: 14px;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 14px;
                            color: #546677;
                            white-space: nowrap;
                        }
                    }
                }

            }

        }
    }

    :local(.cus_picker) {
        .ant-picker-range-arrow {
            // hard code
            display: none;
        }
    }
}