.energyTarget {
  padding-left: 8px;
  padding-top: 8px;
  &-header {
    display: flex;
    color: #A6B2C2;
    margin-bottom: 8px;
    margin-top: 4px;
    &-left {
      margin-right: 200px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 10px;
    }
    &-right {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 10px;
      color: #A6B2C2;
      opacity: 0.8;
    }
  }
  &-item {
    display: flex;
    margin-bottom: 10px;
    &-left {
      flex: 1;
      .left-top {
        margin-bottom: 8px;
        &-name {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 14px;
          color: #FEBC05;
          opacity: 0.8;
          margin-bottom: 16px;
          margin-right: 8px;
        }
        &-type {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 14px;
          color: #8DA1AF;
          opacity: 0.8;
        }
      }
      .left-bottom {
        &-number {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 10px;
          color: #FFFFFF;
          opacity: 1;
          margin-right: 16px;
          width: 64px;
          display: inline-block;
        }
        &-percent {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 16px;
          color: #8DA1AF;
          opacity: 0.8;
          margin-right: 26px;
          display: inline-block;
          min-width: 80px;
        }
        &-comparison {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 10px;
          color: #8DA1AF;
          opacity: 0.8;
        }
      }
    }
  }
  .triangle {
    display: inline-block;
    margin-bottom: 2px;
    margin-right: 5px;
    &.up {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 8px solid#33C2A4;
    }
    &.down {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 8px solid #f16994;
    }

    &+span {
      text-indent: 10px;
    }
  }
}
