:global {
    :local(.personalCenter) {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 24px 24px;

        .content-wrapper {
            height: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .content-header {
                width: 100%;
                height: 56px;
                font-size: 16px;
                line-height: 56px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #357CF7;
                background-color: #fff;
                padding: 0 24px;
                border-radius: 4px 4px 0 0;
                border-bottom: 1px solid #C9C9C9;
            }

            .content-main {
                height: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: row;

                .left {
                    width: 256px;
                    background-color: #FAFAFA;
                }

                .right {
                    width: 0;
                    flex-grow: 1;
                    background-color: #fff;
                    padding: 56px;
                    &>div{
                        width: 800px;
                        display: flex;
                        align-items: center;
                        padding-bottom: 30px ;
                        &>label{
                            display: inline-block;
                            font-size: 14px;
                            color: #999;
                            line-height: 32px;
                            min-width: 80px;
                        }
                        &>span{
                            color: #666;
                        }
                        .ant-form {
                            width: 600px;                    
    
                            .input-tip {
                                position: absolute;
                                right: 0;
                                top: 0;
                                color: #999;
                                font-size: 14px;
                                line-height: 32px;
                                padding-left: 10px;
                                transform: translateX(100%);
                            }
                        }
                        &.footer{
                            margin-top: 48px;
                            justify-content: flex-end;
                            .ant-btn{
                                margin-left: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}