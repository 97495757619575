:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            display: flex;
            flex-direction: column;

            >.top {
                height: 51px;
                padding: 0 12px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                >.text_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    >.item {
                        width: 0;
                        position: relative;
                        font-size: 12px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        line-height: 12px;

                        &.time {
                            color: #666666;
                        }

                        &.name {
                            color: #333333;
                        }

                        >.text {
                            white-space: nowrap;
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        &:first-child {
                            >.text {
                                transform: translateX(0);
                            }
                        }

                        &:last-child {
                            >.text {
                                transform: translateX(-100%);
                            }
                        }
                    }
                }

                >.bar_wrapper {
                    margin-top: 8px;

                    >.bar {
                        height: 12px;
                        background-color: #355B99;
                    }
                }
            }

            >.bottom {
                height: 0;
                flex-grow: 1;
                padding: 20px 12px 16px 12px;
                display: flex;
                font-size: 12px;
                font-family: SourceHanSansCN-Regular;
                line-height: 12px;

                >.left,
                >.right {
                    display: flex;
                    flex-direction: column;

                    >.top {
                        height: 24px;

                        >.title {
                            color: #666666;
                        }
                    }

                    >.bottom {
                        height: 0;
                        flex-grow: 1;
                        color: #333333;
                    }
                }

                >.left {
                    width: 142px;

                    >.bottom {
                        >.wrapper {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            >.item {
                                display: flex;
                                align-items: flex-end;

                                >.color {
                                    width: 8px;
                                    height: 8px;
                                    border-radius: 2px;
                                }

                                >.name {
                                    margin-left: 4px;
                                    width: 64px;
                                }

                                >.line {
                                    height: 16px;
                                    width: 1px;
                                    background-color: #EBEBEB;
                                }

                                >.time {
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                }

                >.right {
                    width: 0;
                    flex-grow: 1;

                    >.top {
                        padding-left: 44px;
                    }

                    >.bottom {
                        >.wrapper {
                            height: 100%;
                            padding-right: 4px;
                            overflow-y: auto;
                            transform: rotateY(180deg);

                            >.content {
                                transform: rotateY(180deg);

                                >table {
                                    width: 100%;
                                    border-collapse: separate;
                                    border-spacing: 0;

                                    >tbody {
                                        >tr {
                                            >td {
                                                padding: 0;
                                                padding-top: 6px;
                                                padding-bottom: 6px;

                                                &:first-child {
                                                    border-top-left-radius: 4px;
                                                    border-bottom-left-radius: 4px;
                                                }

                                                &:last-child {
                                                    border-top-right-radius: 4px;
                                                    border-bottom-right-radius: 4px;
                                                }

                                                &.color {
                                                    width: 8px;
                                                    padding-left: 4px;

                                                    >.bar {
                                                        width: 2px;
                                                        height: 16px;
                                                    }
                                                }

                                                &.action {
                                                    width: 31px;
                                                    color: #1890FF;
                                                    font-family: SourceHanSansCN-Normal;
                                                    user-select: none;

                                                    >.btn {
                                                        display: inline-block;
                                                        cursor: pointer;

                                                        &:hover {
                                                            opacity: 0.8;
                                                        }
                                                    }
                                                }
                                            }

                                            &:nth-child(odd) {
                                                >td {
                                                    background-color: #F8F8F8;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}