:global {
    :local(.EventWrapper) {
        position: relative;
        height: 100%;
        background-color: #F4F5F7;

        .events{
            position: relative;
            height: 100%;
            overflow: hidden auto;
            padding: 53px 20px 0 4px;
        }

        .event-item {
            display: flex;
            margin-bottom: 33px;

            &.at_left {
                justify-content: flex-start;
            }

            &.at_right {
                justify-content: flex-end;

                .creator-info {
                    display: none;
                }

                .time {
                    text-align: right;
                }

                .event-content,
                .comment-content {
                    justify-content: flex-end;
                }

                &.move-left {
                    padding-right: 500px !important;
                    transition: all 0.5s;
                }

                &.move-right {
                    padding-right: 0 !important;
                    transition: all 0.5s;
                }
            }

            .creator-info {
                width: 92px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px;
                color: #2B3249;

                .ant-avatar {
                    margin-bottom: 4px;
                }

                .org-name {
                    color: #fff;
                    background-color: #40A9FF;
                    border-radius: 10px;
                    line-height: 20px;
                    padding: 0 4px;
                }
            }

            .event-info {
                font-size: 12px;
                color: #000;

                &>.time {
                    color: rgba(0, 0, 0, 0.65);
                    margin-bottom: 5px;
                }

                .event-content {
                    max-width: 344px;
                    display: flex;

                    .event-title {
                        display: flex;
                        position: relative;
                        cursor: pointer;

                        .classify-and-new {
                            height: 100%;
                            padding: 10px 0 10px 10px;
                            background: #fff;
                            font-weight: bold;
                            text-align: center;
                            min-width: 50px;

                            &.hidden {
                                display: none;
                            }
                        }

                        .new-icon {
                            display: inline-block;
                            background: #FC5F5F;
                            color: #fff;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            padding: 0 5px;
                            border-radius: 9px;
                            margin-right: 5px;
                            white-space: nowrap;
                        }
                    }
                }

                .comment-item {
                    max-width: 344px;
                    margin-top: 10px;

                    .comment-content {
                        &>span {
                            white-space: nowrap;
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.65);
                        }

                        display: flex;
                        align-items: center;
                        line-height: 18px;

                        div {
                            background-color: #E3E3E3;
                        }

                        .pop-arrow {
                            border-color: #E3E3E3;
                        }
                    }
                }

                .pop-card {
                    position: relative;

                    .pop-arrow {
                        position: absolute;
                        display: block;
                        top: 50%;
                        content: '';
                        width: 8px;
                        height: 8px;
                        background: 0 0;
                        border-style: solid;
                        border-width: 4px;
                        transform: translateY(-50%) rotate(45deg);
                        border-color: #fff;

                        &.arrow-left {
                            left: -4px;
                        }

                        &.arrow-right {
                            right: -4px;
                        }
                    }
                }
            }
        }

        .ellipsis--l1 {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            word-break: break-all;
        }

        .multi-ellipsis--l2 {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            word-break: break-all;
        }

        .ant-empty {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}