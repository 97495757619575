.chartTpl {
  width: 916px;
  background-color: #EDF7F8;
  border-radius: 16px;
  margin-top: 10px;
  padding: 16px;
  box-sizing: border-box;
  &-content {
    width: 880px;
    margin: 0 auto;
    background-color: #FFFFFF;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    &-header {
      display: flex;
      margin-bottom: 18px;
      position: relative;
      .screen {
        position: absolute;
        left: 212px;
        width: 152px;
        top: 26px;
        min-height: 68px;
        background: #000000;
        opacity: 0.7;
        border-radius: 4px;
        z-index: 99;
        &-content {
          padding: 8px;
          box-sizing: border-box;
          color: #FFFFFF;
          &-item {
            display: flex;
            margin-bottom: 8px;
            .select {
              width: 12px;
              height: 12px;
              border: 1px solid #FFFFFF;
              border-radius: 50%;
              opacity: 1;
              margin-top: 4px;
              box-sizing: border-box;
              .checked {
                width: 6px;
                height: 6px;
                border: 1px solid #FFFFFF;
                border-radius: 50%;
                opacity: 1;
                background: #FFFFFF;
                margin: 2px;
              }
            }
            .name {
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
              opacity: 1;
              margin-left: 4px;
            }
          }
        }
      }
    }
    &-list {
      .list-item {
        display: flex;
        margin-bottom: 18px;
        &-left {
          display: flex;
          flex-direction: column;
          width: 180px;
          .title {
            margin-bottom: 8px;
          }
          .ant-input {
            border-radius: 4px;
            border: 1px solid #AFDAD8;
          }
        }
      }
    }
  }
}
