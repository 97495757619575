.tabPanel {
  &-data {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    &-big {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 51px;
      color: #3A3A3A;
      width: 210px;
    }
    &-percent {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 24px;
      margin-right: 55px;
    }
    &-progress {
      width: 312px;
      height: 24px;
      background: #E9E9E9;
      opacity: 1;
      border-radius: 4px;
      position: relative;
      &-num {
        width: 60%;
        background-color: #FF6161;
        height: 100%;
        border-radius: 4px;
      }
      &-text {
        position: absolute;
        right: 52px;
        top: -32px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #3A3A3A;
      }
      &-number {
        position: absolute;
        right: 52px;
        bottom: -22px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #3A3A3A;
      }
      &-line {
        position: absolute;
        right: 62px;
        top: -4px;
        color: #355B99;
        height: 32px;
        width: 1px;
        background-color: #355B99;
        z-index: 100;
      }
    }
  }
  &-text {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 1px solid #B3B5B5;
    span {
      text-align: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #3A3A3A;
    }
  }
}
