:global {
    :local(.wrapper) {
        height: 100%;
        padding: 0 24px;
        display: flex;
        flex-direction: column;

        >.content_wrapper {
            height: 0;
            flex-grow: 1;

            >.inner_wrapper {
                height: 100%;
                overflow-y: auto;

                >.content {
                    min-height: 100%;
                    display: flex;
                    padding-bottom: 24px;

                    >.left {
                        width: 481px;
                    }

                    >.right {
                        margin-left: 12px;
                        width: 0;
                        flex-grow: 1;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;

                        >.item_wrapper {
                            width: 26%;
                            // width: 0;
                            // min-width: 421px;
                            height: 308px;
                            flex-grow: 1;
                            margin-left: 12px; // margin-bottom: 12px;

                            >.item {
                                height: 100%;
                                width: 100%;
                                // max-width: 500px;
                            }

                            &:nth-child(n+4) {
                                margin-top: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}