:global {
    :local(.BoxplotFactorChart) {
        width: 100%;
        height: 250px;

        .chartNode {
            width: 100%;
            height: 250px;
        }
    }
}