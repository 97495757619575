.side_menu_wrapper {
    height: 100%;
    overflow: auto;
    background-color: rgba(33, 40, 48, 1);

    .side_menu_content_wrapper {
        .menu_item_wrapper {

            .menu_item {
                position: relative;
                height: 68px;
                display: flex;
                align-items: center;
                padding-right: 34px;
                cursor: pointer;
                user-select: none;

                .selected_icon {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 44px;
                    width: 0;
                    border-style: solid;
                    border-color: transparent transparent transparent rgba(68, 243, 218, 1);
                }

                .name {
                    margin-left: 6px;
                    height: 22px;
                    font-size: 16px;
                    // font-weight: 400;
                    line-height: 22px;
                }
            }

            .children_wrapper {
                background-color: rgba(13, 17, 21, 1);

                .child_content {
                    padding-left: 78px;
                    height: 46px;
                    font-size: 16px;
                    // font-weight: 400;
                    line-height: 46px;
                    user-select: none;
                    cursor: pointer;
                }
            }
        }
    }
}