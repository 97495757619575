:global {
    :local(.viewEventsContent) {
        .ant-modal-body {
            padding: 24px;
            height: 436px;
            overflow: hidden auto;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: flex;
                    align-items: center;

                    &>div {
                        word-break: break-all;
                        white-space: pre-wrap;
                        font-size: 14px;
                        color: #666666;
                        font-family: 'SourceHanSansCN-Regular';
                        &:first-child{
                            white-space: nowrap;
                        }
                        &:last-child{
                            padding-left: 8px;
                        }
                    }

                    &:not(:last-child) {
                        margin-bottom: 15px;
                    }
                }
            }
        }

        .ant-modal-footer {
            display: none;
        }
    }
}