.main_page_wrapper_2 {
    height: 100%;
    display: flex;
    flex-direction: column;

    .scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .header_out_wrapper {
        height: 67px;
        background-color: rgba(33, 40, 48, 0.95);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        border-bottom: 1px solid #3DCDB9;

        .title_text {
            height: 22px;
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #9FA0BA;
            line-height: 22px;
            // width: 0;
            // flex-grow: 1;
        }

        .date_text {
            height: 24px;
            font-size: 24px;
            // font-family: Helvetica;
            color: #FFFFFF;
            line-height: 24px;
            // width: 300px;
            margin: 0 auto;
        }
    }

    .aLine {
        height: 1px;
        background: #3DCDB9;
    }

    .content_out_wrapper {
        height: 0;
        flex-grow: 1;
        overflow-y: auto;
        background-color: rgba(33, 40, 48, 1);

        .content_wrapper {
            height: 100%;
            // min-height: 966px;
            display: flex;
            flex-direction: column;

            .top_wrapper {
                height: 0;
                flex-grow: 1;
                padding: 24px 28px 0 24px;
                display: flex;

                .left_chart_wrapper {
                    width: 0;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    @keyframes myOpacity {
                        from {
                            opacity: 0;
                        }

                        to {
                            opacity: 1;
                        }
                    }

                    .chart_info_wrapper {
                        position: absolute;
                        left: 0;
                        top: 0;
                        animation: myOpacity 3s;

                        .top_block_wrapper {
                            height: 63px;
                            position: relative;

                            .circle_outline_wrapper {
                                width: 136px;
                                height: 68px;
                                overflow: hidden;
                                position: absolute;
                                top: -12px;
                                left: 50%;
                                transform: translateX(-50%);

                                .circle_wrapper {
                                    position: relative;
                                    width: 136px;
                                    height: 136px;
                                    border-radius: 50%;
                                    background-color: rgba(63, 69, 95, 1);
                                    overflow: hidden;

                                    .rotate_block_1 {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        bottom: 50%;
                                        left: 0;
                                        overflow: hidden;

                                        .colored_block {
                                            position: absolute;
                                            top: 100%;
                                            left: 0;
                                            right: 0;
                                            height: 100%;
                                            // background-color: rgba(68, 243, 218, 1);
                                            background-color: transparent;
                                            transform-origin: 50% 0;
                                            transform: rotate(0deg);
                                        }
                                    }

                                    .inner_hover_circle {
                                        position: absolute;
                                        top: 7px;
                                        right: 7px;
                                        bottom: 7px;
                                        left: 7px;
                                        border-radius: 50%;
                                        // background-color: rgba(25, 31, 39, 1);
                                        background-color: #000000;
                                    }
                                }
                            }

                            .back_circle_wrapper {
                                position: absolute;
                                height: 100%;
                                width: 112px;
                                overflow: hidden;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);

                                .back_circle {
                                    height: 112px;
                                    width: 112px;
                                    border-radius: 50%;
                                    // box-shadow: 0px 10px 8px 0px rgba(68, 243, 218, 0.34) inset;
                                }
                            }

                            .value_text {
                                position: absolute;
                                height: 46px;
                                font-size: 36px;
                                font-weight: bold;
                                color: transparent;
                                line-height: 46px;
                                left: 50%;
                                top: 15px;
                                transform: translateX(-50%);
                            }
                        }

                        .icons_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .icon_wrapper {
                                width: 28px;
                                overflow: hidden;
                                display: flex;
                                justify-content: center;

                                &:not(:first-child) {
                                    margin-left: 5px;
                                }

                                .icon_img {
                                    width: 38px;
                                    // height: 36px;
                                }
                            }
                        }

                        .name {
                            margin-top: 4px;
                            height: 18px;
                            font-size: 13px;
                            // font-weight: 400;
                            color: #9FA0BA;
                            line-height: 18px;
                            text-align: center;
                        }
                    }
                }

                .right_table_wrapper {
                    margin-left: 12px;
                    width: 420px;
                }
            }

            .bottom_wrapper {
                height: 270px;
                padding: 12px 28px 18px 24px;
            }
        }
    }
}