.capsuleModal .ant-modal-body{
    padding: 24px 32px;

    .tpl{
        border-radius: 16px 16px 0 0;
    }

    .tpl-point{
        background-color: #EDF7F8;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 0 0 16px 16px;
        padding: 0px 16px 40px;
        box-sizing: border-box;
        //display: flex;
        .select-point{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            &>div{
                line-height: 32px;
                margin-right: 40px;
            }

            .remove-btn{
                width: 8px;
                height: 8px;
                margin-top: 6px;
                margin-left: 6px;
                float: right;
                display: block;
                background: url('../../../assets//svg//remove.svg') no-repeat center;
                cursor: pointer;
            }
        }
    }
}
.capsuleModal.detail-modal{
    .ant-modal-footer{
        display: none;
    }
}
.capsuleModal {
    .ant-picker{
        border-color: #AFDAD8 !important;
    }
    .ant-select{
        &.search-select{
            .ant-select-selector{
                border-radius: 4px;
                border-color: #AFDAD8 !important;
                .ant-select-selection-search{
                    right: 32px;
                }
            }
        }
     &+.search-icon{
         position: absolute;
         width: 32px;
         height: 32px;
         background: url('../../../assets/svg/search.svg') center no-repeat;
         right: 0;
     }
    }
    .ant-select-arrow{
        // color: #fff;
    }
    .ant-modal-footer .ant-btn-primary {
      background: #357CF7;
      border: 1px solid #357CF7;
    }
    .qrcode {
        position: absolute;
        top: 20px;
        right: 60px;
        width: 16px;
        height: 16px;
        background-image: url("../../../assets/svg/qrcode.svg");
    }
    .edit {
        position: absolute;
        top: 20px;
        right: 100px;
        width: 16px;
        height: 16px;
        background-image: url("../../../assets/svg/edit.svg");
    }
    .del {
      position: absolute;
      top: 20px;
      right: 144px;
      width: 16px;
      height: 16px;
      background-image: url("../../../assets/svg/delete.svg");
      background-repeat: no-repeat;
    }
}

.addCapsule {
  &-tabs {
    background: #EDF7F8;
    min-height: 190px;
    // width: 912px;
    border-radius: 16px;
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
    margin-bottom: 10px;
    padding: 10px 16px;
    box-sizing: border-box;
    &-type {
      display: flex;
      margin-top: 24px;
      .first-tabs {
        width: 112px;
        height: 32px;
        background: #8EC1BE;
        border-radius: 4px;
        padding: 8px 16px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 1;
        line-height: 18px;
        margin-right: 16px;
        cursor: pointer;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      &-item {
        padding: 6px 16px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 20px;
        color: #3A3A3A;
        opacity: 1;
        background: #FFFFFF;
        border: 1px solid #AFDAD8;
        border-radius: 16px;
        margin-right: 8px;
        margin-bottom: 8px;
        cursor: pointer;
      }
      .viceTab {
        padding-left: 0px;
        display: flex;
        li {
          list-style: none;
          margin-right: 16px;
          cursor: pointer;
        }
        .viceTab-name {
          width: 48px;
          text-align: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 24px;
          color: #3A3A3A;
        }
        .viceTab-line {
          width: 48px;
          height: 3px;
          background-color: #B4B4B4
        }
      }
    }
  }
  .tpl {
    width: 912px;
    height: 392px;
    background: #EDF7F8;
    box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    padding: 10px 16px;
    box-sizing: border-box;
    img {
      vertical-align: middle;
    }
    .ant-input {
      border-radius: 4px;
      border: 1px solid #AFDAD8;
    }
    &-title {
      margin-bottom: 8px;
    }
    &-operator {
      display: flex;
      justify-content: space-between;
      &-left {
        .left {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          line-height: 27px;
          color: #727272;
          opacity: 1;
          margin-right: 8px;
        }
        .center {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 27px;
          color: #727272;
          opacity: 1;
          margin-right: 32px;
        }
        .right {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 27px;
          color: #727272;
          opacity: 1;
        }
      }
      &-right {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background: #357CF7;
          color: #FFFFFF;
          border-radius: 4px;
        }
        .reset {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: #357CF7;
          opacity: 1;
          margin-left: 4px;
          vertical-align: middle;
        }
      }
    }
    &-input {
      margin-top: 24px;
      margin-bottom: 18px;
    }
    &-info {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      line-height: 24px;
      color: #3A3A3A;
      opacity: 1;
    }
    &-textarea {

    }
    &-line {
      width: 880px;
      height: 1px;
      background: #FFFFFF;
      margin: 16px 0px;
    }
    &-point {
      background-color: #EDF7F8;
      box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
      border-radius: 0 0 16px 16px;
      padding: 10px 16px;
      box-sizing: border-box;
      //display: flex;
      .point {
        height: 32px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #3A3A3A;
        opacity: 1;
        line-height: 32px;
        margin-right: 20px;
        white-space: nowrap;
      }
    }
    &-fa {
      display: flex;
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #AFDAD8;
        border-radius: 4px;
      }
      &-name {
        height: 24px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 24px;
        color: #3A3A3A;
        opacity: 1;
        margin-right: 18px;
      }
      &-unit {
        margin-top: 4px;
        line-height: 24px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #3A3A3A;
      }
    }
    &-capacity {
      display: flex;
    }
    ul {
      padding-left: 0;
      display: flex;
      li {
        list-style: none;
        display: flex;
        flex-direction: column;
      }
    }
    .firstUl {
      li {
        margin-right: 16px;
        span {
          display: inline-block;
          margin-bottom: 19px;
          width: 48px;
          text-align: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3A3A3A;
        }
      }
    }
    .fiveUl {
      li {
        margin-right: 16px;
        .top {
          margin-bottom: 19px;
          text-align: center;
        }
        .ant-checkbox {
          border: 1px solid #AFDAD8;
          border-radius: 4px;
        }
      }
    }
  }
}

.QrCodeModal{
    .ant-modal-footer,.ant-modal-close-x{
        display: none;
    }
}
