.overview {
  width: 896px;
  height: 624px;
  background-color: #1F2831;
  margin-bottom: 30px;
  position: relative;

  .name {
    position: absolute;
    top: 16px;
    left: 16px;
    height: 24px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 24px;
    color: #A6B2C2;
  }
}