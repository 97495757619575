:global {
  :local(.RoleMngSetting) {
    height: 100%;
    display: flex;
    flex-direction: column;
    //padding: 0 48px;

    .bottom_content_wrapper {
      height: 0;
      flex-grow: 1;
      height: calc(100% - 60px);
      //background: #EDF7F8;
      padding: 24px;

      >div {
        height: 100%;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
      }

      .content_wrapper_header {
        height: 54px;
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
        padding: 0 24px;
        display: flex;
        align-items: center;
      }

      .content_wrapper_content {
        height: calc(100% - 54px);
        padding: 24px 24px 30px;
        display: flex;
        justify-content: space-between;

        .middle_block_item {
          align-self: center;
          width: 24px;
          height: 24px;
          border-radius: 4px;
          border: 1px solid rgba(217, 217, 217, 1);
          margin: 0 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: '';
            display: block;
            width: 9px;
            height: 9px;
            border-width: 1px 1px 0 0;
            border-style: solid;
            border-color: #D9D9D9;
            transform: rotate(45deg);
            position: relative;
            right: 3px;
          }
        }

        .block_wrapper {
          flex-grow: 1;
          flex-basis: 0;
          border-radius: 4px;
          border: 1px solid rgba(217, 217, 217, 1);

          // &:nth-child(n+2) {
          //   margin-left: 56px;
          // }

          .block_header {
            height: 54px;
            background: rgba(0, 0, 0, 0.04);
            font-size: 16px;
            font-weight: 500;
            // color: rgba(53, 124, 247, 1);
            color: #357CF7;
            line-height: 54px;
            padding-left: 16px;
          }

          .block_content {
            height: calc(100% - 54px);
            background: rgba(255, 255, 255, 1);
            overflow-y: auto;

            .role_list_row {
              height: 54px;
              display: flex;
              align-items: center;
              padding: 16px 52px 16px 16px;
              cursor: pointer;

              &:hover {
                background: rgba(0, 103, 107, 0.05);

                .opt_box {
                  margin-left: auto;
                  display: flex;
                }
              }

              .name {
                height: 22px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
              }

              .opt_box {
                margin-left: auto;
                display: none;

                .delete {
                  margin-left: 8px;
                  display: flex;
                  height: 20px;
                  font-size: 12px;
                  font-weight: 400;
                  color: rgba(24, 144, 255, 1);
                  line-height: 20px;
                  align-items: center;
                }
              }
            }

            .role_list_row_selected {
              background: rgba(0, 103, 107, 0.25);

              &:hover {
                background: rgba(0, 103, 107, 0.25);
              }

              // .delete {
              //   display: flex;
              // }
            }

            .role_list_row_selected_input {
              background: rgba(0, 103, 107, 0.25);

              &:hover {
                background: rgba(0, 103, 107, 0.25);

                .save {
                  display: block;
                }

                .delete_2 {
                  display: flex;
                }
              }

              .save {
                margin-left: auto;
                height: 20px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(24, 144, 255, 1);
                line-height: 20px;
                display: none;
              }

              .delete_2 {
                margin-left: 20px;
                height: 20px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(24, 144, 255, 1);
                line-height: 20px;
                align-items: center;
                display: none;
              }
            }

            //hardcode
            .ant-tree-treenode {
              height: 54px;
              align-items: center;

              .ant-tree-switcher {
                align-self: center;
              }

              .ant-tree-node-content-wrapper {
                align-self: stretch;
                line-height: 50px;
              }

              .ant-tree-node-selected {
                background-color: #AFDAD8;
              }
            }

            .auth_list_row {
              height: 54px;
              display: flex;
              align-items: center;
              padding: 16px;

              .name {
                height: 22px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                line-height: 22px;
                margin-left: 8px;
              }
            }
          }
        }
      }

    }
  }
}
