.product_electrical_trend_wrapper {
    border: 1px solid rgba(68, 242, 217, 0.2);

    .item_header_wrapper {
        height: 47px;
        border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
        padding-left: 12px;
        display: flex;
        align-items: center;
        background: #0D1115;

        .item_header_icon {
            width: 24px;
            height: 24px;
        }

        .title {
            // width: 193px;
            padding-left: 5px;
            height: 18px;
            font-size: 18px;
            // font-family: PingFangSC-Medium, PingFang SC;
            // font-weight: 500;
            color: #FFFFFF;
            line-height: 18px;
        }

        .sub_title {
            height: 16px;
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #9FA0BA;
            line-height: 16px;
            margin-left: 12px;
        }
    }

    .item_content_wrapper {
        padding: 16px;
        background-color: rgba(33, 40, 48, 1);

        .inner_content_wrapper {
            height: 404px;
            background-color: rgba(25, 31, 39, 1);
            display: flex;
            flex-direction: column;

            .title_wrapper {
                padding: 12px 0;
                display: flex;
                align-items: center;

                .icon {
                    width: 2px;
                    height: 16px;
                }

                .text {
                    height: 20px;
                    font-size: 14px;
                    // font-family: PingFangSC-Regular,PingFang SC;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 20px;
                    margin-left: 8px;
                }

            }

            .bottom_content_wrapper {
                height: 0;
                flex-grow: 1;
                margin-top: 12px;
                padding-left: 16px;
                display: flex;

                .left {
                    width: 280px;

                    .block_wrapper {
                        height: 96px;
                        position: relative;
                        background: linear-gradient(90deg, rgba(68, 243, 218, 0.1) 0%, rgba(68, 243, 218, 0) 50%, rgba(#0D1115, 1) 100%);
                        border-radius: 2px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .value_unit_wrapper {
                            display: flex;
                            align-items: flex-end;

                            .value {
                                height: 20px;
                                font-size: 24px;
                                // font-family: Helvetica;
                                color: #FFFFFF;
                                line-height: 20px;
                            }

                            .unit {
                                margin-left: 4px;
                                height: 14px;
                                font-size: 14px;
                                // font-family: Helvetica;
                                color: #FFFFFF;
                                line-height: 14px;
                            }
                        }

                        .name {
                            margin-top: 12px;
                            height: 14px;
                            font-size: 14px;
                            // font-family: PingFangSC-Regular, PingFang SC;
                            // font-weight: 400;
                            color: #8C8F93;
                            line-height: 14px;
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                            width: 2px;
                            background-color: rgba(68, 243, 218, 1);
                            border-radius: 100px 0px 0px 100px;
                        }

                        &:nth-child(n+2) {
                            margin-top: 16px;
                        }
                    }
                }

                .right {
                    width: 0;
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;

                    .line_sign_wrapper {
                        margin-left: 64px;
                        display: flex;
                        align-items: center;

                        .line_1 {
                            width: 16px;
                            height: 4px;
                            background: #44F3DA;
                            border-radius: 2px;
                        }

                        .line_2 {
                            margin-left: 59px;
                            width: 17px;
                            height: 0;
                            border-top: 1px dashed transparent;
                        }

                        .line_1_text,
                        .line_2_text {
                            margin-left: 4px;
                            height: 12px;
                            font-size: 12px;
                            // font-family: PingFangSC-Regular, PingFang SC;
                            // font-weight: 400;
                            color: #9FA0BA;
                            line-height: 12px;
                        }
                    }

                    .chart_wrapper {
                        height: 0;
                        flex-grow: 1;
                        padding-top: 16px;
                        padding-bottom: 16px;

                        .chart_self {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}