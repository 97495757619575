.page_improve_chance_wrapper {
    height: 100%;
    padding: 0 24px 0;
    overflow-y: auto;

    .content_wrapper {
        padding: 24px 0 42px;

        .content_root_title {
            height: 20px;
            font-size: 20px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
        }

        .content_item {
            margin-top: 16px;
            border: 1px solid rgba(68, 242, 217, 0.2);

            .item_header_wrapper {
                height: 47px;
                border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
                padding-left: 12px;
                display: flex;
                align-items: center;
                background: #0D1115;

                .item_header_icon {
                    width: 24px;
                    height: 24px;
                }

                .title {
                    width: 193px;
                    padding-left: 5px;
                    height: 18px;
                    font-size: 18px;
                    // font-family: PingFangSC-Medium, PingFang SC;
                    // font-weight: 500;
                    color: #FFFFFF;
                    line-height: 18px;
                }
            }
        }
    }
}