:global {
    :local(.rule_factor_img_wrapper) {
        border: 1px solid grey;
        height: 90px;
        padding: 35px 25px 0 50px;
        position: relative;

        &::before {
            content: '得分';
            position: absolute;
            top: 5px;
            left: 5px;
        }

        &::after {
            content: '参数';
            position: absolute;
            bottom: 5px;
            left: 5px;
        }

        .bar_wrapper {
            display: flex;
            height: 20px;
            position: relative;

            .point {
                position: absolute;
                top: 0;
                height: 260%;
                transform: translate(-50%, -50%);
                width: 2px;
                background-color: rgb(202, 200, 200);

                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    border: 2px solid rgb(202, 200, 200);
                    background-color: #ffffff;
                }
            }

            .block {
                flex-grow: 1;
                height: 100%;
                position: relative;

                .score_text {
                    position: absolute;
                    left: 50%;
                    top: -30px;
                    transform: translateX(-50%);
                }

                .point {
                    right: 0;
                    transform: translate(50%, -50%);
                    z-index: 200;
                }

                .value {
                    position: absolute;
                    bottom: -30px;
                    right: 0;
                    transform: translateX(50%);
                    font-size: 14px;
                    line-height: 18px;
                }

                .bordered_value {
                    border: 1px solid grey;
                    padding: 0 2px;
                }
            }

            .value_bar {
                position: absolute;
                bottom: -35px;
                left: 50%;
                transform: translateX(-50%);
                width: 50%;
                display: flex;
                justify-content: space-between;
                border-top: 3px solid #651FFF;
                padding: 0 50px;

                &::before {
                    content: '';
                    position: absolute;
                    top: -2px;
                    left: 0;
                    width: 20px;
                    height: 1px;
                    background-color: #651FFF;
                    transform: rotate(30deg);
                    transform-origin: top left;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: -2px;
                    right: 0;
                    width: 20px;
                    height: 1px;
                    background-color: #651FFF;
                    transform: rotate(30deg);
                    transform-origin: bottom right;
                }
            }

            .score_bar {
                position: absolute;
                top: -35px;
                left: 50%;
                transform: translateX(-50%);
                width: 50%;
                display: flex;
                justify-content: space-between;
                border-bottom: 3px solid #651FFF;
                padding: 0 50px;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    left: 0;
                    width: 20px;
                    height: 1px;
                    background-color: #651FFF;
                    transform: rotate(30deg);
                    transform-origin: top left;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -2px;
                    right: 0;
                    width: 20px;
                    height: 1px;
                    background-color: #651FFF;
                    transform: rotate(30deg);
                    transform-origin: bottom right;
                }
            }
        }
    }
}