:global {
    :local(.EventModal) {
        .info-line {
            width: 90%;
            display: flex;

            &:nth-child(n+2) {
                margin-top: 20px;
            }

            .info-hint {
                width: 110px;
                line-height: 32px;
            }

            .desp_info-content {
                flex-grow: 1;
            }
        }
    }
}