:global {
    :local(.wrapper) {
        display: flex;
        align-items: center;
        color: #A6B2C2;

        >.scene_select_wrapper {
            margin-left: auto;

            .scene_tag {
                width: 16px;
                height: 16px;
                border: 1px solid #A6B2C2;
                display: flex;
                align-items: center;
                justify-content: center;
                user-select: none;
                cursor: pointer;

                >.text {
                    height: 12px;
                    font-size: 12px;
                    line-height: 12px;
                }

                &.curr_scene {
                    background-color: #A6B2C2;
                    color: #13191f;
                }
            }
        }

        >.screen_related_tool {
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 16px;
        }
    }
}