:global {
    :local(.wrapper) {
        height: 100%;
        background: #FFFFFF;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        display: flex;
        flex-direction: column;

        >.head {
            padding: 20px 16px;
            display: flex;
            border-bottom: 1px solid #c9c9c9;
            >.text {
                height: 16px;
                font-size: 16px;
                margin-left: 8px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 16px;
                color: #357CF7;
            }
        }

        >.content {
            height: 0;
            flex-grow: 1;
            padding: 16px 24px;
            display: flex;

            >.left {
                width: 320px;

                >.content {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    >.title_wrapper {
                        display: flex;
                        align-items: center;

                        >.text {
                            height: 16px;
                            font-size: 16px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 16px;
                            color: #333;
                        }
                    }

                    >.cards_wrapper {
                        height: 0;
                        flex-grow: 1;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: flex-start;
                        align-content: space-between;
                        img{
                            margin-top: 16px;
                        }
                    }
                }
            }

            >.line {
                width: 1px;
                height: 100%;
                position: relative;
                margin-left: 16px;

                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -8px;
                    left: 0;
                    content: '';
                    background-color: #c9c9c9;
                }
            }

            >.right {
                width: 0;
                flex-grow: 1;
                margin-left: 16px;
                display: flex;
                flex-direction: column;

                >.title {
                    height: 16px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 16px;
                    color: #333;
                }

                >.content {
                    margin-top: 40px;
                    position: relative;

                    >.row_wrapper {
                        display: flex;
                        align-items: flex-end;

                        &:nth-child(n+2) {
                            margin-top: 24px;
                        }

                        >.title {
                            // width: 32px;
                            height: 14px;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 14px;
                            color: #666;
                        }

                        >.bar_wrapper {
                            width: 0;
                            flex-grow: 1;
                            height: 16px;
                            background: #E9E9E9;
                            border-radius: 4px;
                            overflow: hidden;

                            >.colored_bar {
                                width: 0%;
                                height: 100%;
                                background-color: #7489AE;
                            }
                        }

                        >.value {
                            height: 16px;
                            font-size: 16px;
                            font-family: Source Han Sans CN;
                            font-weight: bold;
                            line-height: 16px;
                            color: #3A3A3A;
                            // width: 50px;
                            text-align: right;

                            &.value_red {
                                color: #FB6060;
                            }
                        }
                    }

                    .standard_value_text {
                        height: 14px;
                        font-size: 14px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        line-height: 14px;
                        color: #FB6060;
                    }

                }
            }
        }
    }
}