:global {
    :local(.wrapper) {
        height: 100%;
        // padding: 24px 56px 0 72px;
        font-family: 'Microsoft Yahei';

        .ant-modal-body{
            padding-top: 20px;
            padding-left: 40px;
            padding-right: 40px;
        }

        .info_line{
            width: 100%;
            line-height: 50px;
            height: 50px;
            display: flex;
            flex-direction: row;
            font-size: 15px;
            .label{
                width: 100px;
                flex-shrink: 0;
                flex-grow: 0;
            }
            >.content{
                display: flex;
                position: relative;
                flex-direction: column;
                justify-content: center;

                >*{
                    display: block;
                }

                &.sep_col{
                    flex-direction: row;
                    > *{
                        text-align: center;
                        display: flex;
                        align-items: center;
                    }
                }

                >.col_1{
                    justify-content: center;
                    width: 82px;
                }
                >.col_2{
                    justify-content: center;
                    margin-left: 10px;
                    width: 100px;
                }
                >.col_3{
                    justify-content: center;
                    margin-left: 10px;
                    width: 140px;
                }
                >.col_4{
                    justify-content: center;
                    margin-left: 10px;
                    width: 140px;
                }
            }
        }
    }
}