:global {
    :local(.attachmentWrapper) {
        padding-top: 24px;
        border-top: 1px solid #E2E4E7;

        .top {
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            font-size: 12px;
            color: #000;
            text-indent: 26px;
            margin-bottom: 24px;
            background: url('../../../../../../../../assets/images/v3/attachment_tag.png') no-repeat left;
            img {
              vertical-align: middle;
            }
            .add-btn {
                color: #357CF7;
                vertical-align: middle;
            }
        }
        .attachmentPic {
          .attachmentPicTitle {
            margin-bottom: 13px;
            .text {
              font-size: 14px;
              font-family: PingFangSC-Regular;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.65);
              opacity: 1;
              margin-right: 4px;
            }
            .num {
              font-size: 14px;
              font-family: PingFangSC-Medium;
              line-height: 19px;
              color: #1890FF;
              opacity: 1;
            }
          }
          .picUl {
            padding-left: 0px;
            display: flex;
            flex-wrap: wrap;
            li {
              list-style: none;
              margin-right: 8px;
              margin-bottom: 8px;
              position: relative;
              img {
                display: inline-block;
                border-radius: 2px;
              }
              &:nth-child(4n) {
                margin-right: 0px;
              }
              .close {
                position: absolute;
                top: 0px;
                right: 0px;
                width: 18px;
                height: 18px;
                background: #E15D5D;
                border-radius: 50%;
                opacity: 1;
                text-align: center;
                line-height: 18px;
                color: #FFFFFF;
              }
              .play {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              .name {
                width: 148px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
        }
    }
}
