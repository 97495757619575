:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            display: flex;
            flex-direction: column;

            >.top {
                padding: 0 20px 0 20px;
                height: 34px;
                display: flex;
                flex-direction: column;

                >.items_wrapper {
                    margin-top: auto;
                    display: flex;
                    align-items: center;

                    >.item {
                        margin-right: auto;
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        line-height: 14px;
                        display: flex;
                        align-items: center;

                        >.title {
                            height: 14px;
                            color: #999999;
                        }

                        >.value {
                            margin-left: 2px;
                            height: 14px;
                            color: #333333;
                        }
                    }

                }
            }

            >.mid {
                height: 0;
                flex-grow: 1;
                position: relative;
                overflow: hidden;

                >.resize {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }

                >.chart {
                    height: 100%;
                    position: relative;
                }
            }

            >.bottom {
                padding-bottom: 12px;
                display: flex;

                >.item {
                    width: 0;
                    flex-grow: 1;
                    border-radius: 2px;
                    background-color: #F8F8F8;
                    padding: 4px 8px 8px 8px;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Regular;
                    line-height: 12px;

                    &:nth-child(n+2) {
                        margin-left: 16px;
                    }

                    >.title {
                        text-align: center;
                        color: #333333;
                    }

                    >.content {
                        margin-top: 4px;
                        display: flex;
                        justify-content: space-between;

                        >.item {
                            display: flex;

                            >.title {
                                color: #999999;
                            }
                        }
                    }
                }
            }
        }
    }
}