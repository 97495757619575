:global {
    :local(.sample_maintenance_calendar_wrapper) {
        
        // height: 314px;//不设置,因为antd日历组件不可设置高度
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.15);
        background: #FFFFFF;
        border-radius: 2px;
        overflow: hidden;
        .ant-btn-primary{
            background-color:#357CF7 !important;
            border-color:#357CF7 !important;
        }
        .custom_header {
            display: flex;
            align-items: center;
            height: 39px;
            padding: 0 17px;
            font-size: 12px;

            .text {
                margin: 0 auto;
                height: 22px;
                font-size: 14px;
                font-weight: 500;
                color: #333;
                line-height: 22px;
            }
        }

        .cus_date_wrapper {
            display: inline-block;

            .text {
                height: 24px;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                user-select: none;
            }
        }

        .cus_date_wrapper_selected {
            background-color: #357CF7;
            color: #ffffff;
        }

        .bottom_wrapper {
            height: 40px;
            border-top: 1px solid rgba(0, 0, 0, 0.09);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #357CF7;
            cursor: pointer;
            user-select: none;
        }

    }
}