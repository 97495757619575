:global {
    :local(.new_main_page_chart_modal_wrapper) {
        position: fixed;
        background: rgba(0, 0, 0, 0.65);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        padding: 8px 16px 16px;
        width: 544px;
        transform: translateX(-50%) translateY(20px);
        cursor: move;
        user-select: none;
        z-index: 101;

        .title_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 22px;

            .title {
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }

            >img {
                cursor: pointer;
            }
        }

        .chart_wrapper {
            height: 163px;
        }
    }
}