:global {
    :local(.SepTimeBoard) {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;        

        >.BoardContent {
            height: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: row;

            .BoardTitle {
                color: #727272;
                height: 26px;
                font-size: 26px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 26px;
                margin-left: 55px;
            }

            >.left {
                width: 1165px;
                background-color: #fff;
                box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
                border-radius: 16px;
                padding-top: 23px;
                margin-right: 78px;
                display: flex;
                flex-direction: column;
            }

            >.right {
                width: 0;
                flex-grow: 1;
                background-color: #fff;
                box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
                border-radius: 16px;                
            }
        }
    }

}