.DiagramDisplayWrapper {
    height: 100%;
    position: relative;

    .contentWrapper {
        height: 100%;
        max-height: 100%;
        box-sizing: border-box;
        // padding-top: 64px;
        display: flex;

        .rightLayout {
            width: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .picWrapper {
                height: 0;
                flex-grow: 1;
                position: relative;
            }
        }
    }
}