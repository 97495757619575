.alarm_modal_wrapper {
    .header {
        display: flex;
        align-items: center;

        .time_link {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
            margin: 0 4px;
        }
    }
}