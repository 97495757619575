.produce_analysis_wrapper {
    height: 100%;
    padding: 0 24px 0;
    overflow-y: auto;

    &.scroll_bar_restyle,
    .scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .content_wrapper {
        padding: 24px 0 42px;

        .content_root_title {
            height: 20px;
            font-size: 20px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
        }

    }
}