:global {
    :local(.wrapper) {
        $bg-color: rgba($color: #F5F5F5, $alpha: 1);

        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        background: #FFFFFF;
        padding: 20px 0;
        height: 100%;
        display: flex;
        flex-direction: column;

        .tab_row {
            display: flex;
            padding: 0 16px 0 16px;
            .tab_item {
                height: 40px;
                user-select: none;
                cursor: pointer;

                &:nth-child(n+2) {
                    margin-left: 16px;
                }

                .text {
                    height: 16px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 16px;
                    color: #357CF7;
                    display: flex;
                    img{
                        margin-right: 8px;
                    }
                }

                &.selected_tab_item {
                    position: relative;

                    &::after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        content: '';
                        height: 3px;
                        background-color: #357CF7;
                    }
                }
            }
        }

        .content_wrapper {
            margin-top: 33px;
            height: 0;
            flex-grow: 1;
            position: relative;
            overflow: hidden;

            .dot_wrapper {
                display: flex;

                .red_dot {
                    display: flex;
                    align-items: center;
                    padding: 2px 5px;
                    border-radius: 8px;
                    background-color: #FF6161;
                    // position: relative;
                    // bottom: 8px;

                    &:nth-child(n+2) {
                        margin-left: 8px;
                    }

                    .dot_text {
                        font-size: 12px;
                        line-height: 12px;
                        // height: 12px;
                        font-weight: 400;
                        font-family: Source Han Sans CN;
                        color: #FFFFFF;
                    }
                }
            }

            .title_text {
                color: #3A3A3A;
                // height: 16px;
                // font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                line-height: 16px;
                padding: 8px 0;
            }

            .tree_wrapper {
                height: 100%;
                overflow: auto;

                .ant-tree-treenode-selected {

                    // hard code
                    &::before {
                        background-color: $bg-color;
                    }

                    &:hover {
                        &::before {
                            background-color: $bg-color;
                        }
                    }
                }
            }

            .abnormal_wrapper {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                overflow: auto;

                >.row {
                    height: 40px;
                    display: flex;
                    align-items: center;
                    padding-left: 24px;
                    cursor: pointer;

                    &.selected {
                        background-color: $bg-color;
                    }
                }
            }
        }
    }
}