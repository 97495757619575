.ScriptList{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    >.toolBar{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        >.leftTools{
            display: flex;
            flex-direction: row;
            align-items: center;
            .createBtn{
                margin-left: 0px;
            }
        }
        >.rightTools{
            .refreshBtn{
                margin-right: 0px;
            }
            >.processBadge{
                margin-right: 20px;
                .ant-badge-status-text{
                    font-size:  13px;
                    color: #888;
                }
            }
        }
    }

    >.downContent{
        margin-top: 20px;
    }
}
