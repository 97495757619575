:global {
    :local(.wrapper) {
        height: 100%;
        background-color: #F4F5F7;
        position: relative;

        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-item-link,
        .ant-pagination-next,
        .ant-select:not(.ant-select-customize-input),
        .ant-select-selector {
            border-radius: 6px;
        }

        .ant-pagination-item-active {
            background: #1890FF;

            a {
                color: #fff;
            }
        }

        .ant-pagination-item-active:focus a,
        .ant-pagination-item-active:hover a {
            color: #fff;
        }

        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 4px;
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color:#C9C9C9, $alpha: 1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 2px;
            }
        }
    }
}