$template-color:#357CF7;

.loading-tip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 100%, .9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;

    .ant-spin-dot-item {
        background-color: $template-color;
    }

    .ant-spin-text {
        color: $template-color;
    }
}