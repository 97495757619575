.RightWrapperPart {
    width: 290px;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    .bindData {
        flex-shrink: 0;
        width: 100%;
        height: 100px;
        box-sizing: border-box;
        border-bottom: 1px solid grey;
        display: flex;
        align-items: center;
        justify-content: center;
        .dataPointWrapper {}
    }
    .listWrapper {
        flex-grow: 1;
        width: 100%;
        overflow: auto;
        .ListItem {
            .itemWrapper {
                flex-grow: 1;
                box-sizing: border-box;
                padding-left: 5px;
                padding-right: 5px;
                .typeWrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .imgWrapper {
                    display: flex;
                    align-items: center;
                    >img {
                        width: 50px;
                        height: 50px;
                        display: block;
                        box-sizing: border-box;
                        border: 1px solid grey;
                    }
                    .flexGrow {
                        flex-grow: 1;
                    }
                }
            }
        }
        .ListItem:hover {
            background-color: azure;
        }
        .selected {
            background-color: antiquewhite;
        }
        .selected:hover {
            background-color: antiquewhite;
        }
        .AddButton {
            width: 80%;
            display: block;
            margin: 0px auto;
        }
    }
}