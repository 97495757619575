:global {
    :local(.score_interval_limit_wrapper) {
        .content_table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0 10px;
            text-align: center;

            >thead {
                >tr {
                    >th:not(:last-child) {
                        background-color: red;
                        color: white;
                    }
                }
            }

            >tbody {
                >tr {
                    >td {
                        >.range_td {
                            display: flex;
                            align-items: center;
                            // justify-content: space-between;

                            .min_max_block {
                                width: 80px;
                            }

                            .range_mid_text {
                                flex-grow: 1;
                                width: 0;
                                display: flex;
                                align-items: center;

                                .left_text,
                                .right_text {
                                    width: 20px;
                                }

                                .mid_text {
                                    width: 0;
                                    flex-grow: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}