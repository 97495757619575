:global {
    :local(.SepTimeBoard) {
        height: 100%;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        background: #FFFFFF;
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        position: relative;

        >.BoardTitle {
            color: #727272;
            height: 16px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            line-height: 16px;
            margin-left: 16px;
        }

        >.BoardContent {
            height: 0;
            flex-grow: 1;
            display: flex;
            flex-direction: row;

            >.left {
                width: 344px;
            }

            >.right {
                width: 0;
                flex-grow: 1;
            }
        }
    }

}