:global {
    :local(.contentWrapper) {
        .content-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px;

            &>span {
                color: #2B3249;
                font-size: 16px;
                font-weight: 500;

                span {
                    color: #999;
                    font-size: 12px;
                }
            }

            .reset-icon {
                display: inline-block;
                text-indent: 24px;
                line-height: 24px;
                height: 24px;
                font-size: 12px;
                color: #1890FF;
                background: url('../../../../../../../../assets/images/v3/reset_icon.png') no-repeat left;
                cursor: pointer;
            }
        }

        .classify {
            width: 78px;
            margin-left: 18px;

            .ant-select-selector {
                border-color: #375B98 !important;
                border-radius: 4px;
            }
        }

        .title,
        .info {
            width: 100%;
            border-color: rgba(0, 0, 0, 0.15);
            border-radius: 4px;
        }

        .ant-picker .anticon{
            color: #375B98;
        }
    }
}