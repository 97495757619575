.quality_item_wrapper {
    height: 92px;
    background-color: #191F27;
    border-radius: 2px;
    display: flex;

    .left {
        width: 0;
        flex-grow: 1;
        padding: 9px 0 0 6px;

        .item_title {
            position: relative;

            .text {
                height: 14px;
                font-size: 14px;
                // font-weight: 500;
                color: #BFC0DA;
                line-height: 14px;
                margin-left: 4px;
            }

            .title_icon {
                position: absolute;
                top: 50%;
                left: -6px;
                transform: translateY(-50%);
                width: 2px;
            }
        }

        .rows_wrapper {
            margin-top: 17px;

            .row_wrapper {
                display: flex;
                align-items: center;

                &:not(:first-child) {
                    margin-top: 8px;
                }

                .row_title {
                    height: 12px;
                    font-size: 12px;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 12px;
                    width: 52px;
                }

                .row_value {
                    height: 14px;
                    font-size: 14px;
                    color: #FFFFFF;
                    line-height: 14px;
                    // margin-left: 2px;

                    &.warning_row {
                        color: rgba(255, 97, 112, 1);
                    }
                }
            }
        }
    }

    .right {
        width: 83px;
        display: flex;
        align-items: center;
        justify-content: center;

        .spin_block {
            position: relative;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            // background-color: pink;
            background-color: rgba(63, 69, 95, 1);
            overflow: hidden;

            .rotate_block_1 {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 50%;
                overflow: hidden;

                .colored_block {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 100%;
                    width: 100%;
                    background-color: rgba(74, 155, 135, 1);
                    transform-origin: 100% 50%;
                    transform: rotate(0deg);
                }
            }

            .rotate_block_2 {
                position: absolute;
                top: 0;
                right: 50%;
                bottom: 0;
                left: 0;
                overflow: hidden;

                .colored_block {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 100%;
                    width: 100%;
                    background-color: rgba(74, 155, 135, 1);
                    transform-origin: 0 50%;
                    transform: rotate(0deg);
                }
            }

            .inner_hover_circle {
                position: absolute;
                top: 5px;
                right: 5px;
                bottom: 5px;
                left: 5px;
                border-radius: 50%;
                background-color: rgba(25, 31, 39, 1);
            }
        }
    }
}