:global {
    :local(.wrapper) {
        height: 100%;
        padding: 0 24px;
        display: flex;
        flex-direction: column;

        >.content_wrapper {
            height: 0;
            flex-grow: 1;

            >.inner_wrapper {
                height: 100%;
                padding-bottom: 24px; // overflow auto会隐藏box shadow ???
                overflow-y: auto;

                >.content {
                    min-height: 100%;
                    display: flex;

                    >.left {
                        width: 0;
                        flex-grow: 1;
                    }

                    >.right {
                        margin-left: 12px;
                        width: 712px;
                        display: flex;
                        flex-direction: column;

                        >.top {
                            height: 0;
                            flex-grow: 1;
                        }

                        >.mid {
                            margin-top: 12px;
                            height: 0;
                            flex-grow: 1;
                        }

                        >.bottom {
                            margin-top: 12px;
                            height: 0;
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }
}