.main_page_bottom_panels_production_modal_wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 1);
        opacity: 0.7;
    }

    .cus_modal_wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1143px;
        height: 702px;
        // opacity: 0.6;
        border: 1px solid rgba($color: #44F2D9, $alpha: 0.6);
        background-color: rgba(33, 40, 48, 1);
        display: flex;
        flex-direction: column;

        .modal_header {
            height: 46px;
            background: #0D1115;
            border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
            padding: 0 16px 0 16px;
            display: flex;
            align-items: center;
            position: relative;

            .title_icon {
                width: 24px;
            }

            .title {
                height: 18px;
                font-size: 18px;
                // font-weight: 500;
                color: #FFFFFF;
                line-height: 18px;
                margin-left: 5px;
            }

            .time {
                margin-left: 12px;
                height: 16px;
                font-size: 16px;
                // font-weight: 400;
                color: #9FA0BA;
                line-height: 16px;
            }

            .cus_close_icon {
                margin-left: auto;
                margin-right: 4px;
                font-size: 24px;
                color: rgba(89, 97, 104, 1);
            }
        }

        .modal_body {
            height: 0;
            flex-grow: 1;
            // overflow-y: auto;
            padding-top: 22px;
            display: flex;

            .left {
                width: 0;
                flex-grow: 1;
                padding-left: 48px;
                padding-bottom: 12px;
                display: flex;
                flex-direction: column;

                .left_header {
                    display: flex;
                    align-items: flex-end;

                    .title_wrapper {
                        display: flex;
                        align-items: center;

                        .title_icon {
                            width: 22px;
                            height: 12px;
                        }

                        .title {
                            margin-left: 8px;
                            height: 18px;
                            font-size: 18px;
                            // font-weight: 500;
                            color: #FFFFFF;
                            line-height: 18px;
                        }
                    }

                    .subtitle {
                        margin-left: 12px;
                        height: 16px;
                        font-size: 16px;
                        // font-weight: 400;
                        color: #FFFFFF;
                        line-height: 16px;
                    }
                }

                .row_devide {
                    display: flex;

                    .name {
                        width: 112px;
                    }

                    .chart {
                        width: 0;
                        flex-grow: 1;
                    }

                    .value {
                        width: 84px;
                    }

                    .project {
                        width: 84px;
                    }

                    .rate {
                        width: 94px;
                    }
                }

                .table_header {
                    margin-top: 36px;

                    >div {
                        height: 16px;
                        font-size: 16px;
                        // font-weight: 400;
                        color: #9FA0BA;
                        line-height: 16px;
                    }
                }

                .table_content {
                    height: 0;
                    flex-grow: 1;
                    overflow-y: auto;

                    .items_wrapper {
                        // padding-bottom: 24px;

                        .item_wrapper {
                            height: 59px;
                            align-items: center;

                            .name {
                                display: flex;

                                .name_text {
                                    height: 30px;
                                    font-size: 14px;
                                    // font-weight: 400;
                                    color: #FFFFFF;
                                    padding: 0 8px;
                                    border: 1px solid transparent;
                                    display: flex;
                                    align-items: center;
                                }

                                .name_text_bordered {
                                    color: rgba(255, 217, 78, 1);
                                    border: 1px solid #FFD94E;
                                }
                            }

                            .chart {
                                padding-right: 48px;

                                .top_bar {
                                    width: 0;
                                    height: 8px;
                                    background: #4A9B87;
                                    border-radius: 4px;

                                    &.top_bar_warning {
                                        background: #964B52;
                                    }
                                }

                                .bottom_bar {
                                    margin-top: 6px;
                                    height: 8px;
                                    background: #3F455F;
                                    border-radius: 4px;
                                }
                            }

                            .value,
                            .project,
                            .rate {
                                height: 16px;
                                font-size: 16px;
                                color: #FFFFFF;
                                line-height: 16px;

                                &.text_warning {
                                    color: rgba(255, 97, 112, 1);
                                }
                            }
                        }
                    }
                }
            }

            .modal_body_devide_line {
                width: 1px;
                background: #3E405E;
            }

            .right {
                width: 452px;
                padding-left: 36px;
                padding-bottom: 12px;
                display: flex;
                flex-direction: column;

                .title_wrapper {
                    display: flex;
                    align-items: center;

                    .title_icon {
                        width: 22px;
                        height: 12px;
                    }

                    .title {
                        margin-left: 8px;
                        height: 18px;
                        font-size: 18px;
                        // font-weight: 500;
                        color: #FFFFFF;
                        line-height: 18px;
                    }
                }

                .table_content {
                    height: 0;
                    flex-grow: 1;
                    overflow-y: auto;
                    margin-top: 24px;

                    .items_wrapper {
                        display: inline-block;
                        width: 380px;

                        .item_top_line {
                            height: 1px;
                            background: rgba(62, 64, 94, 1);
                            margin: 0 0 12px 8px;
                        }

                        .item_self_wrapper {
                            height: 141px;
                            padding: 0 8px;
                        }
                    }
                }
            }
        }
    }
}