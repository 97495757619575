.reference_factors_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.scroll_bar_restyle,
    .scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .reference_factors_header_wrapper {

        .time_range_select_block {
            // width: 0;
            // flex-grow: 1;
            // margin-left: 8px;
            margin-right: 24px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .block_wrapper {
                width: 56px;
                height: 32px;
                background: #12191F;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                user-select: none;

                &:not(:last-child) {
                    margin-right: 4px;
                }

                .text {
                    height: 16px;
                    font-size: 16px;
                    // font-family: PingFangSC-Regular, PingFang SC;
                    // font-weight: 400;
                    color: #9FA0BA;
                    line-height: 16px;
                }

                &.selected_block_wrapper {
                    background: #343D47;
                    border-style: solid;
                    border-color: #3DCDB9;
                    border-width: 3px 1px 1px 1px;
                    padding-bottom: 2px;

                    .text {
                        color: #44F3DA;
                    }
                }
            }
        }
    }

    .reference_factors_content_wrapper {
        height: 0;
        flex-grow: 1;
        padding: 24px 24px 20px 24px;
        display: flex;
        position: relative;

        .left_block_wrapper {
            border-radius: 2px;
            width: 0;
            flex-grow: 1;
            background-color: #191F27;
            // display: flex;
            // flex-direction: column;

            .item_content_wrapper {
                height: 100%;
                // height: 0;
                // flex-grow: 1;

                .table_wrapper {
                    height: 100%;
                    overflow-y: auto;

                    >table {
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 0;

                        thead {
                            tr {
                                th {
                                    font-size: 16px;
                                    // font-family: PingFangSC-Regular, PingFang SC;
                                    // font-weight: 400;
                                    color: #9FA0BA;
                                    // line-height: 16px;
                                    padding: 0;
                                    height: 0;
                                    position: sticky;
                                    top: 0;
                                    z-index: 100;
                                    background-color: #191F27;
                                    font-weight: normal;
                                }
                            }
                        }

                        tbody {
                            tr {
                                &:nth-child(n+2) {
                                    td {
                                        cursor: pointer;
                                    }
                                }

                                &:first-child {
                                    td {
                                        &:last-child {
                                            cursor: pointer;
                                        }
                                    }
                                }

                                td {
                                    padding: 0;
                                    font-size: 14px;
                                    // font-family: PingFangSC-Regular, PingFang SC;
                                    // font-weight: 400;
                                    color: #FFFFFF;
                                    line-height: 14px;

                                    .td_3_product_category {
                                        // margin-left: 2px;
                                        width: 57px;
                                        height: 20px;
                                        border: 1px solid #FFD94E;
                                        font-size: 12px;
                                        // font-family: PingFangSC-Regular, PingFang SC;
                                        // font-weight: 400;
                                        color: #FFD94E;
                                        line-height: 12px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    .factor_name_wrapper {
                                        display: flex;
                                        align-items: center;
                                    }
                                }

                                &.tr_selected,
                                &.tr_selected_top,
                                &.tr_selected_bottom {
                                    td {
                                        &:last-child {
                                            border-right: 1px solid #277069;
                                        }
                                    }
                                }

                                &.tr_selected {

                                    &.tr_zero_expand {

                                        &:nth-child(3) {
                                            td {
                                                &:nth-child(n+5) {
                                                    border-top: 1px solid #277069;
                                                    border-bottom: 1px solid #277069;
                                                    background-color: #15191e;
                                                }

                                                &:nth-child(5) {
                                                    border-left: 1px solid #277069;
                                                }
                                            }
                                        }

                                        &:nth-child(n+4) {
                                            td {
                                                &:nth-child(n+4) {
                                                    border-top: 1px solid #277069;
                                                    border-bottom: 1px solid #277069;
                                                    background-color: #15191e;
                                                }

                                                &:nth-child(4) {
                                                    border-left: 1px solid #277069;
                                                }
                                            }
                                        }
                                    }

                                    &:not(.tr_zero_expand) {

                                        &:nth-child(2) {
                                            td {
                                                &:nth-child(n+5) {
                                                    border-top: 1px solid #277069;
                                                    border-bottom: 1px solid #277069;
                                                    background-color: #15191e;
                                                }

                                                &:nth-child(5) {
                                                    border-left: 1px solid #277069;
                                                }
                                            }
                                        }

                                        &:nth-child(n+3) {
                                            td {
                                                &:nth-child(n+4) {
                                                    border-top: 1px solid #277069;
                                                    border-bottom: 1px solid #277069;
                                                    background-color: #15191e;
                                                }

                                                &:nth-child(4) {
                                                    border-left: 1px solid #277069;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.tr_selected_top {
                                    &.tr_zero_expand {

                                        &:nth-child(3) {
                                            td {
                                                &:nth-child(n+5) {
                                                    border-top: 1px solid #277069;
                                                    // border-bottom: 1px solid transparent;
                                                    background-color: #15191e;
                                                }

                                                &:nth-child(5) {
                                                    border-left: 1px solid #277069;
                                                }
                                            }
                                        }

                                        &:nth-child(n+4) {
                                            td {
                                                &:nth-child(n+4) {
                                                    border-top: 1px solid #277069;
                                                    // border-bottom: 1px solid transparent;
                                                    background-color: #15191e;
                                                }

                                                &:nth-child(4) {
                                                    border-left: 1px solid #277069;
                                                }
                                            }
                                        }
                                    }

                                    &:not(.tr_zero_expand) {

                                        &:nth-child(2) {
                                            td {
                                                &:nth-child(n+5) {
                                                    border-top: 1px solid #277069;
                                                    // border-bottom: 1px solid transparent;
                                                    background-color: #15191e;
                                                }

                                                &:nth-child(5) {
                                                    border-left: 1px solid #277069;
                                                }
                                            }
                                        }

                                        &:nth-child(n+3) {
                                            td {
                                                &:nth-child(n+4) {
                                                    border-top: 1px solid #277069;
                                                    // border-bottom: 1px solid transparent;
                                                    background-color: #15191e;
                                                }

                                                &:nth-child(4) {
                                                    border-left: 1px solid #277069;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.tr_selected_bottom {
                                    &:not(:last-child) {
                                        td {
                                            &:nth-child(n+3) {
                                                // border-top: 1px solid transparent;
                                                border-bottom: 1px solid #277069;
                                                background-color: #15191e;
                                            }

                                            &:nth-child(3) {
                                                border-left: 1px solid #277069;
                                            }
                                        }
                                    }

                                    &:last-child {
                                        td {
                                            &:nth-child(n+4) {
                                                // border-top: 1px solid transparent;
                                                border-bottom: 1px solid #277069;
                                                background-color: #15191e;
                                            }

                                            &:nth-child(4) {
                                                border-left: 1px solid #277069;
                                            }
                                        }
                                    }
                                }

                                &:nth-child(n+2) {

                                    &:hover {
                                        td {
                                            opacity: 0.8;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .right_block_wrapper {
            width: 404px;
            margin-left: 12px;
        }
    }
}