:global {
    :local(.wrapper) {
        display: inline-block;

        >.tabs {
            background-color: #FFFFFF;
            border-radius: 2px;
            display: flex;

            >.item {
                padding: 2px;
                cursor: pointer;
                user-select: none;

                >.text_wrapper {
                    padding: 5px 15px;

                    >.text {
                        height: 14px;
                        font-size: 14px;
                        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                        font-weight: 400;
                        color: #001529;
                        line-height: 14px;
                    }

                    &.selected {
                        background-color: #355B99;
                        border-radius: 2px;

                        >.text {
                            color: #FFFFFF;
                        }
                    }
                }
            }

            >.devide_line {
                align-self: center;
                width: 1px;
                height: 16px;
                background-color: #F0F0F0;
            }
        }
    }
}