:global {
    :local(.wrapper) {
        height: 100%;
        background-color: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        border-radius: 2px;
        position: relative;

        >.content {
            height: 100%;
            display: flex;
            flex-direction: column;

            >.top {
                padding: 24px;
                display: flex;
                align-items: center;

                >.title {
                    height: 14px;
                    font-size: 14px;
                    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                    font-weight: 400;
                    color: #999999;
                    line-height: 14px;
                }

                >.selector {
                    margin-left: 10px;
                }
            }

            >.bottom {
                height: 0;
                flex-grow: 1;
                padding-right: 2px;

                >.scroll_wrapper {
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;

                    >.content {
                        >table {
                            width: 100%;
                            border-collapse: separate;
                            border-spacing: 0;

                            >thead {

                                >tr {
                                    >th {
                                        padding: 0;
                                        background-color: #F5F5F5;
                                        height: 48px;
                                        text-align: center;
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                                        font-weight: 500;
                                        color: #333333;
                                        line-height: 14px;
                                        border-top: 1px solid #E6E6E6;
                                        // sticky 之后 border top消失??? // 由border-collapse coolapse导致
                                        position: sticky;
                                        top: 0;
                                        z-index: 1;

                                        &:first-child {
                                            border-top-left-radius: 2px;
                                            border-left: 1px solid #E6E6E6;
                                        }

                                        &:last-child {
                                            border-top-right-radius: 2px;
                                            border-right: 1px solid #E6E6E6;
                                        }

                                        &.action {
                                            width: 126px;
                                        }
                                    }
                                }
                            }

                            >tbody {
                                >tr {
                                    >td {
                                        padding: 0;
                                        text-align: center;
                                        height: 72px;

                                        &.pot_cost {
                                            text-align: right;
                                            font-size: 12px;
                                            font-family: PingFangSC-Medium, PingFang SC;
                                            font-weight: 500;
                                            color: #5BAE8D;
                                            line-height: 12px;

                                            >.value {
                                                min-width: 30px;
                                                padding-right: 8px;
                                            }
                                        }

                                        &.pot_bar {
                                            width: 120px;

                                            >.bar {
                                                background-color: rgba(128, 128, 128, 0.1);
                                                border-radius: 2px;

                                                >.value {
                                                    width: 0;
                                                    background-color: #5BAE8D;
                                                    height: 10px;
                                                    border-radius: 2px;
                                                }
                                            }
                                        }

                                        &:first-child {
                                            border-left: 1px solid #E6E6E6;
                                        }

                                        &:last-child {
                                            border-right: 1px solid #E6E6E6;
                                        }
                                    }

                                    &:nth-child(even) {
                                        background-color: rgba(245, 245, 245, 1);

                                        >td {
                                            border-top: 1px solid #E6E6E6;
                                            border-bottom: 1px solid #E6E6E6;
                                        }
                                    }

                                    &:last-child {
                                        >td {
                                            border-bottom: 1px solid #E6E6E6;
                                        }
                                    }

                                    &.selected {
                                        background-color: rgba(227, 242, 255, 1);

                                        >td {
                                            border-top: 1px solid #C1E1FF;
                                            border-bottom: 1px solid #C1E1FF;
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}