.produce_trend_wrapper {
    height: 100%;
    padding: 0 24px 0;
    overflow-y: auto;

    .content_wrapper {
        padding: 24px 0 26px;

        .content_root_title {
            height: 20px;
            font-size: 20px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
        }

        .item_position_wrapper {
            margin-top: 16px;
        }
    }
}