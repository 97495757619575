:global {
    :local(.exam_result_root_wrapper) {
        height: 100%;
        padding: 24px 56px 0 72px;
        display: flex;
        flex-direction: column;
        position: relative;

        .header {
            position: absolute;
            right: 56px;
            top: 0;
            transform: translateY(-100%);

            display: flex;
            align-items: center;

            >.item {
                margin-right: 47px;

                span {
                    font-weight: bold;
                }
            }

            .btn {
                margin-left: auto;
            }
        }

        .ExamResultWrapper {
            height: 0;
            flex-grow: 1;
            background: rgba(255, 255, 255, 1);
            border-radius: 16px;
            box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.05);
            padding: 0 12px 12px 12px;
            display: flex;
            flex-direction: column;

            .top_bar {
                display: flex;
                align-items: center;
                padding: 0 12px;
                height: 70px;
                border-bottom: 1px solid #BBBBBB;
                justify-content: space-between;
                flex-direction: row;

                .exam_system_wrapper {
                    display: flex;
                    height: 50px;

                    .tab_title {
                        font-size: 14px;
                        line-height: 22px;
                        padding: 0 16px;
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.65);
                        cursor: pointer;
                        position: relative;
                        display: flex;
                        align-items: center;
                    }

                    .tab_title_selected {
                        color: rgba(53, 124, 247, 1);
                        font-weight: 500;
                    }

                    .tab_title_selected::after {
                        content: '';
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 2px;
                        background: rgba(53, 124, 247, 1);
                    }
                }

                .export_btn {
                    margin-left: auto;
                }

                .rightBtns {
                    display: flex;
                    flex-direction: row;

                }

                .cus_switch {
                    margin-left: 35px;
                    height: 30px;
                    display: flex;
                    border-radius: 4px;
                    border: 1px solid #357CF7;

                    .item {
                        width: 80px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #357CF7;
                        cursor: pointer;
                    }

                    .active_item {
                        background-color: #357CF7;
                        color: #ffffff;
                    }
                }
            }

            .bottom_content_wrapper {
                height: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .bottom_header_block {
                    padding: 16px 32px;
                    display: flex;

                    .display_item {
                        padding: 6px 10px;
                        background-color: rgba($color: grey, $alpha: 0.3);
                        border-radius: 4px;
                        display: flex;
                        align-items: center;

                        .title {
                            font-weight: bold;
                        }

                        &:nth-child(n+2) {
                            margin-left: 20px;
                        }
                    }

                    .display_time_range_wrapper {

                        .value {
                            margin-left: 2px;
                        }
                    }

                    .display_item_select_time {
                        position: relative;

                        .text {
                            min-width: 170px;
                            display: flex;
                            justify-content: center;

                        }

                        .more_btn {
                            margin-left: 20px;
                        }

                        .time_picker_wrapper_custom {
                            position: absolute;
                            right: -100px;
                            bottom: 0;
                            transform: translateY(100%);
                            width: 600px;

                            .time_content_wrapper {
                                border: 1px solid grey;
                                display: flex;
                                background-color: white;

                                .left,
                                .right {
                                    padding: 10px;
                                }

                                .left {
                                    width: 300px;

                                    .custom_header {
                                        display: flex;
                                        align-items: center;
                                        height: 39px;
                                        padding: 0 17px;
                                        font-size: 12px;
                                    }
                                }

                                .mid_line {
                                    flex-shrink: 0;
                                    width: 2px;
                                    height: 200px;
                                    align-self: center;
                                    background-color: grey;
                                }

                                .right {
                                    width: 0;
                                    flex-grow: 1;
                                    display: flex;
                                    flex-direction: column;

                                    .all_shifts_wrapper {
                                        height: 30px;
                                        padding: 5px 10px;
                                        background-color: rgb(228, 226, 226);
                                    }

                                    .single_shift_wrapper {
                                        display: flex;
                                        align-items: center;
                                        margin-top: 20px;
                                    }

                                    .btn_ok {
                                        margin-top: auto;
                                        width: 100px;
                                        align-self: flex-end;
                                    }
                                }
                            }
                        }
                    }
                }

                .table_wrapper {
                    height: 0;
                    flex-grow: 1;
                    display: flex;
                    justify-content: center;
                    padding: 30px;
                    overflow-y: auto;

                    table {
                        border-collapse: collapse;
                        border: 1px solid #000;

                        .bck_th {
                            background-color: rgb(214, 213, 213);
                            font-weight: bold;
                        }
                    }

                    th {
                        border: 1px solid #000;
                        padding: 5px 20px;
                        text-align: center;
                    }

                    td {
                        border: 1px solid #000;
                        padding: 5px 20px;
                        text-align: center;
                    }
                }
            }

        }
    }
}