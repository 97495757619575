.main_electrical_params_wrapper {
    height: 100%;
    padding: 0 24px 0;
    overflow-y: auto;

    &.scroll_bar_restyle,
    .scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .content_wrapper {
        padding: 24px 0 42px;

        .content_root_title {
            height: 20px;
            font-size: 20px;
            // font-family: PingFangSC-Regular, PingFang SC;
            // font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
        }

        .item_wrapper {
            height: 185px;
            border: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
            display: flex;
            flex-direction: column;

            .item_header_wrapper {
                height: 57px;
                border-bottom: 1px solid rgba($color: #44F2D9, $alpha: 0.2);
                padding-left: 12px;
                display: flex;
                align-items: center;
                background: #0D1115;

                .item_header_icon {
                    width: 24px;
                    height: 24px;
                }

                .title {
                    // width: 193px;
                    padding-left: 5px;
                    height: 18px;
                    font-size: 18px;
                    // font-family: PingFangSC-Medium, PingFang SC;
                    // font-weight: 500;
                    color: #FFFFFF;
                    line-height: 18px;
                }
            }

            .item_content_wrapper {
                height: 0;
                flex-grow: 1;
                background-color: rgba(33, 40, 48, 1);
                padding: 0 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .child_item_wrapper {
                    width: 280px;
                    height: 96px;
                    position: relative;
                    background-color: rgba(21, 25, 30, 1);
                    border-radius: 2px;

                    .mask {
                        opacity: 0.1;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }

                    .head_color_bar {
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        width: 2px;
                        background-color: transparent;
                    }

                    .inner_content_wrapper {
                        position: absolute;
                        top: 50%;
                        left: 80px;
                        transform: translateY(-50%);

                        .value_unit_wrapper {
                            display: flex;
                            align-items: flex-end;

                            .value {
                                height: 24px;
                                font-size: 24px;
                                // font-family: Helvetica;
                                color: #FFFFFF;
                                line-height: 24px;
                            }

                            .unit {
                                margin-left: 10px;
                                height: 14px;
                                font-size: 14px;
                                // font-family: PingFangSC-Medium, PingFang SC;
                                // font-weight: 500;
                                color: #FFFFFF;
                                line-height: 14px;
                                opacity: 0.5;
                            }
                        }

                        .name {
                            margin-top: 12px;
                            height: 14px;
                            font-size: 14px;
                            // font-family: PingFangSC-Regular, PingFang SC;
                            // font-weight: 400;
                            color: #8C8F93;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
    }
}