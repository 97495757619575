:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        >.content_wrapper {
            height: 100%;
            overflow: hidden auto;

            .ant-empty{
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }

            >.content {
                padding-right: 16px;
                padding-bottom: 12px;

                >.single_capsule_wrapper {
                    display: flex;
                    flex-direction: column;

                    &.at_right {
                        align-items: flex-end;                      
                        &.move-left{
                            padding-right: 472px !important;
                            transition: all 0.5s;
                        }
                        &.move-right{
                            padding-right: 0 !important;
                            transition: all 0.5s;
                        }
                    }

                    &.at_left {
                        padding-top: 8px;
                        align-items: flex-start;
                    }

                    >.title_wrapper {
                        height: 24px;
                        display: flex;
                        align-items: center;

                        >.text {
                            height: 12px;
                            font-size: 12px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 12px;
                            color: #727272;
                        }
                    }

                    .capsule-content:hover .navigate-btn {
                        display: block;
                    }

                    .capsule_content_wrapper {
                        position: relative;
                        height: 56px;
                        max-width: 344px;
                        min-width: 208px;
                        background-color: #F1F1F1;
                        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
                        border-radius: 28px;
                        padding: 8px 24px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        cursor: pointer;

                        >.time_tag_wrapper {
                            display: flex;
                            align-items: center;

                            >.time {
                                height: 12px; // 设计稿是13
                                font-size: 12px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                line-height: 12px;
                                color: #727272;
                            }

                            >.tag_wrapper {
                                margin-left: 5px;
                                height: 16px;
                                background: #238D7A;
                                border-radius: 16px;
                                padding: 0 8px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                >.text {
                                    height: 12px;
                                    font-size: 12px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    line-height: 12px;
                                    color: #FFFFFF;
                                }
                            }
                        }

                        >.text_wrapper {
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 16px;
                            color: #3A3A3A;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .new-icon{
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 40px;
                            height: 16px;
                            background: url('../../../../assets/svg/icon_new.svg') no-repeat;
                            transform: translateX(100%);
                        }
                        .active-tag{
                            position: absolute;
                            bottom: 3px;
                            right: 0;
                            width: 50px;
                            height: 17px;
                            background: #FFD900;
                            border-radius: 50% / 100% 100%  0 0;
                            transform: translate(16px, -16px) rotate(90deg) ;
                            background-color: #000000;
                        }
                    }
                    .comment_wrapper {
                        font-size: 12px;
                        line-height: 24px;
                        max-width: 312px;
                        min-width: 176px;
                        border-radius: 16px;
                        margin: 0 16px;
                        padding: 0 5px 0 28px;
                        background-color: rgba(35, 141, 122, .7);
                        color: #fff;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        background: rgba(35, 141, 122, .7) url('../../../../assets/svg/icon_comment.svg') no-repeat 8px;
                    }
                    .navigate-btn {
                        display: none;
                        width: 88px;
                        background: rgba(0, 0, 0, .7);
                        border-radius: 16px;
                        font-size: 12px;
                        font-family: 'Source Han Sans CN';
                        font-weight: 400;
                        line-height: 24px;
                        color: #FFFFFF;
                        padding: 0 8px;
                        margin-left: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}