.history_data_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ant-table-body,
    .scroll_bar_restyle {
        //hardcode

        //滚动条背景
        &::-webkit-scrollbar {
            width: 4px;
            background-color: rgba(0, 0, 0, 0);
        }

        // //滚动条前景
        &::-webkit-scrollbar-thumb {
            background-color: rgba($color: #dadada, $alpha: 0.25);
            border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(100, 100, 100, 0.3);
            background-color: rgba(200, 200, 200, 0.1);
        }
    }

    .history_data_header_wrapper {
        height: 70px;
        background-color: #212830;
        // background: rgba(242, 242, 242, 1);
        // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.11);
        display: flex;
        align-items: center;
        padding: 0 24px;

        .divide_line {
            margin-left: 28px;
            width: 1px;
            height: 28px;
            background: rgba(255, 255, 255, 1);
            opacity: 0.18;
        }

        .opt_title {
            margin: 0 12px 0 24px;
            font-size: 14px;
            font-weight: 400;
            // color: rgba(0, 0, 0, 0.65);
            line-height: 22px;
        }
    }

    .history_data_content_wrapper {
        height: 0;
        flex-grow: 1;
        // background: rgba(240, 242, 245, 1);
        overflow-y: hidden;
        padding: 0 24px 24px 24px;

        .content_wrapper {
            height: 100%;
            // background: rgba(255, 255, 255, 1);
            // background-color: #191f27;
            border-radius: 2px;
            // padding: 24px;
            display: flex;
            flex-direction: column;

            .content_top_header {
                height: 60px;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
                display: flex;
                align-items: center;
            }

            .content_bottom_wrapper {
                display: flex;
                // padding: 12px 24px;
                // height: calc(100% - 60px);
                height: 0;
                flex-grow: 1;

                .left {
                    width: 600px;
                    margin-right: 24px;
                    height: 100%;
                    padding: 12px;
                    // box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.07);
                    border: 1px solid rgba(68, 242, 217, 0.2);

                    .top_bar {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        padding-bottom: 10px;
                    }

                    .ant-table-wrapper {
                        height: calc(100% - 50px);

                        .ant-spin-nested-loading {
                            height: 100%;

                            .ant-spin-container {
                                height: 100%;

                                .ant-table.ant-table-fixed-header {
                                    height: 100%;

                                    .ant-table-container {
                                        height: 100%;
                                        display: flex;
                                        flex-direction: column;

                                        .ant-table-header {
                                            flex-shrink: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right {
                    flex-grow: 1;
                    padding: 12px;
                    height: 100%;
                    overflow-y: scroll;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    // box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.07);
                    border: 1px solid rgba(68, 242, 217, 0.2);

                    .chart_wrapper {
                        background-color: rgba(0, 0, 0, 0.03);
                        padding: 12px;

                        .title {
                            height: 24px;
                            line-height: 24px;
                            padding-left: 24px;
                            position: relative;

                            &::after {
                                content: '';
                                display: block;
                                position: absolute;
                                top: 0;
                                bottom: 0;
                                left: 8px;
                                width: 4px;
                                border-radius: 2px;
                                background-color: #1890ff;
                            }

                            >span {
                                margin-left: 12px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
}