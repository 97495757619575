:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            display: flex;
            flex-direction: column;

            >.content {
                height: 0;
                flex-grow: 1;
                display: flex;

                >.left {
                    width: 0;
                    flex-grow: 1;
                    position: relative;

                    >.chart {
                        height: 100%;
                    }

                    >.inner_wrapper {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 40%;
                        padding-top: 40%;

                        >.block {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: #EFEFEF;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            >.content {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                color: #355B99;

                                >.title {
                                    height: 12px;
                                    font-size: 12px;
                                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                    font-weight: 400;
                                    line-height: 12px;
                                }

                                >.value_wrapper {
                                    margin-top: 4px;
                                    height: 28px;
                                    font-size: 20px;
                                    font-family: Helvetica;
                                    line-height: 28px;
                                    display: flex;

                                    >.unit {
                                        font-size: 14px;
                                        position: relative;
                                        top: 2px;
                                    }
                                }

                                >.line {
                                    margin-top: 2px;
                                    width: 14px;
                                    height: 1px;
                                    background-color: #355B99;
                                }
                            }
                        }
                    }
                }

                >.right {
                    width: 464px;
                    padding-top: 39px;
                    padding-right: 2px;
                    display: flex;
                    flex-direction: column;

                    >.content {
                        height: 0;
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;

                        >.title_wrapper {
                            display: flex;
                            align-items: center;
                            justify-content: space-around;

                            >.item {
                                display: flex;
                                align-items: center;
                                font-size: 14px;
                                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                                font-weight: 400;
                                line-height: 14px;

                                >.title {
                                    height: 14px;
                                    color: #999999;
                                }

                                >.value {
                                    margin-left: 2px;
                                    height: 14px;
                                    color: #333333;
                                }
                            }
                        }

                        >.scroll_wrapper {
                            margin-top: 24px;
                            height: 0;
                            flex-grow: 1;
                            overflow-y: auto;
                            overflow-x: hidden;

                            >.content {
                                display: flex;
                                justify-content: space-between;
                                padding-bottom: 12px;

                                >.part {
                                    width: 208px;

                                    >.item {
                                        height: 33px;
                                        background-color: #FAFAFA;
                                        border-radius: 2px;
                                        padding-left: 16px;
                                        display: flex;
                                        align-items: center;
                                        font-size: 12px;
                                        font-family: PingFangSC-Regular, PingFang SC;
                                        font-weight: 400;
                                        line-height: 17px;

                                        >.dot {
                                            width: 9px;
                                            height: 9px;
                                            border-radius: 50%;
                                        }

                                        >.title {
                                            margin-left: 8px;
                                            width: 63px;
                                            height: 17px;
                                            color: #666666;
                                        }

                                        >.rate,
                                        >.cost {
                                            height: 17px;
                                            color: #000000;
                                        }

                                        >.rate {
                                            width: 52px;
                                        }

                                        >.cost {
                                            width: 0;
                                            flex-grow: 1;
                                        }

                                        &:nth-child(n+2) {
                                            margin-top: 12px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}