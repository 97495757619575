:global {
    :local(.wrapper) {
        height: 100%;

        .card_hide {
            opacity: 0;
        }

        >.gp_wrapper {
            position: absolute;

            >.title {
                height: 19px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                line-height: 19px;
                color: #A6B2C2;
                opacity: 0.8;
            }

            >.content {
                margin-top: 8px;
            }

            &.gpI {
                top: 24px;
                right: 24px;
            }
        }
    }
}