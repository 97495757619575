:global {
    :local(.PA_ScatterChart) {
        width: 100%;
        height: 280px;
        display: flex;
        flex-direction: row;

        .left-content {
            flex-grow: 1;
            flex-basis: 100px;
            display: flex;
            flex-direction: column;

            .info-bar {
                display: flex;
                flex-direction: row;
            }

            .scatter-node {
                height: 200px;
                width: 100%;
                margin-top: 20px;
            }
        }

        .time-options {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 180px;
            width: 180px;
            display: flex;
            flex-direction: column;
            margin-top: 44px;

            >*:nth-child(n+2) {
                margin-top: 6px;
            }
        }
    }
}