.factor_summary_v2_single_factor_detail_shift_compare_block_wrapper {
    >table {
        >thead {
            >tr {
                >th {
                    padding: 0;
                    font-size: 14px;
                    line-height: 14px;
                    color: #9FA0BA;
                    background-color: #0D1115;
                }
            }
        }

        >tbody {
            >tr {
                >td {
                    height: 38px;
                    padding: 0;
                    font-size: 14px;
                    line-height: 14px;
                    color: #FFFFFF;

                    &.td_red {
                        color: #FF6170;
                    }
                }

                &:nth-child(n+2) {
                    >td {
                        border-top: 2px solid rgba(58, 63, 70, 1);
                    }
                }
            }
        }
    }
}