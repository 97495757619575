.smallCard {
  width: 122px;
  height: 88px;
  border-radius: 10px;
  background: #F2F2F2;
  padding: 16px 0px 15px 17px;
  box-sizing: border-box;
  &-number {
    display: flex;
    margin-bottom: 12px;
    color: #015959;
    &-num {
      font-size: 24px;
    }
    &-v {
      font-size: 12px;
      vertical-align: bottom;
      margin-top: 13px;
    }
  }
  &-text {
    font-size: 12px;
  }
}

