:global {
    :local(.wrapper) {
        height: 100%;
        background: #FFFFFF;
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 2px;
        display: flex;
        flex-direction: column;

        >.head {
            padding: 20px 16px;
            display: flex;
            border-bottom: 1px solid #c9c9c9;
            >.text {
                height: 16px;
                font-size: 16px;
                margin-left: 8px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 16px;
                color: #357CF7;
            }
        }

        >.content {
            height: 0;
            flex-grow: 1;
            display: flex;
            padding-left: 8px;
            padding-top: 24px;
            padding-bottom: 24px;

            >.item_wrapper {
                width: 0;
                flex-grow: 1;
                padding-right: 8px;

                >.title {
                    height: 16px;
                    font-size: 16px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 16px;
                    color: #333;
                    padding-left: 16px;
                }

                >.values_wrapper {
                    width: 232px;
                    margin: 20px auto 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    >.v_wrapper {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        >.name {
                            height: 14px;
                            font-size: 14px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            line-height: 14px;
                            color: #666;
                        }

                        >.value_wrapper {
                            margin-top: 12px;
                            display: flex;
                            align-items: flex-end;

                            >.value {
                                height: 20px;
                                font-size: 20px;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                line-height: 20px;
                                color: #333;
                            }

                            >.unit {
                                margin-left: 2px;
                                height: 14px;
                                font-size: 14px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                line-height: 14px;
                                color: #333;
                            }
                        }
                    }
                }
            }

            >.line {
                width: 1px;
                height: 100%;
                position: relative;

                &::after {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: -8px;
                    left: 0;
                    content: '';
                    background-color: #C9C9C9;
                }
            }
        }
    }
}