:global {
    :local(.wrapper) {
        height: 100%;
        position: relative;

        :local(.content) {
            height: 100%;
            padding: 0 16px;
            display: flex;
            flex-direction: column;

            >.content {
                // margin-top: 14px;
                padding-top: 10px;
                height: 0;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                >.title {
                    margin-top: 2px;
                    height: 12px;
                    font-size: 12px;
                    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
                    font-weight: 400;
                    color: #666666;
                    line-height: 12px;
                    text-align: center;
                }

                >.chart_wrapper {
                    height: 0;
                    flex-grow: 1;
                    margin-top: 6px;
                    position: relative;

                    >.back {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                    }

                    >.font {
                        position: relative;
                        height: 100%;
                        overflow: hidden;

                        >.chart {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    :local(.subtitle) {
        padding-right: 16px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
    }
}