:global {
    :local(.wrapper) {
        position: relative;
    }

    :local(.title_wrapper) {
        position: relative;

        >.text {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 23px;
            color: #333333;
        }

        &::after {
            // hard code 与antd modal style相关
            content: "";
            position: absolute;
            top: 50%;
            left: -24px;
            transform: translateY(-50%);
            width: 0;
            height: 100%;
            border-style: solid;
            border-width: 2px 0 2px 4px;
            border-color: transparent transparent transparent #375B98;
        }
    }

    :local(.modal) {
        .ant-modal-header {
            // hard code
            background-color: #E2E4E7;
        }
    }
}