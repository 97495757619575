$template-color:#1890FF;
$border-color:#C9C9C9;
:global {
    :local(.filter-container) {
        background-color: #F5F5F5;
        display: flex;
        padding: 16px 0; 
        min-height: 96px;       

        &>div {
            display: flex;
            flex-direction: column;
            padding: 0 16px;

            &:not(:first-child):not(:last-child):not(:nth-child(2)) {
                border-left: 1px solid rgba(0, 0, 0, .18);
            }

            &.pageTitle{
               color: #333;
               position: relative;
               width: 90px;
               min-width: 90px;
               flex-direction: row;
               white-space: nowrap;
               align-items: center;
               justify-content: center;
               padding-left: 0;
               padding-right: 0px;
               border-right: 1px solid rgba(0, 0, 0, .18);
               margin-right: 16px;
            }

            .filter-icon {
                position: absolute;                
                width: 16px;
                height: 16px;
                top: 4px;
                right: -26px;                                               
                background: url('../../../../../assets/svg/icon_filter_blue.svg') no-repeat;                             
            }

            .ant-picker {
                border-color: #d9d9d9 !important;
                // margin-bottom: 5px;
                padding: 4px 6px;
            }

            .ant-tabs {
                .ant-tabs-nav {
                    margin-bottom: 8px;

                    &::before {
                        border: none;
                    }
                }

                .ant-tabs-tab {
                    padding: 0;
                    margin-right: 8px;

                    .ant-tabs-tab-btn {
                        font-size: 14px;
                        font-family: 'Source Han Sans CN';
                        // font-weight: 500;
                        line-height: 22px;
                        color: #666;

                        &:hover {
                            color: $template-color;
                        }
                    }
                }

                .ant-radio-inner {
                    // border-color: #357CF7;
                }

                .ant-radio-wrapper-checked .ant-radio-inner::after {
                    // background-color: #357CF7;
                }

                .ant-tabs-tabpane {
                    white-space: nowrap;
                }

                .ant-tabs-ink-bar {
                    // background: #1890FF;
                }

                .ant-tabs-tab-active {
                    .ant-tabs-tab-btn {
                        color: $template-color;
                    }

                }

                span.ant-radio+* {
                    padding-right: 3px;
                    padding-left: 4px;
                }
            }

            &>label {
                font-size: 14px;
                font-family: 'Source Han Sans CN';
                // font-weight: 500;
                line-height: 22px;
                color: #666;
                margin-bottom: 8px;
            }

            .ant-select-selector {
                // border-color: #AFDAD8 !important;
            }

            .ant-input {
                padding: 4px;
                // border-color: #AFDAD8;
            }           
        }

        .reset-btn{ 
            justify-content: center;               
            margin: 0;               
            cursor: pointer;
            color: #333;
            padding-left: 24px;
            background: url('../../../../../assets/svg/icon_reset.svg') no-repeat left;
            white-space: nowrap;
            &:hover{
                color: $template-color;
            }
        }
       
        .btn-group {            
            flex-direction: row;            
            align-items: center;  
            justify-content: flex-end;
            border-left: 1px solid rgba(0, 0, 0, .18);        
            
            .search-icon,.add-icon,.export-btn {             
                width: 72px;
                height: 32px;                
                border-radius: 4px;
                margin-right: 24px ;                
            }
            .search-icon {
                margin-right: 0;
                margin-left: 24px;
            }
        }
    }
}