:global {
    :local(.wrapper) {
        // display: flex;
        // align-items: center;
        position: relative;

        >.picker_wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;

            >.hover_block {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }

        >.content {
            position: relative;
            display: flex;
            align-items: center;

            >.date {
                height: 14px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #546677;
                line-height: 14px;
                cursor: pointer;
                user-select: none;
            }

        }

    }
}