:global {
    :local(.wrapper) {
        box-shadow: 2px 5px 8px rgba(0, 0, 0, 0.16);
        border-radius: 16px;
        background: #FFFFFF;
        padding: 32px 50px 0 40px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;

        .title_row {
            .title {
                height: 32px;
                font-size: 32px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                line-height: 32px;
                color: #727272;
            }
        }

        .content_row {
            height: 0;
            flex-grow: 1;
            display: flex;
            align-items: center;

            .left,
            .right {
                width: 0;
                flex-grow: 1;
            }

            .left {
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 16px;

                .chart_wrapper {
                    width: 100%;
                    position: relative;

                    &::before {
                        // content: '';
                        // display: block;
                        // width: 100%;
                        // padding-top: 100%;
                    }

                    .chart_content_wrapper {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .radar_block {
                            width: 256px;
                            height: 256px;
                            position: relative;

                            .item_wrapper {
                                padding: 3px 6px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                user-select: none;
                                cursor: pointer;

                                .value {
                                    height: 24px;
                                    font-size: 24px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    line-height: 24px;
                                    color: #3a3a3a;
                                }

                                .name {
                                    margin-top: 4px;
                                    height: 24px;
                                    font-size: 24px;
                                    font-family: Source Han Sans CN;
                                    font-weight: 400;
                                    line-height: 24px;
                                    color: #3a3a3a;
                                    white-space: nowrap;

                                    &.selected_name {
                                        height: 40px;
                                        line-height: 40px;
                                        padding: 0 33px;
                                        background-color: #00676B;
                                        color: #FFFFFF;
                                        border-radius: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .right {
                padding-top: 16px;

                .chart_row {
                    height: 72px;
                }

                .table_row {
                    margin-top: 16px;
                    table {
                        width: 100%;

                        thead {
                            tr {
                                th {
                                    height: 32px;
                                    text-align: center;

                                    &.th_topic {
                                        width: 180px;

                                        .title_wrapper {
                                            display: flex;
                                            width: 100%;
                                            align-items: center;
                                            justify-content: center;
                                            // justify-content: space-between;

                                            .name {
                                                margin-left: 6px;
                                                height: 46px;
                                                font-size: 33px;
                                                font-family: Source Han Sans CN;
                                                font-weight: bold;
                                                line-height: 46px;
                                                color: #3a3a3a;
                                            }
                                        }

                                        >.clickable {
                                            cursor: pointer;
                                            user-select: none;
                                        }
                                    }

                                    .col_title_wrapper {
                                        // max-width: 140px;
                                        display: flex;
                                        align-items: center;
                                        justify-content:center;               
                                        font-family: Source Han Sans CN;
                                        font-weight: 400;
                                        
                                        color: #666;

                                        .name {
                                            font-size: 28px;
                                        }

                                        .unit {
                                            margin-left: 4px;
                                            font-size: 24px;
                                        }
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                &.tr_content {
                                    td {
                                        height: 70px;
                                        font-size: 24px;
                                        font-family: Source Han Sans CN;
                                        font-weight: 400;
                                        line-height: 70px;
                                        color: #666;
                                        &>div{
                                            text-align: center;
                                        }

                                        // &:first-child > div{
                                        //     text-align: center;
                                        // }

                                        // &:nth-child(n+2) {
                                        //     text-align: right;
                                        // }
                                    }
                                }
                            }
                        }
                    }
                }

                .list_row {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 8px;
                    // margin-bottom: 10px;

                    .content_wrapper {
                        font-size: 14px;
                        line-height: 14px;

                        .item_wrapper {
                            color: #666;
                            height: 14px;
                            margin-bottom: 8px;
                        }

                        .more_btn {
                            display: inline-block;
                            color: #357CF7;
                            height: 14px;
                            user-select: none;
                            cursor: pointer;
                        }
                    }
                }
            }

        }
    }
}