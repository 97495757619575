:global {
    :local(.wrapper) {
        padding: 0 20px;

        .ant-tabs-nav {
            margin-bottom: 10px;

            &::before {
                border: none;
            }

            .ant-tabs-tab {
                padding: 10px 0 5px;
                color: rgba(0, 0, 0, 0.65);
                .ant-tabs-tab-btn:focus{
                    color: rgba(0, 0, 0, 0.65);
                }
            }
        }

        .ant-tabs-tabpane {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            background-color: #F5F5F5;
            border: 1px solid #DADADA;
            border-radius: 20px;
            overflow: hidden;

            .container {
                width: 0;
                flex-grow: 1;
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;
                overflow: hidden;               

                li {
                    width: 170px;                    
                    height: 28px;
                    line-height: 28px;
                    background-color: #375B98;
                    border-radius: 14px;
                    margin-left: 10px;
                    padding: 0 10px;
                    cursor: pointer;
                    text-align: center;

                    span {
                        display: inline-block;
                        font-size: 12px;
                        color: #fff;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        max-width: 100%;
                    }
                    &:last-child{
                        margin-right: 10px;
                    }
                }
            }

            .empty-text {
                color: #C5C5C5;
                margin-left: 24px;
            }

            .more-btn {
                white-space: nowrap;
                color: #357CF7;
                font-size: 12px;
                margin: 0 15px;
                cursor: pointer;
            }
        }
    }
}