:global{
    :local(.pointSelectorWrapper) {

        .point-selector{
            padding: 16px;
            border-radius: 4px;
            background-color: #F5F7FA;
        }

        &.detail-view{
            .point-selector{
                background-color:transparent; 
                padding: 0;
            }
            
            .point-item{
                border: none;
            }
        }
        .search-container {
            position: relative;
            display: flex;
            align-items: center;
            .search-icon {
                position: absolute;
                width: 32px;
                height: 32px;
                display: block;
                background: url('../../../../../../../../assets/svg/input_search.svg') no-repeat center;
            }
            .ant-select-selector{
                padding: 0 11px 0 43px;
                border-color: #375B98 !important;
                border-radius: 4px;
                .ant-select-selection-search{
                    left: 43px;
                }
            }            
        }
        .selected-points{
            .point-item{              
                height: 28px;
                line-height: 28px;
                display: inline-flex;
                align-items: center;
                font-size: 12;
                color: #595959;
                border: 1px solid #C9CFD5;
                border-radius: 14px;
                background-color: #F5F7FA;
                margin: 8px 12px 0 0; 
                padding: 0 12px;  
                .remove-btn{
                    width: 20px;
                    height: 20px;
                    background: url('../../../../../../../../assets/images/v3/remove_icon.png') no-repeat center;
                    margin-left: 6px;
                    cursor: pointer;
                }     
            }
        }
    }
}