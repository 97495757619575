.FolderContainer{
    // padding: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    >.toolBar{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        >.leftTools{
            display: flex;
            flex-direction: row;
            align-items: center;
            .uploadBtn{

            }
            .editBtn{
                margin-left: 10px;
            }
        }
        >.rightTools{
            .downloadBtn{
                margin-right: 10px;
            }
            .delBtn{
                margin-right: 0px;
            }
        }
    }

    .downContent{
        display: flex;
        flex-grow: 1;
        margin-top: 8px;
        padding-bottom: 24px;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        overflow-y: auto;
        user-select:none;
        position: relative; //必须使用relative，否则内部的框框无法相对于它定位
        >.selectRect{
            position: absolute;
            display: block;
            z-index: 1;
            background-color: rgba(24,144,255,0.15);
            border: 1px solid rgba(24,144,255,0.8);
        }
    }
}
