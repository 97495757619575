:global {
    :local(.wrapper) {
        height: 100%;
    }

    :local(.subtitle) {
        height: 100%;
        padding-left: 20px;
        padding-right: 14px;
        display: flex;
        align-items: center;

        >.setting {
            width: 19px;
            height: 19px;
            user-select: none;
            cursor: pointer;
        }

        >.period_wrapper {
            margin-left: auto;
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: rgba(32, 34, 36, 1);
            line-height: 12px;

            >.hour_item {
                width: 42px;
                height: 24px;
                background-color: rgba(250, 251, 253, 1);
                border-style: solid;
                border-width: 1px 0 1px 1px;
                border-color: rgba(213, 213, 213, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                user-select: none;

                &:first-child {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }

                &:last-child {
                    border-top-right-radius: 12px;
                    border-bottom-right-radius: 12px;
                    border-right-width: 1px;
                }

                &.selected_hour_item {
                    border-width: 0;
                    background-color: #357CF7;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }

    :local(.content) {
        height: 100%;
        padding: 16px 2px 16px 16px;

        >.scroll_wrapper {
            height: 100%;
            padding-right: 14px;
            overflow-y: auto;
            overflow-x: hidden;

            >.chart_wrapper {
                &:nth-child(n+2) {
                    margin-top: 16px;
                }
            }
        }
    }

    :local(.modal_content_wrapper) {
        height: 460px;
        display: flex;
        flex-direction: column;

        .scroll_bar_restyle {
            //hardcode

            //滚动条背景
            &::-webkit-scrollbar {
                width: 4px;
            }

            // //滚动条前景
            &::-webkit-scrollbar-thumb {
                background-color: rgba($color:#C9C9C9, $alpha: 1);
                border-radius: 2px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
                border-radius: 2px;
            }
        }

        >.top {
            display: flex;
            align-items: center;
        }

        >.bottom {
            margin-top: 24px;
            height: 0;
            flex-grow: 1;
            position: relative;

            >.wrapper {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                padding-left: 24px;
                padding-right: 2px;

                >.scroll_wrapper {
                    height: 100%;
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding-right: 22px;

                    >table {
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 0;
                        table-layout: fixed;
                        font-family: Source Han Sans CN;

                        >thead {

                            >tr {
                                >th {
                                    padding: 16px 0;
                                    text-align: center;
                                    font-size: 14px;
                                    line-height: 14px;
                                    color: #333333;
                                    font-weight: 400;
                                    position: sticky;
                                    top: 0;
                                    background-color: rgba(245, 245, 245, 1);

                                    &.select {
                                        width: 32px;
                                        text-align: right;

                                        >.icon {
                                            user-select: none;
                                            cursor: pointer;
                                            width: 16px;
                                            height: 16px;
                                            display: inline-block;

                                            &:hover {
                                                opacity: 0.8;
                                            }
                                        }
                                    }

                                    &.action {
                                        width: 84px;
                                    }
                                }
                            }
                        }

                        >tbody {
                            >tr {
                                >td {
                                    padding: 14px 0;
                                    text-align: center;
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #666666;
                                    font-weight: 400;
                                    border-style: solid;
                                    border-width: 0 0 1px 0;
                                    border-color: transparent transparent rgba(0, 0, 0, 0.08) transparent;

                                    &.select {
                                        text-align: right;

                                        >.icon {
                                            user-select: none;
                                            cursor: pointer;
                                            width: 16px;
                                            height: 16px;
                                            display: inline-block;

                                            &:hover {
                                                opacity: 0.8;
                                            }
                                        }
                                    }

                                    &.name {
                                        text-align: left;
                                        padding-left: 8px;
                                    }

                                    &.action {
                                        >.btn {
                                            display: inline-block;
                                            color: rgba(24, 144, 255, 1);
                                            cursor: pointer;
                                            user-select: none;

                                            &:hover {
                                                opacity: 0.8;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}